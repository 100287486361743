import allchains from "../../Maps/allchains";
import { useState, useEffect } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useNavigate } from "react-router-dom";


const Defi = ({ theme }) => {
  const { isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);
  let share = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={theme === "dark" ? "white" : "black"}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="size-4"
    >
      <path d="M15 3h6v6"></path>
      <path d="M10 14 21 3"></path>
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    </svg>
  );
  const headers = [
    {
      id: 1,
      title: "Protocol",
    },
    {
      id: 2,
      title: "Chain",
    },
    {
      id: 3,
      title: "Assets",
    },
    {
      id: 4,
      title: "TVL",
    },
    {
      id: 5,
      title: "Boost",
    },
  ];

  const defidetails = [
    {
      id: 1,
      image: "./images/pendle-light.webp",
      protocol: "Pendle",
      new: true,
      chainIcon: "./images/eth.svg",
      chain: "Ethereum",
      assets: "ezETH",
      tvl: "-",
      boost: "4",
      link: "https://app.pendle.finance/points/0xd8f12bcde578c653014f27379a6114f67f0e445f?chain=ethereum",
    },
    {
      id: 2,
      image: "./images/pendle-light.webp",
      protocol: "Pendle",
      new: true,
      chainIcon: "./images/bnb.svg",
      chain: "BNB Chain",
      assets: "ezETH",
      tvl: "1,152,268",
      boost: "4",
      link: "https://app.pendle.finance/points/0xd8f12bcde578c653014f27379a6114f67f0e445f?chain=ethereum",
    },
    {
      id: 3,
      image: "./images/pendle-light.webp",
      protocol: "Pendle",
      new: false,
      chainIcon: "./images/arb.svg",
      chain: "Arbitrum",
      assets: "ezETH",
      tvl: "237,152,268",
      boost: "4",
      link: "https://app.pendle.finance/points/0xd8f12bcde578c653014f27379a6114f67f0e445f?chain=ethereum",
    },
  ];

  const [sac, setSac] = useState(false);
  const [act, setAct] = useState(0);

  return (
    <section
      className="bg-transparent dark:bg-[#050712] w-[100%] h-[100vh] lg:h-[80vh] overflow-y-scroll
      lg:p-[50px] lg:pb-[0px] pb-[150px] pt-[80px] flex flex-col items-start justify-start px-[20px] "
      style={{
        borderRadius: "0px 0px 1.25rem 0px",
      }}
    >
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-[100%] pb-[30px] lg:pb-[0px]">
        <h1 className="dark:text-white font-[700] lg:pl-[10px] pb-[10px]">
          DeFi Integrations
        </h1>

        <div className="flex flex-col items-center ">
          <div className="flex flex-row items-center">
            <h1 className="dark:text-white text-black text-[14px] font-[400]">
              Filter by:
            </h1>
            <button
              onClick={() => {
                setSac(!sac);
              }}
              className="dark:text-white text-black text-[14px] font-[400] flex flex-row items-center"
            >
              All Chains
              <i class="bx bxs-chevron-down"></i>
            </button>
          </div>

          {sac && (
            <section
              className="flex flex-col items-start min-w-[160px] absolute mt-[25px] ml-[30px] lg:ml-[40px] bg-white dark:bg-[#05081a] border-solid border-[1px] dark:border-[#36374d]
          p-[.25rem] rounded-[.75rem]"
            >
              <button
                onClick={() => {
                  setAct(0);
                }}
                className="text-black dark:text-[#8c90a580] text-[14px] pb-[10px] p-[.5rem] 
          flex flex-row items-center w-[100%] justify-between rounded-[.75rem] dark:hover:bg-[#1c1f34] w-[100%] cursor-pointer
            hover:bg-[#a3a4bf80]"
              >
                All Chains
                {act === 0 && <i class="bx bx-check text-[16px]"></i>}
              </button>
              {allchains.map((us, id) => (
                <button
                  key={id}
                  className="flex flex-row items-center p-[.5rem] rounded-[.75rem] dark:hover:bg-[#1c1f34] w-[100%] cursor-pointer
            hover:bg-[#a3a4bf80] justify-between"
                  onClick={() => {
                    setAct(us.id);
                  }}
                >
                  <div className="flex flex-row items-center">
                    <img src={us.icon} className="rounded-[50%] w-[20px]" />
                    <h1 className="text-black dark:text-[#8c90a580] text-[14px] pl-[5px]">
                      {us.name}
                    </h1>
                  </div>
                  {act === us.id && (
                    <i class="bx bx-check text-[16px] text-black dark:text-[#8c90a580] "></i>
                  )}
                </button>
              ))}
            </section>
          )}
        </div>
      </div>

      <section
        className="w-[100%] hidden lg:flex flex-col justify-between bg-white dark:bg-transparent
                    border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem]"
        onClick={() => {
          setSac(false);
        }}
      >
        <div className="flex flex-row items-center justify-between w-[100%] p-[15px]">
          {headers.map((us, id) => (
            <h1
              key={id}
              className={` ${us.id !== 1 || us.id !== 3 ? "w-[15%]" : ""} 
                    ${us.id === 1 || us.id === 3 ? "w-[24%]" : ""} 
                     text-[14px] font-[400] text-[#7a7c99]`}
            >
              {us.title}
            </h1>
          ))}
        </div>
        <div className="flex flex-col items-center justify-between w-[100%]">
          {defidetails.map((us, id) => (
            <div
              key={id}
              className="flex flex-row items-center justify-between w-[100%] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] p-[15px]"
            >
              <div className="flex flex-row items-center w-[200px] lg:w-[25%]">
                <img src={us.image} className="w-[40px]" />
                <h1 className="dark:text-white text-black text-[16px] font-[400] px-[10px] flex flex-row items-center">
                  {us.protocol}
                  <div className="pl-[5px]">
                    <a href={us.link}>{share}</a>
                  </div>
                </h1>
                {us.new && (
                  <div className="dark:text-[12px] text-[#7a7c99] dark:bg-[#36374d] py-[.25rem] px-[.5rem] rounded-[9999px]">
                    🔥 New Market
                  </div>
                )}
              </div>

              <div className="flex flex-row items-center w-[200px] lg:w-[15%]">
                <img src={us.chainIcon} className="rounded-[50%] w-[20px]" />
                <h1 className="dark:text-white text-black text-[16px] pl-[5px]">
                  {us.chain}
                </h1>
              </div>

              <div className="flex flex-row items-center w-[200px] lg:w-[25%]">
                <h1 className="dark:text-white text-black text-[16px]">
                  {us.assets}
                </h1>
              </div>

              <div className="flex flex-row items-center w-[200px] lg:w-[15%]">
                <h1 className="dark:text-white text-black text-[16px] sw">
                  {us.tvl === "-" ? "" : "$"}
                  {us.tvl}
                </h1>
              </div>

              <div className="flex flex-row items-center w-[200px] lg:w-[15%]">
                <h1 className="dark:text-white text-black text-[16px]">
                  {us.boost}x
                </h1>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section
        className="w-[100%] flex lg:hidden flex-col justify-between bg-white dark:bg-transparent
                    border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem] overflow-x-scroll"
        onClick={() => {
          setSac(false);
        }}
      >
        <div className="flex flex-row items-center justify-between w-[100%] p-[15px]">
          {headers.map((us, id) => (
            <h1
              key={id}
              className={` ${us.id !== 1 || us.id !== 3 ? "min-w-[250px]" : ""} 
                    ${us.id === 1 || us.id === 3 ? "min-w-[250px]" : ""} 
                     text-[14px] font-[400] text-[#7a7c99]`}
            >
              {us.title}
            </h1>
          ))}
        </div>
        <div className="flex flex-col items-center justify-between w-[100%]">
          {defidetails.map((us, id) => (
            <div
              key={id}
              className="flex flex-row items-start justify-between w-[100%] h-[70px] px-[15px]"
            >
              <div
                className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px]"
              >
                <img src={us.image} className="w-[40px]" />
                <h1 className="dark:text-white text-black text-[16px] font-[400] px-[10px] flex flex-row items-center">
                  {us.protocol}
                  <div className="pl-[5px]">
                    <a href={us.link}>{share}</a>
                  </div>
                </h1>
                {us.new && (
                  <div className="text-[12px] text-[#7a7c99] bg-[#f1f1f1] dark:bg-[#36374d] py-[.25rem] px-[.5rem] rounded-[9999px]">
                    🔥 New Market
                  </div>
                )}
              </div>

              <div
                className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px]"
              >
                <img src={us.chainIcon} className="rounded-[50%] w-[20px]" />
                <h1 className="dark:text-white text-black text-[16px] pl-[5px]">
                  {us.chain}
                </h1>
              </div>

              <div
                className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px]"
              >
                <h1 className="dark:text-white text-black text-[16px]">
                  {us.assets}
                </h1>
              </div>

              <div
                className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px]"
              >
                <h1 className="dark:text-white text-black text-[16px] sw">
                  {us.tvl === "-" ? "" : "$"}
                  {us.tvl}
                </h1>
              </div>

              <div
                className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px]"
              >
                <h1 className="dark:text-white text-black text-[16px]">
                  {us.boost}x
                </h1>
              </div>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Defi;
