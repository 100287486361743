import { Spinner } from "@chakra-ui/react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Analytics = ({ showSide, theme }) => {
  let share = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#ffffff99"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="size-4"
    >
      <path d="M15 3h6v6"></path>
      <path d="M10 14 21 3"></path>
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    </svg>
  );

  const headers = [
    {
      id: 1,
      title: "Asset",
    },
    {
      id: 2,
      title: "Price",
    },
    {
      id: 3,
      title: "Quantity",
    },
    {
      id: 4,
      title: "Balance",
    },
  ];

  const coins = [
    {
      id: 1,
      name: "Ethereum",
      ticker: "ETH",
      icon: "./images/z1.svg",
      netIcon: "./images/zz.svg",
      price: "3,102",
      quantity: "13.3k",
      balance: "44.56M",
    },
    {
      id: 2,
      name: "Binance Staked Ether",
      ticker: "wBETH",
      icon: "./images/z2.webp",
      netIcon: "./images/zz.svg",
      price: "3,102",
      quantity: "13.3k",
      balance: "44.56M",
    },
    {
      id: 3,
      name: "Lido Staked Ether",
      ticker: "stETH",
      icon: "./images/z3.svg",
      netIcon: "./images/zz.svg",
      price: "3,102",
      quantity: "13.3k",
      balance: "44.56M",
    },
  ];

  const base = [
    {
      id: 1,
      name: "Renzo Restaked ETH",
      ticker: "ezETH",
      icon: "./images/ezETH.webp",
      netIcon: "./images/zz.svg",
      price: "3,102",
      quantity: "13.3k",
      balance: "44.56M",
    },
  ];
  const { isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);

  return (
    <section
      className="bg-transparent dark:bg-[#050712] w-[100%] h-[100vh] lg:h-[80vh] overflow-y-scroll
      lg:p-[50px] lg:pb-[40px] pb-[150px] p-[80px] flex flex-col items-start justify-start px-[20px]"
      style={{
        borderRadius: "0px 0px 1.25rem 0px",
        zIndex: "10",
      }}
    >
      <section className="flex flex-col items-center justify-center w-[100%] pb-[40px] ">
        <Spinner
          className={`text-[green] text-[60px] w-[60px] h-[60px] ${
            showSide ? "" : "z-10"
          }`}
        />
      </section>

      <div className="flex flex-col items-start w-[100%]">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-[100%] pb-[10px] lg:pb-[0px]">
          <h1 className="dark:text-white font-[700] lg:pl-[10px] pb-[10px] flex flex-row items-center">
            Proof Of Reserves
            <i class="bx bx-info-circle pl-[10px]"></i>
          </h1>
        </div>

        <section
          className="w-[100%] h-[100%] hidden lg:flex flex-col justify-between bg-white dark:bg-transparent
        overflow-x-scroll border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem]"
        >
          <div className="flex flex-row items-center justify-between w-[100%] p-[15px]">
            {headers.map((us, id) => (
              <h1
                key={id}
                className={` ${us.id !== 1 ? "w-[20%]" : ""} 
                    ${us.id === 1 ? "w-[40%]" : ""} 
                     text-[14px] font-[400] text-[#7a7c99]`}
              >
                {us.title}
              </h1>
            ))}
          </div>
          <div className="flex flex-col items-center justify-between w-[100%]">
            {coins.map((us, id) => (
              <div
                key={id}
                className="flex flex-row items-center justify-between w-[100%] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] p-[15px]"
              >
                <section className="flex flex-row items-center w-[40%]">
                  <div className="flex flex-col items-end pr-[10px]">
                    <img src={us.icon} className="w-[40px] rounded-[50%]" />
                    <img
                      src={us.netIcon}
                      className="w-[14px] rounded-[50%] relative mt-[-10px]"
                    />
                  </div>

                  <div className="flex flex-col items-start w-[90%]">
                    <h1 className="dark:text-white text-black text-[14px] font-[700]">
                      {us.ticker}
                    </h1>
                    <h1 className="dark:text-white text-black text-[14px] font-[400]">
                      {us.name}
                    </h1>
                  </div>
                </section>
                <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
                  ${us.price}
                </h1>
                <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
                  {us.quantity}
                </h1>
                <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
                  ${us.balance}
                </h1>
              </div>
            ))}
          </div>
          <div
            className="flex flex-row items-center justify-between w-[100%] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] p-[15px]"
          >
            <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
              Total
            </h1>

            <h1 className="dark:text-white text-black text-[16px] sw w-[20%]"></h1>
            <h1 className="dark:text-white text-black text-[16px] sw w-[20%]"></h1>
            <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
              1.06M
            </h1>
            <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
              $3.36B
            </h1>
          </div>
        </section>

        <section
          className="w-[100%] flex lg:hidden flex-col justify-between bg-white dark:bg-transparent
                    border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem] overflow-x-scroll"
        >
          <div className="flex flex-row items-center justify-between w-[100%] py-[15px]">
            {headers.map((us, id) => (
              <h1
                key={id}
                className={` min-w-[250px] px-[15px]
                     text-[14px] font-[400] text-[#7a7c99]`}
              >
                {us.title}
              </h1>
            ))}
          </div>
          <div className="flex flex-col items-center justify-between w-[100%]">
            {coins.map((us, id) => (
              <div
                key={id}
                className="flex flex-row items-center justify-between w-[100%]"
              >
                <section
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] px-[15px]"
                >
                  <div className="flex flex-col items-end pr-[10px]">
                    <img src={us.icon} className="w-[40px] rounded-[50%]" />
                    <img
                      src={us.netIcon}
                      className="w-[14px] rounded-[50%] relative mt-[-10px]"
                    />
                  </div>

                  <div className="flex flex-col items-start w-[90%]">
                    <h1 className="dark:text-white text-black text-[14px] font-[700]">
                      {us.ticker}
                    </h1>
                    <h1 className="dark:text-white text-black text-[14px] font-[400]">
                      {us.name}
                    </h1>
                  </div>
                </section>
                <h1
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                >
                  ${us.price}
                </h1>
                <h1
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                >
                  {us.quantity}
                </h1>
                <h1
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                >
                  ${us.balance}
                </h1>
              </div>
            ))}
          </div>
          <div className="flex flex-row items-center justify-between w-[100%]">
            <h1
              className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
            >
              Total
            </h1>

            <h1
              className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
            ></h1>
            <h1
              className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
            >
              1.06M
            </h1>
            <h1
              className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
            >
              $3.36B
            </h1>
          </div>
        </section>
      </div>
      <div className="flex flex-col items-start w-[100%]">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-[100%] pt-[30px] pb-[10px] lg:pb-[0px]">
          <h1 className="dark:text-white font-[700] lg:pl-[10px] pb-[10px] flex flex-row items-center">
            Circulating Supply
            <i class="bx bx-info-circle pl-[10px]"></i>
          </h1>
        </div>

        <section
          className="w-[100%] h-[100%] hidden lg:flex flex-col justify-between bg-white dark:bg-transparent
  overflow-x-scroll border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem]"
        >
          <div className="flex flex-row items-center justify-between w-[100%] p-[15px]">
            {headers.map((us, id) => (
              <h1
                key={id}
                className={` ${us.id !== 1 ? "w-[20%]" : ""} 
              ${us.id === 1 ? "w-[40%]" : ""} 
               text-[14px] font-[400] text-[#7a7c99]`}
              >
                {us.title}
              </h1>
            ))}
          </div>
          <div className="flex flex-col items-center justify-between w-[100%]">
            {base.map((us, id) => (
              <div
                key={id}
                className="flex flex-row items-center justify-between w-[100%] border-solid border-t-[1px]
               dark:border-[#ffffff1a] p-[15px]"
              >
                <section className="flex flex-row items-center w-[40%]">
                  <div className="flex flex-col items-end pr-[10px]">
                    <img src={us.icon} className="w-[40px] rounded-[50%]" />
                    <img
                      src={us.netIcon}
                      className="w-[14px] rounded-[50%] relative mt-[-10px]"
                    />
                  </div>

                  <div className="flex flex-col items-start w-[90%]">
                    <h1 className="dark:text-white text-black text-[14px] font-[400]">
                      {us.name}
                    </h1>
                    <h1 className="text-[#ffffff99] text-[14px] font-[400] flex flex-row items-center">
                      {us.ticker}
                      <a className="pl-[5px]" href={us.link}>
                        {share}
                      </a>
                    </h1>
                  </div>
                </section>
                <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
                  ${us.price}
                </h1>
                <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
                  {us.quantity}
                </h1>
                <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
                  ${us.balance}
                </h1>
              </div>
            ))}
          </div>
        </section>

        <section
          className="w-[100%] flex lg:hidden flex-col justify-between bg-white dark:bg-transparent
              border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem] overflow-x-scroll"
        >
          <div className="flex flex-row items-center justify-between w-[100%] py-[15px]">
            {headers.map((us, id) => (
              <h1
                key={id}
                className={` min-w-[250px] px-[15px]
               text-[14px] font-[400] text-[#7a7c99]`}
              >
                {us.title}
              </h1>
            ))}
          </div>
          <div className="flex flex-col items-center justify-between w-[100%]">
            {base.map((us, id) => (
              <div
                key={id}
                className="flex flex-row items-center justify-between w-[100%]"
              >
                <section
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
               dark:border-[#ffffff1a] h-[70px] px-[15px]"
                >
                  <div className="flex flex-col items-end pr-[10px]">
                    <img src={us.icon} className="w-[40px] rounded-[50%]" />
                    <img
                      src={us.netIcon}
                      className="w-[14px] rounded-[50%] relative mt-[-10px]"
                    />
                  </div>

                  <div className="flex flex-col items-start w-[90%]">
                    <h1 className="dark:text-white text-black text-[14px] font-[700]">
                      {us.name}
                    </h1>
                    <h1 className="text-[#ffffff99] text-[14px] font-[400] flex flex-row items-center">
                      {us.ticker}
                      <a className="pl-[5px]" href={us.link}>
                        {share}
                      </a>
                    </h1>
                  </div>
                </section>
                <h1
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
               dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                >
                  ${us.price}
                </h1>
                <h1
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
               dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                >
                  {us.quantity}
                </h1>
                <h1
                  className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
               dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                >
                  ${us.balance}
                </h1>
              </div>
            ))}
          </div>
        </section>
      </div>
    </section>
  );
};

export default Analytics;
