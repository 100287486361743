const inf = [
    {
        id: 1,
        title: "EigenLayer Points",
        body: <h1 className="flex flex-row items-center">
            <img src="./images/e1.svg" className="w-[20px]" />
            919.25M
        </h1>
    },
    {
        id: 2,
        title: "Renzo ezPoints",
        body: "1.51B"
    },
    {
        id: 3,
        title: "Total Value Staked",
        body: "$3.42B"
    },
    {
        id: 4,
        title: "APR",
        body: "1.40%"
    },
    {
        id: 1,
        title: "EigenLayer Points",
        body: <h1 className="flex flex-row items-center">
            <img src="./images/e1.svg" className="w-[20px]" />
            919.25M
        </h1>
    },
    {
        id: 2,
        title: "Renzo ezPoints",
        body: "1.51B"
    },
    {
        id: 3,
        title: "Total Value Staked",
        body: "$3.42B"
    },
    {
        id: 4,
        title: "APR",
        body: "1.40%"
    },
    {
        id: 1,
        title: "EigenLayer Points",
        body: <h1 className="flex flex-row items-center">
            <img src="./images/e1.svg" className="w-[20px]" />
            919.25M
        </h1>
    },
    {
        id: 2,
        title: "Renzo ezPoints",
        body: "1.51B"
    },
    {
        id: 3,
        title: "Total Value Staked",
        body: "$3.42B"
    },
    {
        id: 4,
        title: "APR",
        body: "1.40%"
    },
  ];


  export default inf