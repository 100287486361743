const bottoms = [
    {
      id: 1,
      link: "https://github.com/Renzo-Protocol",
      icon: (
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.5087 0.5C4.69766 0.5 0 4.85415 0 10.2408C0 14.5467 3.00995 18.1915 7.18555 19.4815C7.7076 19.5785 7.89883 19.2719 7.89883 19.014C7.89883 18.7882 7.88162 18.0142 7.88162 17.2077C4.95836 17.7883 4.34962 16.0465 4.34962 16.0465C3.87983 14.9176 3.18375 14.6274 3.18375 14.6274C2.22697 14.0307 3.25345 14.0307 3.25345 14.0307C4.31477 14.0952 4.87167 15.0306 4.87167 15.0306C5.81103 16.5142 7.32472 16.095 7.93368 15.8369C8.02058 15.2079 8.29914 14.7725 8.59491 14.5307C6.2634 14.3048 3.81035 13.4663 3.81035 9.72466C3.81035 8.66027 4.22765 7.78944 4.88888 7.11217C4.78456 6.87032 4.4191 5.87025 4.99342 4.53174C4.99342 4.53174 5.88073 4.27366 7.88141 5.53161C8.73797 5.31839 9.62133 5.20992 10.5087 5.20901C11.396 5.20901 12.3005 5.32202 13.1358 5.53161C15.1367 4.27366 16.024 4.53174 16.024 4.53174C16.5983 5.87025 16.2326 6.87032 16.1283 7.11217C16.8069 7.78944 17.207 8.66027 17.207 9.72466C17.207 13.4663 14.754 14.2886 12.405 14.5307C12.7879 14.837 13.1183 15.4175 13.1183 16.3368C13.1183 17.6431 13.1011 18.6914 13.1011 19.0138C13.1011 19.2719 13.2926 19.5785 13.8144 19.4817C17.99 18.1913 21 14.5467 21 10.2408C21.0172 4.85415 16.3023 0.5 10.5087 0.5Z"
            fill="black"
          ></path>
        </svg>
      ),
      lightIcon: (
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.5087 0.5C4.69766 0.5 0 4.85415 0 10.2408C0 14.5467 3.00995 18.1915 7.18555 19.4815C7.7076 19.5785 7.89883 19.2719 7.89883 19.014C7.89883 18.7882 7.88162 18.0142 7.88162 17.2077C4.95836 17.7883 4.34962 16.0465 4.34962 16.0465C3.87983 14.9176 3.18375 14.6274 3.18375 14.6274C2.22697 14.0307 3.25345 14.0307 3.25345 14.0307C4.31477 14.0952 4.87167 15.0306 4.87167 15.0306C5.81103 16.5142 7.32472 16.095 7.93368 15.8369C8.02058 15.2079 8.29914 14.7725 8.59491 14.5307C6.2634 14.3048 3.81035 13.4663 3.81035 9.72466C3.81035 8.66027 4.22765 7.78944 4.88888 7.11217C4.78456 6.87032 4.4191 5.87025 4.99342 4.53174C4.99342 4.53174 5.88073 4.27366 7.88141 5.53161C8.73797 5.31839 9.62133 5.20992 10.5087 5.20901C11.396 5.20901 12.3005 5.32202 13.1358 5.53161C15.1367 4.27366 16.024 4.53174 16.024 4.53174C16.5983 5.87025 16.2326 6.87032 16.1283 7.11217C16.8069 7.78944 17.207 8.66027 17.207 9.72466C17.207 13.4663 14.754 14.2886 12.405 14.5307C12.7879 14.837 13.1183 15.4175 13.1183 16.3368C13.1183 17.6431 13.1011 18.6914 13.1011 19.0138C13.1011 19.2719 13.2926 19.5785 13.8144 19.4817C17.99 18.1913 21 14.5467 21 10.2408C21.0172 4.85415 16.3023 0.5 10.5087 0.5Z"
            fill="white"
          ></path>
        </svg>
      ),
    },
    {
      id: 2,
      link: "https://docs.renzoprotocol.com/docs/",
      icon: (
        <svg
          width="16"
          height="21"
          viewBox="0 0 16 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.9996 3.16002V6.25H12.609L8.9996 3.16002ZM13.9996 7.75H7.4996V2.25H5.52802C4.70078 2.25 4.0297 2.91972 4.02802 3.74696L4.0057 14.747C4.00402 15.5754 4.67422 16.2483 5.50265 16.25L12.4996 16.25C13.328 16.25 13.9996 15.5784 13.9996 14.75V7.75ZM3.49366 3.99622C3.49577 4.41043 3.1617 4.74792 2.74749 4.75003C2.05581 4.75355 1.50004 5.31216 1.50004 6.00003L1.50004 17.5C1.50004 18.1904 2.05968 18.75 2.75004 18.75L10.25 18.75C10.9403 18.75 11.4999 18.1905 11.5 17.5002C11.5 17.0746 11.8358 16.7388 12.25 16.7388C12.6642 16.7388 13 17.0746 13 17.4888C12.9998 19.019 11.7686 20.25 10.25 20.25L2.75004 20.25C1.23126 20.25 3.90606e-05 19.0188 3.91006e-05 17.5L3.98159e-05 6.00003C3.98559e-05 4.48671 1.22274 3.25777 2.73604 3.25007C3.15406 3.24794 3.49155 3.58201 3.49366 3.99622ZM8.49097 0.75L15.4996 6.75V14.75C15.4996 16.4069 14.1565 17.75 12.4996 17.75H5.50569C3.84275 17.7466 2.50234 16.4008 2.5057 14.7439L2.52802 3.74391C2.53138 2.08944 3.87354 0.75 5.52802 0.75H8.49097Z"
            fill="black"
          ></path>
        </svg>
      ),
      lightIcon: (
        <svg
          width="16"
          height="21"
          viewBox="0 0 16 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.9996 3.16002V6.25H12.609L8.9996 3.16002ZM13.9996 7.75H7.4996V2.25H5.52802C4.70078 2.25 4.0297 2.91972 4.02802 3.74696L4.0057 14.747C4.00402 15.5754 4.67422 16.2483 5.50265 16.25L12.4996 16.25C13.328 16.25 13.9996 15.5784 13.9996 14.75V7.75ZM3.49366 3.99622C3.49577 4.41043 3.1617 4.74792 2.74749 4.75003C2.05581 4.75355 1.50004 5.31216 1.50004 6.00003L1.50004 17.5C1.50004 18.1904 2.05968 18.75 2.75004 18.75L10.25 18.75C10.9403 18.75 11.4999 18.1905 11.5 17.5002C11.5 17.0746 11.8358 16.7388 12.25 16.7388C12.6642 16.7388 13 17.0746 13 17.4888C12.9998 19.019 11.7686 20.25 10.25 20.25L2.75004 20.25C1.23126 20.25 3.90606e-05 19.0188 3.91006e-05 17.5L3.98159e-05 6.00003C3.98559e-05 4.48671 1.22274 3.25777 2.73604 3.25007C3.15406 3.24794 3.49155 3.58201 3.49366 3.99622ZM8.49097 0.75L15.4996 6.75V14.75C15.4996 16.4069 14.1565 17.75 12.4996 17.75H5.50569C3.84275 17.7466 2.50234 16.4008 2.5057 14.7439L2.52802 3.74391C2.53138 2.08944 3.87354 0.75 5.52802 0.75H8.49097Z"
            fill="white"
          ></path>
        </svg>
      ),
    },
    {
      id: 3,
      link: "https://twitter.com/RenzoProtocol",
      icon: (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.153041 0L6.77154 8.82491L0.111328 16H1.61039L7.44153 9.71796L12.1528 16H17.2538L10.2627 6.67879L16.4621 0H14.963L9.59304 5.78545L5.25406 0H0.153041ZM2.35748 1.10103H4.70087L15.049 14.899H12.7057L2.35748 1.10103Z"
            fill="black"
          ></path>
        </svg>
      ),
      lightIcon: (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.153041 0L6.77154 8.82491L0.111328 16H1.61039L7.44153 9.71796L12.1528 16H17.2538L10.2627 6.67879L16.4621 0H14.963L9.59304 5.78545L5.25406 0H0.153041ZM2.35748 1.10103H4.70087L15.049 14.899H12.7057L2.35748 1.10103Z"
            fill="white"
          ></path>
        </svg>
      ),
    },
    {
      id: 4,
      link: "https://t.me/RenzoProtocolChat",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.99996 0.666626C4.39996 0.666626 0.666626 4.39996 0.666626 8.99996C0.666626 13.6 4.39996 17.3333 8.99996 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99996C17.3333 4.39996 13.6 0.666626 8.99996 0.666626ZM12.8666 6.33329C12.7416 7.64996 12.2 10.85 11.925 12.325C11.8083 12.95 11.575 13.1583 11.3583 13.1833C10.875 13.225 10.5083 12.8666 10.0416 12.5583C9.30829 12.075 8.89163 11.775 8.18329 11.3083C7.35829 10.7666 7.89163 10.4666 8.36663 9.98329C8.49163 9.85829 10.625 7.91663 10.6666 7.74163C10.6724 7.71512 10.6716 7.6876 10.6644 7.66146C10.6571 7.63532 10.6436 7.61135 10.625 7.59163C10.575 7.54996 10.5083 7.56663 10.45 7.57496C10.375 7.59163 9.20829 8.36663 6.93329 9.89996C6.59996 10.125 6.29996 10.2416 6.03329 10.2333C5.73329 10.225 5.16663 10.0666 4.74163 9.92496C4.21663 9.75829 3.80829 9.66663 3.84163 9.37496C3.85829 9.22496 4.06663 9.07496 4.45829 8.91663C6.89163 7.85829 8.50829 7.15829 9.31663 6.82496C11.6333 5.85829 12.1083 5.69163 12.425 5.69163C12.4916 5.69163 12.65 5.70829 12.75 5.79163C12.8333 5.85829 12.8583 5.94996 12.8666 6.01663C12.8583 6.06663 12.875 6.21663 12.8666 6.33329Z"
            fill="black"
          ></path>
        </svg>
      ),
      lightIcon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.99996 0.666626C4.39996 0.666626 0.666626 4.39996 0.666626 8.99996C0.666626 13.6 4.39996 17.3333 8.99996 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99996C17.3333 4.39996 13.6 0.666626 8.99996 0.666626ZM12.8666 6.33329C12.7416 7.64996 12.2 10.85 11.925 12.325C11.8083 12.95 11.575 13.1583 11.3583 13.1833C10.875 13.225 10.5083 12.8666 10.0416 12.5583C9.30829 12.075 8.89163 11.775 8.18329 11.3083C7.35829 10.7666 7.89163 10.4666 8.36663 9.98329C8.49163 9.85829 10.625 7.91663 10.6666 7.74163C10.6724 7.71512 10.6716 7.6876 10.6644 7.66146C10.6571 7.63532 10.6436 7.61135 10.625 7.59163C10.575 7.54996 10.5083 7.56663 10.45 7.57496C10.375 7.59163 9.20829 8.36663 6.93329 9.89996C6.59996 10.125 6.29996 10.2416 6.03329 10.2333C5.73329 10.225 5.16663 10.0666 4.74163 9.92496C4.21663 9.75829 3.80829 9.66663 3.84163 9.37496C3.85829 9.22496 4.06663 9.07496 4.45829 8.91663C6.89163 7.85829 8.50829 7.15829 9.31663 6.82496C11.6333 5.85829 12.1083 5.69163 12.425 5.69163C12.4916 5.69163 12.65 5.70829 12.75 5.79163C12.8333 5.85829 12.8583 5.94996 12.8666 6.01663C12.8583 6.06663 12.875 6.21663 12.8666 6.33329Z"
            fill="white"
          ></path>
        </svg>
      ),
    },
    {
      id: 5,
      link: "https://mirror.xyz/renzoprotocol.eth",
      icon: (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5ZM12.5454 8.34104V14.1365H5.45454V8.34104V8.10922H5.46229C5.58392 6.29686 7.12132 4.86377 8.99999 4.86377C10.8787 4.86377 12.4161 6.29686 12.5377 8.10922H12.5454V8.34104Z"
            fill="black"
          ></path>
        </svg>
      ),
      lightIcon: (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5ZM12.5454 8.34104V14.1365H5.45454V8.34104V8.10922H5.46229C5.58392 6.29686 7.12132 4.86377 8.99999 4.86377C10.8787 4.86377 12.4161 6.29686 12.5377 8.10922H12.5454V8.34104Z"
            fill="white"
          ></path>
        </svg>
      ),
    },
    {
      id: 6,
      link: "https://discord.gg/FMwGPDXXtf",
      icon: (
        <svg
          width="22"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.0088 1.34C16.6709 0.714 15.2402 0.259052 13.7446 0C13.5609 0.332095 13.3463 0.77877 13.1983 1.1341C11.6084 0.894993 10.0331 0.894993 8.47243 1.1341C8.3245 0.77877 8.10503 0.332095 7.91971 0C6.42243 0.259052 4.99018 0.715671 3.65222 1.34331C0.953554 5.42136 0.221989 9.39811 0.587772 13.3184C2.37767 14.655 4.1123 15.467 5.81766 15.9984C6.23872 15.4189 6.61425 14.8028 6.93776 14.1536C6.32162 13.9195 5.73149 13.6306 5.17389 13.2952C5.32182 13.1856 5.46652 13.071 5.60632 12.9531C9.00728 14.5438 12.7025 14.5438 16.0628 12.9531C16.2043 13.071 16.349 13.1856 16.4953 13.2952C15.936 13.6322 15.3443 13.9211 14.7281 14.1553C15.0517 14.8028 15.4256 15.4205 15.8482 16C17.5552 15.4687 19.2915 14.6567 21.0814 13.3184C21.5106 8.77378 20.3482 4.83355 18.0088 1.34ZM7.40109 10.9075C6.38016 10.9075 5.54291 9.95435 5.54291 8.7937C5.54291 7.63305 6.36228 6.67831 7.40109 6.67831C8.43993 6.67831 9.27715 7.63138 9.25928 8.7937C9.26089 9.95435 8.43993 10.9075 7.40109 10.9075ZM14.2681 10.9075C13.2471 10.9075 12.4099 9.95435 12.4099 8.7937C12.4099 7.63305 13.2292 6.67831 14.2681 6.67831C15.3069 6.67831 16.1441 7.63138 16.1263 8.7937C16.1263 9.95435 15.3069 10.9075 14.2681 10.9075Z"
            fill="black"
          ></path>
        </svg>
      ),
      lightIcon: (
        <svg
          width="22"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.0088 1.34C16.6709 0.714 15.2402 0.259052 13.7446 0C13.5609 0.332095 13.3463 0.77877 13.1983 1.1341C11.6084 0.894993 10.0331 0.894993 8.47243 1.1341C8.3245 0.77877 8.10503 0.332095 7.91971 0C6.42243 0.259052 4.99018 0.715671 3.65222 1.34331C0.953554 5.42136 0.221989 9.39811 0.587772 13.3184C2.37767 14.655 4.1123 15.467 5.81766 15.9984C6.23872 15.4189 6.61425 14.8028 6.93776 14.1536C6.32162 13.9195 5.73149 13.6306 5.17389 13.2952C5.32182 13.1856 5.46652 13.071 5.60632 12.9531C9.00728 14.5438 12.7025 14.5438 16.0628 12.9531C16.2043 13.071 16.349 13.1856 16.4953 13.2952C15.936 13.6322 15.3443 13.9211 14.7281 14.1553C15.0517 14.8028 15.4256 15.4205 15.8482 16C17.5552 15.4687 19.2915 14.6567 21.0814 13.3184C21.5106 8.77378 20.3482 4.83355 18.0088 1.34ZM7.40109 10.9075C6.38016 10.9075 5.54291 9.95435 5.54291 8.7937C5.54291 7.63305 6.36228 6.67831 7.40109 6.67831C8.43993 6.67831 9.27715 7.63138 9.25928 8.7937C9.26089 9.95435 8.43993 10.9075 7.40109 10.9075ZM14.2681 10.9075C13.2471 10.9075 12.4099 9.95435 12.4099 8.7937C12.4099 7.63305 13.2292 6.67831 14.2681 6.67831C15.3069 6.67831 16.1441 7.63138 16.1263 8.7937C16.1263 9.95435 15.3069 10.9075 14.2681 10.9075Z"
            fill="white"
          ></path>
        </svg>
      ),
    },
  ];

  export default bottoms