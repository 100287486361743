import anime from "animejs";
import React, { useEffect, useState } from "react";

const Background = ({ setPage }) => {
  const [state, setState] = useState({
    rows: 0,
    columns: 0,
    total: 1,
  });

  const getGridSize = () => {
    let columns, rows;
    columns = Math.floor(document.documentElement.clientWidth / 25);
    rows = Math.floor(document.documentElement.clientHeight / 25);

    setState((prevState) => ({
      ...prevState,
      rows: rows,
      columns: columns,
      total: rows * columns,
    }));

    document.body.style.setProperty("--rows", state.rows);
    document.body.style.setProperty("--columns", state.columns);

    anime({
      targets: ".tile",
      duration: 0,
      easing: "linear",
    });
  };

  useEffect(() => {
    let columns, rows;
    columns = Math.floor(document.documentElement.clientWidth / 80);
    rows = Math.floor(document.documentElement.clientHeight / 80);

    setState((prevState) => ({
      ...prevState,
      rows: rows,
      columns: columns,
      total: rows * columns,
    }));

    document.body.style.setProperty("--rows", rows);
    document.body.style.setProperty("--columns", columns);
  }, []);

  window.onresize = getGridSize;

  return (
    <div
      onMouseEnter={() => {
        setPage(0);
      }}
    >
      <div id="tiles" className="">
        {[...Array(state.total)].map((tile, i) => (
          <div
            className="tile cursor-pointer hover:bg-[#ffffff04]"
            key={i}
            id={i}
          />
        ))}
      </div>
    </div>
  );
};

export default Background;
