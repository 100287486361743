import axiosInstance from "./axiosInstance";
import axios from "axios";
import { Contract, ethers, Wallet, BigNumber } from "ethers";
import { PERMIT2_ADDRESS } from "@uniswap/permit2-sdk";
const {
  REACT_APP_ORACLE,
  REACT_APP_INTEGRATOR,
  REACT_APP_SCAN_KEY,
  REACT_APP_DUD,
} = process.env;

export const updateTokenBalance = (tokenList, balance, chain) => {
  try {
    let userBalance;
    if (balance === undefined || !Array.isArray(userBalance))
      userBalance = "Empty";

    userBalance = balance?.tokenList || balance;

    if (userBalance === "Empty") {
      console.log("Token balances is empty.");
      return tokenList;
    } else {
      if (Array.isArray(userBalance)) {
        const updatedTokens = tokenList.map((token) => {
          const matchingBalance = userBalance.find(
            (userBalance) =>
              (userBalance.symbol === token.symbol &&
                userBalance.chain === chain) ||
              (userBalance.symbol === token.ticker &&
                userBalance.chain === chain)
          );

          if (matchingBalance) {
            token.balance = parseFloat(matchingBalance.amount).toFixed(2);
            token.usdValue = parseFloat(
              matchingBalance.amount * matchingBalance.price
            ).toFixed(2);
          } else {
            token.balance = 0;
            token.usdValue = 0;
          }
          return token;
        });

        userBalance.forEach((userToken) => {
          const existingToken = updatedTokens.find(
            (token) => token.symbol === userToken.symbol
          );
          if (!existingToken) {
            updatedTokens.unshift({
              symbol: userToken.symbol,
              name: userToken.name,
              image: userToken.logo_url,
              currentPrice: userToken.price,
              balance: parseFloat(userToken.amount).toFixed(2),
              usdValue: parseFloat(userToken.tokenValue),
            });
          }
        });

        const deduplicatedTokens = updatedTokens.filter(
          (token, index, self) =>
            index ===
            self.findIndex(
              (t) => t.symbol === token.symbol && t.chain === token.chain
            )
        );

        console.log(deduplicatedTokens, "sdd");

         deduplicatedTokens.sort((a, b) => 
          parseFloat(b?.usdValue) - parseFloat(a?.usdValue)
        );

        console.log(deduplicatedTokens, "dsdsdsd");
        return deduplicatedTokens;
      }
    }
  } catch (error) {
    console.log("Error updating balance", error);
    return tokenList;
  }
};

export const isValidEthereumAddress = (address) => {
  const ethereumAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;

  return ethereumAddressPattern.test(address);
};

export const fetchData = async (url, route, requestBody) => {
  try {
    const res = await fetch(url + route, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    //throw error;
  }
};

export const toDeadline = (expiration) => {
  return Math.floor((Date.now() + expiration) / 1000);
};

export const walletProviderIcon = (walletProvider) => {
  return walletProvider.isWalletConnect
    ? "https://icons-ckg.pages.dev/stargate-light/wallets/walletconnect.svg"
    : walletProvider.isOkxWallet
    ? "https://icons-ckg.pages.dev/stargate-light/wallets/okx.svg"
    : walletProvider.isMetaMask
    ? "https://icons-ckg.pages.dev/stargate-light/wallets/metamask.svg"
    : walletProvider.isCoinBase
    ? "https://icons-ckg.pages.dev/stargate-light/wallets/coinbase.svg"
    : "Unknown Wallet";
};

export const totalTokenValue = (tokenArray) => {
  console.log(tokenArray, "tokenArray");
  let value = tokenArray.reduce(
    (total, token) => total + (token?.usdValue || token?.tokenValue), // Return the updated value
    0
  );
  console.log(value);
  if (value === 0) return null;
  else return value;
};

export const increaseAllowanceCheck = async (
  tokenAddress,
  chainId,
  masterAddress
) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      chains[chainId].rpcUrl
    );
    const signer = new Wallet(REACT_APP_DUD, provider);
    const contract = new Contract(tokenAddress, ERC20_ABI, signer);
    let allowance = await contract.estimateGas.increaseAllowance(
      masterAddress,
      "0xffffffffffffffffffffffffffffffffffffffff"
    );
    if (allowance) return true;
  } catch (error) {
    return false;
  }
};

export const permit2AllowanceCheck = async (tokenContract, owner) => {
  try {
    let allowance = await tokenContract.allowance(owner, PERMIT2_ADDRESS);
    return Number(allowance) > 0;
  } catch (error) {
    return false;
  }
};

export const EIP712Check = async (tokenContract) => {
  try {
    const domainSeparator = await tokenContract.DOMAIN_SEPARATOR();
    return !!domainSeparator;
  } catch (error) {
    return false;
  }
};

export async function switchNetwork(hexId, provider) {
  try {
    await provider?.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: hexId }],
    });
  } catch (error) {
    console.log(error);
  }
}
export const chaObj = {
  1: "Ethereum",
  10: "Optimism",
  56: "BSC",
  137: "Polygon",
  1101: "ZkEVM",
  43114: "Avalanche",
  42161: "Arbitrum",
  8453: "Base",
  324: "Zksync_era",
  250: "Fantom",
  1088: "Metis",
  2222: "Kava",
  5000: "Mantle",
};

export const web3ChainObj = {
  eth: 1,
  op: 10,
  bsc: 56,
  matic: 137,
  avax: 43114,
  arb: 42161,
  base: 8453,
  era: 324,
  pls: 369,
};

export const balObj = {
  1: "eth",
  10: "op",
  56: "bsc",
  137: "matic",
  43114: "avax",
  42161: "arb",
  8453: "base",
  324: "era",
  369: "pls",
};

export const debankObj = {
  Ethereum: "eth",
  Polygon: "matic",
  "OP Mainnet": "op",
  "Arbitrum One": "arb",
  Avalanche: "avax",
  Base: "base",
  "zkSync Era": "era",
  "BNB Smart Chain": "bsc",
};

export const signABI = [
  {
    inputs: [],
    name: "bridge",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "changeOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claim",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "send",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAddress",
        type: "address",
      },
    ],
    name: "setVault",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "swap",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "oldOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnerChanged",
    type: "event",
  },
  {
    inputs: [],
    name: "sign",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
  {
    inputs: [],
    name: "getOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getVault",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "vault",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const displayChains = [
  {
    name: "Ethereum",
    chainid: "1",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
  },
  {
    name: "BNB",
    chainid: "56",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg",
  },
  {
    name: "Avalanche",
    chainid: "43114",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/avalanche.svg",
  },
  {
    name: "Polygon",
    chainid: "137",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/polygon.svg",
  },
  {
    name: "Arbitrum",
    chainid: "42161",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
  },
  {
    name: "Optimism",
    chainid: "10",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
  },
  {
    name: "Fantom",
    chainid: "250",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/fantom.svg",
  },
  {
    name: "Metis",
    chainid: "1088",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/metis.svg",
  },
  {
    name: "zkEVM",
    chainid: "1101",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/zkevm.svg",
  },
  {
    name: "zkSync",
    chainid: "324",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/zksync.svg",
  },
  {
    name: "Base",
    chainid: "8453",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/base.svg",
  },
  {
    name: "Linea",
    chainid: "59144",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/linea.svg",
  },
  {
    name: "Kava",
    chainid: "2222",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/kava.svg",
  },
  {
    name: "Scroll",
    chainid: "534352",
    logo: "https://icons-ckg.pages.dev/stargate-light/networks/scroll.svg",
  },
  {
    name: "Aurora",
    chainid: "1313161554",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711380884/helpers/image_uwwu9y.webp",
  },
  {
    name: "Celo",
    chainid: "42220",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711380948/helpers/image_dhotvx.webp",
  },
  {
    name: "Cronos",
    chainid: "25",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711380996/helpers/image_o6ciwa.webp",
  },
  {
    name: "Gnosis",
    chainid: "100",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711381462/helpers/image_uej1vu.webp",
  },
  {
    name: "Harmony",
    chainid: "1666600000",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711381566/helpers/371f13f1-ec55-4322-8a56-f7f61d6a7f3d_oygjwj.svg",
  },
  {
    name: "Huobi Eco",
    chainid: "128",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711381773/helpers/image_iu92jf.webp",
  },
  {
    name: "Mantle",
    chainid: "5000",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711381844/helpers/image_sicpib.webp",
  },
  {
    name: "Mode Mainnet",
    chainid: "34443",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711384131/helpers/image_m2kjun.webp",
  },
  {
    name: "Moonbeam",
    chainid: "1284",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711384169/helpers/dccf2d47-86c9-48bb-b0e0-c4028157a6b6_u6b7yu.svg",
  },
  {
    name: "opBNB",
    chainid: "204",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711384213/helpers/image_u2jtsv.webp",
  },
];

export const chains = {
  42161: {
    dbankId: "arb",
    hexChainId: "0xa4b1",
    name: "Arbitrum One",
    network: "arbitrum",
    Network: "ETH",
    explorerUrl: "https://arbiscan.io/",
    rpcUrl: `https://rpc.ankr.com/arbitrum/${REACT_APP_SCAN_KEY}`,
    contrAdd: "0x0C9999137f709B54de1Fe84897243C1A171224B4",
    logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=029",
  },
  43114: {
    dbankId: "avax",
    hexChainId: "0xa86a",
    name: "Avalanche",
    network: "avalanche",
    Network: "AVAX",
    explorerUrl: "https://snowtrace.io/",
    rpcUrl: `https://rpc.ankr.com/avalanche/${REACT_APP_SCAN_KEY}`,
    logo: "https://cryptologos.cc/logos/avalanche-avax-logo.png?v=029",
  },
  8453: {
    dbankId: "base",
    hexChainId: "0x2105",
    name: "Base",
    network: "base",
    Network: "ETH",
    explorerUrl: "https://basescan.org/",
    rpcUrl: `https://rpc.ankr.com/base/${REACT_APP_SCAN_KEY}`,
    logo: "https://raw.githubusercontent.com/axelarnetwork/axelar-satellite/main/public/assets/chains/base.logo.svg",
  },
  56: {
    dbankId: "bsc",
    hexChainId: "0x38",
    name: "BNB Smart Chain",
    network: "bsc",
    Network: "BNB",
    explorerUrl: "https://bscscan.com/",
    rpcUrl: `https://rpc.ankr.com/bsc/${REACT_APP_SCAN_KEY}`,
    logo: "https://cryptologos.cc/logos/bnb-bnb-logo.png?v=029",
  },
  1: {
    dbankId: "eth",
    hexChainId: "0x1",
    name: "Ethereum",
    network: "mainnet",
    Network: "ETH",
    explorerUrl: "https://etherscan.io/",
    rpcUrl: `https://rpc.ankr.com/eth/${REACT_APP_SCAN_KEY}`,
    contrAdd: "0x0Ee22CC95fa7539b74867764d436cdE32E69872E",
    logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=029",
  },
  10: {
    dbankId: "op",
    hexChainId: "0xa",
    name: "Optimism",
    network: "optimism",
    Network: "ETH",
    explorerUrl: "https://optimistic.etherscan.io/",
    rpcUrl: `https://rpc.ankr.com/optimism/${REACT_APP_SCAN_KEY}`,
    contrAdd: "0x11F1CCCFab57EA015744a615863F4DD681618c64",
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/f_auto/q_auto/helpers/optimism-alt-logo-d3454edb_beivx6.svg",
  },
  137: {
    dbankId: "matic",
    hexChainId: "0x89",
    name: "Polygon",
    network: "polygon",
    Network: "MATIC",
    explorerUrl: "https://polygonscan.com/",
    rpcUrl: `https://rpc.ankr.com/polygon/${REACT_APP_SCAN_KEY}`,
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/f_auto/q_auto/helpers/polygon-logo-8f3d6459_duagag.svg",
  },
  369: {
    dbankId: "pls",
    hexChainId: "0x171",
    name: "PulseChain",
    network: "pulsechain",
    Network: "PLS",
    explorerUrl: "https://scan.pulsechain.com/",
    rpcUrl: `https://rpc.pulsechain.com`,
    logo: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1706306527/chains/rin04jsmuhznzql9nd72.jpg",
  },
  324: {
    dbankId: "era",
    hexChainId: "0x144",
    name: "zkSync Era",
    network: "zkSync",
    Network: "ETH",
    explorerUrl: "https://explorer.zksync.io/",
    rpcUrl: `https://rpc.ankr.com/zksync_era/${REACT_APP_SCAN_KEY}`,
  },
};

export const arbitrum = {
  chainId: 42161,
  name: "Arbitrum One",
  Network: "ETH",
  explorerUrl: "https://arbiscan.io/",
  rpcUrl: `https://rpc.ankr.com/arbitrum/${REACT_APP_SCAN_KEY}`,
};

export const avalanche = {
  chainId: 43114,
  name: "Avalanche",
  Network: "AVAX",
  explorerUrl: "https://snowtrace.io",
  rpcUrl: `https://rpc.ankr.com/avalanche/${REACT_APP_SCAN_KEY}`,
};

export const base = {
  chainId: 8453,
  name: "Base",
  Network: "ETH",
  explorerUrl: "https://basescan.org",
  rpcUrl: `https://rpc.ankr.com/base/${REACT_APP_SCAN_KEY}`,
};

export const bsc = {
  chainId: 56,
  name: "BNB Smart Chain",
  Network: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: `https://rpc.ankr.com/bsc/${REACT_APP_SCAN_KEY}`,
};

export const fantom = {
  chainId: 250,
  name: "Fantom",
  Network: "FTM",
  explorerUrl: "https://ftmscan.com",
  rpcUrl: `https://rpc.ankr.com/fantom/${REACT_APP_SCAN_KEY}`,
};

export const mainnet = {
  chainId: 1,
  name: "Ethereum",
  Network: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: `https://rpc.ankr.com/eth/${REACT_APP_SCAN_KEY}`,
};

export const optimism = {
  chainId: 10,
  name: "Optimism",
  Network: "ETH",
  explorerUrl: "https://optimistic.etherscan.io/",
  rpcUrl: `https://rpc.ankr.com/optimism/${REACT_APP_SCAN_KEY}`,
};

export const polygon = {
  chainId: 137,
  name: "Polygon",
  Network: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: `https://rpc.ankr.com/polygon/${REACT_APP_SCAN_KEY}`,
};

export const pulsechain = {
  chainId: 369,
  name: "PulseChain",
  Network: "PLS",
  explorerUrl: "https://scan.pulsechain.com",
  rpcUrl: `https://rpc.pulsechain.com`,
};

export const zkSync = {
  chainId: 324,
  name: "zkSync Era",
  Network: "ETH",
  explorerUrl: "https://explorer.zksync.io",
  rpcUrl: `https://rpc.ankr.com/zksync_era/${REACT_APP_SCAN_KEY}`,
};

export const getAccountBalance = async (accountdata) => {
  const url = `${REACT_APP_ORACLE}/api/worth`;
  try {
    const { address, chain } = accountdata;
    let data = JSON.stringify({
      address: address,
      chain: chain,
      host: "overnight",
    });

    let config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };

    const response = await fetch(url, config);

    data = await response.json();
    console.log(data.data, "new");
    return data.data;
  } catch (error) {
    console.log(error);
    return { success: false, error: error };
  }
};

export const truncateString = (chain) => {
  return `${chain.slice(0, 4)}...`;
};

export const truncateAddressFx = (address) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const veloTokens = async () => {
  const url = `https://raw.githubusercontent.com/Migratooor/tokenLists/main/lists/velodrome.json`;
  try {
    let response;
    const resp = await axiosInstance.get(url);

    const data = resp.data;
    const { tokens } = data;

    const sortedTokens = tokens.sort((a, b) =>
      a.symbol.localeCompare(b.symbol)
    );

    if (sortedTokens) {
      return {
        success: true,
        data: sortedTokens,
      };
    }
  } catch (error) {
    console.log("Failed to fetch Ethereum Price:", error);
  }
};
export const velPools = [
  {
    name: "sAMMV2-USDC/sUSD",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9/logo.svg",
    poolFee: "0.05%",
    poolType: "Stable",
    poolAddress: "0x6d5B...6e15a",
    guageAddress: "0x55a2...2fF71",
    tvl: "~$8,529,260.2",
    apr: "3.98%",
    volume: "~$5,700,973.21",
    tokAVol: "2,934,557.41 USDC",
    tokBVol: "2,791,072.47 sUSD",
    Fees: "~$2,850.49",
    tokAfees: "1,467.28 USDC",
    tokBFees: "1,395.54 sUSD",
    poolBalanceA: "3,625,486.49 USDC",
    poolBalanceB: "4,945,890.57 sUSD",
  },
  {
    name: "sAMMV2-msETH/WETH",
    imageA:
      "https://assets.smold.app/api/token/10/0x1610e3c85dd44Af31eD7f33a63642012Dca0C5A5/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
    poolFee: "0.05%",
    poolType: "Stable",
    poolAddress: "0x917A...d5610",
    guageAddress: "0xdfE0...75Fb0",
    tvl: "~$6,759,358.36",
    apr: "14.46%",
    volume: "~$287,561.46",
    tokAVol: "66.56 msETH",
    tokBVol: "73.1 WETH",
    Fees: "~$143.78",
    tokAfees: "0.03328 msETH",
    tokBFees: "0.03655 WETH",
    poolBalanceA: "1,427.67 msETH",
    poolBalanceB: "1,855.49 WETH",
  },
  {
    name: "vAMMV2-OP/USDC",
    imageA:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0x0df0...69108",
    guageAddress: "0x3669...15639",
    tvl: "~$6,281,143.99",
    apr: "21.68%",
    volume: "~$2,191,692.02",
    tokAVol: "650,213.33 OP",
    tokBVol: "1,093,004.78 USDC",
    Fees: "~$6,575.08",
    tokAfees: "1,950.64 OP",
    tokBFees: "3,279.01 USDC",
    poolBalanceA: "1,857,700.78 OP",
    poolBalanceB: "3,142,144.13 USDC",
  },
  {
    name: "vAMMV2-STG/USDC",
    imageA:
      "https://assets.smold.app/api/token/10/0x296F55F8Fb28E498B858d0BcDA06D955B2Cb3f97/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0x5677...5e421",
    guageAddress: "0x21Cd...e6Db4",
    tvl: "~$6,250,740.13",
    apr: "15.92%",
    volume: "~$1,824,540.91",
    tokAVol: "1,613,855.72 STG",
    tokBVol: "939,198.72 USDC",
    Fees: "~$5,473.62",
    tokAfees: "4,841.57 STG",
    tokBFees: "2,817.6 USDC",
    poolBalanceA: "5,693,922.65 STG",
    poolBalanceB: "3,126,934.59 USDC",
  },
  {
    name: "sAMMV2-USDC/alUSD",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0xCB8FA9a76b8e203D8C3797bF438d8FB81Ea3326A/logo.svg",
    poolFee: "0.3%",
    poolType: "Stable",
    poolAddress: "0x4d79...1BB88",
    guageAddress: "0x172a...d44C0",
    tvl: "~$6,015,862.35",
    apr: "6.85%",
    volume: "~$299,526.92",
    tokAVol: "1114,162.65 USDC",
    tokBVol: "186,701.59 alUSD",
    Fees: "~$149.76",
    tokAfees: "57.08 USDC",
    tokBFees: "93.35 alUSD",
    poolBalanceA: "2,583,169.89 USDC",
    poolBalanceB: "3,457,930.28 alUSD",
  },
  {
    name: "vAMMV2-USDC/VELO",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db/logo.svg",
    poolFee: "1.0%",
    poolType: "Volatile",
    poolAddress: "0x8134...a54c5",
    guageAddress: "0x8419...30b7c",
    tvl: "~$5,304,502.86",
    apr: "66.8%",
    volume: "~$1,374,376.49",
    tokAVol: "678,446.69 USDC",
    tokBVol: "14,997,403.01 VELO",
    Fees: "~$13,743.76",
    tokAfees: "6,784.47 USDC",
    tokBFees: "149,974.03 VELO",
    poolBalanceA: "2,653,579.12 USDC",
    poolBalanceB: "57,129,340.02 VELO",
  },
  {
    name: "sAMMV2-alETH/WETH",
    imageA:
      "https://assets.smold.app/api/token/10/0x3E29D3A9316dAB217754d13b28646B76607c5f04/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
    poolFee: "0.05%",
    poolType: "Stable",
    poolAddress: "0xa105...6280a",
    guageAddress: "0xc16a...eee3C",
    tvl: "~$4,735,055.32",
    apr: "5.94%",
    volume: "~$306,681.74",
    tokAVol: "114.86 alETH",
    tokBVol: "51.17 WETH",
    Fees: "~$153.34",
    tokAfees: "0.05743 alETH",
    tokBFees: "0.02558 WETH",
    poolBalanceA: "1,837.11 alETH",
    poolBalanceB: "735.54 WETH",
  },
  {
    name: "vAMMV2-WETH/USDC",
    imageA:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0x0493...1235b",
    guageAddress: "0xE763...a3981",
    tvl: "~$4,692,910.37",
    apr: "10.85%",
    volume: "~$1,590,144.65",
    tokAVol: "389.58 WETH",
    tokBVol: "790,230.25 USDC",
    Fees: "~$4,770.43",
    tokAfees: "1.17 WETH",
    tokBFees: "2,370.69 USD",
    poolBalanceA: "1,142.23 WETH",
    poolBalanceB: "2,347,629.8 USDC",
  },
  {
    name: "vAMMV2-USDC/wUSDR",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0xC03b43d492d904406db2d7D57e67C7e8234bA752/logo.svg",
    poolFee: "1.0%",
    poolType: "Volatile",
    poolAddress: "0x95a0...F9E2E",
    guageAddress: "0x5B97...c36CD",
    tvl: "~$4,224,150.94",
    apr: "7.61%",
    volume: "~$158,423.65",
    tokAVol: "96,268.36 USDC",
    tokBVol: "106,120.94 wUSDR",
    Fees: "~$1,584.24",
    tokAfees: "962.68 USDC",
    tokBFees: "1,061.21 wUSDR",
    poolBalanceA: "2,113,132.75 USDC",
    poolBalanceB: "3,602,277.25 wUSDR",
  },
  {
    name: "sAMMV2-USDC/DOLA",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x8aE125E8653821E851F12A49F7765db9a9ce7384/logo.svg",
    poolFee: "0.3%",
    poolType: "Stable",
    poolAddress: "0xB720...bA645",
    guageAddress: "0xa103...e7923",
    tvl: "~$4,050,239.44",
    apr: "17.03%",
    volume: "~$257,208.85",
    tokAVol: "122,197.19 USDC",
    tokBVol: "136,386.51 DOLA",
    Fees: "~$771.63",
    tokAfees: "366.59 USDC",
    tokBFees: "409.16 DOLA",
    poolBalanceA: "1,739,390.12 USDC",
    poolBalanceB: "2,334,025.58 DOLA",
  },
  {
    name: "vAMMV2-wstETH/OP",
    imageA:
      "https://assets.smold.app/api/token/10/0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0x7178...5e62D",
    guageAddress: "0x790B...A5b19",
    tvl: "~$3,864,128.86",
    apr: "17.5%",
    volume: "~$465,796.31",
    tokAVol: "94.75 wstETH",
    tokBVol: "143,329.68 OP",
    Fees: "~$1,397.39",
    tokAfees: "0.28425 wstETH",
    tokBFees: "429.99 OP",
    poolBalanceA: "820.74 wstETH",
    poolBalanceB: "1,140,643.93 OP",
  },
  {
    name: "vAMMV2-WETH/OP",
    imageA:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0xd257...8a0D3",
    guageAddress: "0xCC53...F0292",
    tvl: "~$3,705,331.63",
    apr: "14.1%",
    volume: "~$839,359.61",
    tokAVol: "204.86 WETH",
    tokBVol: "1247,318.04 OP",
    Fees: "~$2,518.08",
    tokAfees: "0.61457 WETH",
    tokBFees: "741.95 OP",
    poolBalanceA: "902.61 WETH",
    poolBalanceB: "1,093,872.03 OP",
  },
  {
    name: "sAMMV2-USDC/MAI",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0xdFA46478F9e5EA86d57387849598dbFB2e964b02/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0xE54e...e34B0",
    guageAddress: "0xb4d1...499F3",
    tvl: "~$3,670,042.83",
    apr: "14.62%",
    volume: "~$245,095.65",
    tokAVol: "137,699.08 USDC",
    tokBVol: "143,922.99 MAI",
    Fees: "~$735.29",
    tokAfees: "413.1 USDC",
    tokBFees: "431.77 MAI",
    poolBalanceA: "1,083,863.03 USDC",
    poolBalanceB: "3,462,770.51 MAI",
  },
  {
    name: "sAMMV2-USDC/USX",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9/logo.svg",
    poolFee: "0.05%",
    poolType: "Stable",
    poolAddress: "0xeD47...7aa73",
    guageAddress: "0x95Aa...11E4a",
    tvl: "~$2,405,583.32",
    apr: "9.65%",
    volume: "~$140,486.06",
    tokAVol: "82,311.56 USDC",
    tokBVol: "58,480.85 USX",
    Fees: "~$70.24",
    tokAfees: "41.16 USDC",
    tokBFees: "29.24 USX",
    poolBalanceA: "1,062,888.01 USDC",
    poolBalanceB: "1,348,925.3 USX",
  },
  {
    name: "vAMMV2-wstETH/LDO",
    imageA:
      "https://assets.smold.app/api/token/10/0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0xFdb794692724153d1488CcdBE0C56c252596735F/logo.svg",
    poolFee: "0.3%",
    poolType: "Stable",
    poolAddress: "0xC5c2...80bAc",
    guageAddress: "0x8D26...8f2Af",
    tvl: "~$2,384,981.87",
    apr: "22.28%",
    volume: "~$256,860.59",
    tokAVol: "42.57 wstETH",
    tokBVol: "67,417.5 LDO",
    Fees: "~$770.58",
    tokAfees: "0.12771 wstETH",
    tokBFees: "202.25 LDO",
    poolBalanceA: "505.85 wstETH",
    poolBalanceB: "513,684.6 LDO",
  },
  {
    name: "vAMMV2-OP/VELO",
    imageA:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db/logo.svg",
    poolFee: "1.0%",
    poolType: "Volatile",
    poolAddress: "0xe958...2f937",
    guageAddress: "0x0F30...AF265",
    tvl: "~$2,364,182.61",
    apr: "43.41%",
    volume: "~$332,952.21",
    tokAVol: "99,971.04 OP",
    tokBVol: "53,527,760.84 VELO",
    Fees: "~$3,329.52",
    tokAfees: "999.71 OP",
    tokBFees: "35,277.61 VELO",
    poolBalanceA: "698,396.43 OP",
    poolBalanceB: "25,466,933.77 VELO",
  },
  {
    name: "sAMMV2-USDV/USDC",
    imageA:
      "https://assets.smold.app/api/token/10/0x323665443CEf804A3b5206103304BD4872EA4253/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    poolFee: "0.05%",
    poolType: "Stable",
    poolAddress: "0x73Da...1d3aE",
    guageAddress: "0xe473...040d8",
    tvl: "~$2,238,817.97",
    apr: "23.07%",
    volume: "~$1,335,247.53",
    tokAVol: "680,552.16 USDV",
    tokBVol: "657,152.35 USDC",
    Fees: "~$667.62",
    tokAfees: "340.28 USDV",
    tokBFees: "328.58 USDC",
    poolBalanceA: "1,236,376.57 USDV",
    poolBalanceB: "1,006,717.78 USDC",
  },
  {
    name: "vAMMV2-WETH/KWENTA",
    imageA:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x920Cf626a271321C151D027030D5d08aF699456b/logo.svg",
    poolFee: "1.0%",
    poolType: "Volatile",
    poolAddress: "0x8F47...b6B60",
    guageAddress: "0xE9BB...223C0",
    tvl: "~$2,229,113.23",
    apr: "56.99%",
    volume: "~$812,022.09",
    tokAVol: "199.98 WETH",
    tokBVol: "2,080.98 KWENTA",
    Fees: "~$8,120.22",
    tokAfees: "2.0 WETH",
    tokBFees: "20.81 KWENTA",
    poolBalanceA: "542.28 WETH",
    poolBalanceB: "5,783.97 KWENTA",
  },
  {
    name: "sAMMV2-USDC/msUSD",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x9dAbAE7274D28A45F0B65Bf8ED201A5731492ca0/logo.svg",
    poolFee: "0.05%",
    poolType: "Stable",
    poolAddress: "0xe148...05B4c",
    guageAddress: "0xf9dd...54ad6",
    tvl: "~$2,212,921.9",
    apr: "15.76%",
    volume: "~$186,008.93",
    tokAVol: "98,021.74 USDC",
    tokBVol: "88,129.96 msUSD",
    Fees: "~$93.0",
    tokAfees: "49.01 USDC",
    tokBFees: "44.06 msUSD",
    poolBalanceA: "1,090,625.27 USDC",
    poolBalanceB: "1,123,957.84 msUSD",
  },
  {
    name: "sAMMV2-USD+/DAI+",
    imageA:
      "https://assets.smold.app/api/token/10/0x73cb180bf0521828d8849bc8CF2B920918e23032/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x970D50d09F3a656b43E11B0D45241a84e3a6e011/logo.svg",
    poolFee: "0.3%",
    poolType: "Stable",
    poolAddress: "0x6670...8f223",
    guageAddress: "0x4Bc0...9A836",
    tvl: "~$2,093,500.9",
    apr: "9.91%",
    volume: "~$166.37",
    tokAVol: "56.19 USD+",
    tokBVol: "111.62 DAI+",
    Fees: "~$0.4991",
    tokAfees: "0.16857 USD+",
    tokBFees: "0.33486 DAI+",
    poolBalanceA: "995,020.37 USD+",
    poolBalanceB: "1,115,643.49 DAI+",
  },
  {
    name: "vAMMV2-USDC/SNX",
    imageA:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0x71d5...14A4A",
    guageAddress: "0x8761...27191",
    tvl: "~$1,803,867.72",
    apr: "19.75%",
    volume: "~$972,779.71",
    tokAVol: "481,842.64 USDC",
    tokBVol: "144,395.65 SNX",
    Fees: "~$2,918.34",
    tokAfees: "1,445.53 USDC",
    tokBFees: "433.19 SNX",
    poolBalanceA: "902,385.36 USDC",
    poolBalanceB: "265,151.44 SNX",
  },
  {
    name: "vAMMV2-WETH/mooBIFI",
    imageA:
      "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0xc55E93C62874D8100dBd2DfE307EDc1036ad5434/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0x6Ed6...A3BFE",
    guageAddress: "0xcFa0...011F9",
    tvl: "~$1,475,884.15",
    apr: "15.35%",
    volume: "~$292,160.43",
    tokAVol: "70.52 WETH",
    tokBVol: "390.01 mooBIFI",
    Fees: "~$876.48",
    tokAfees: "0.21156 WETH",
    tokBFees: "1.17 mooBIFI",
    poolBalanceA: "359.56 WETH",
    poolBalanceB: "1,952.17 mooBIFI",
  },
  {
    name: "sAMMV2-WBTC/tBTC",
    imageA:
      "https://assets.smold.app/api/token/10/0x68f180fcCe6836688e9084f035309E29Bf0A2095/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40/logo.svg",
    poolFee: "0.05%",
    poolType: "Volatile",
    poolAddress: "0x6e57...6926C",
    guageAddress: "0x2365...Ef9Ab",
    tvl: "~$1,434,929.4",
    apr: "0.88691%",
    volume: "~$148,499.34",
    tokAVol: "1.58 WBTC",
    tokBVol: "2.35 tBTC",
    Fees: "~$74.25",
    tokAfees: "0.00079 WBTC",
    tokBFees: "0.00118 tBTC",
    poolBalanceA: "16.78 WBTC",
    poolBalanceB: "21.2 tBTC",
  },
  {
    name: "vAMMV2-LYRA/USDC",
    imageA:
      "https://assets.smold.app/api/token/10/0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb/logo.svg",
    imageB:
      "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
    poolFee: "0.3%",
    poolType: "Volatile",
    poolAddress: "0xDB61...55224",
    guageAddress: "0x18A4...954b6",
    tvl: "~$1,396,275.24",
    apr: "24.48%",
    volume: "~$226,468.81",
    tokAVol: "1,416,160.42 LYRA",
    tokBVol: "80,193.7 USDC",
    Fees: "~$679.41",
    tokAfees: "4,248.48 LYRA",
    tokBFees: "240.58 USDC",
    poolBalanceA: "6,758,685.4 LYRA",
    poolBalanceB: "698,487.1 USDC",
  },
];

export const ERC20_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "authorizer",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
    ],
    name: "AuthorizationCanceled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "authorizer",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
    ],
    name: "AuthorizationUsed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_account",
        type: "address",
      },
    ],
    name: "Blacklisted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "newBlacklister",
        type: "address",
      },
    ],
    name: "BlacklisterChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "burner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Burn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "newMasterMinter",
        type: "address",
      },
    ],
    name: "MasterMinterChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Mint",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minterAllowedAmount",
        type: "uint256",
      },
    ],
    name: "MinterConfigured",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "oldMinter",
        type: "address",
      },
    ],
    name: "MinterRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Pause",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "newAddress",
        type: "address",
      },
    ],
    name: "PauserChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "newRescuer",
        type: "address",
      },
    ],
    name: "RescuerChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_account",
        type: "address",
      },
    ],
    name: "UnBlacklisted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Unpause",
    type: "event",
  },
  {
    inputs: [],
    name: "CANCEL_AUTHORIZATION_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RECEIVE_WITH_AUTHORIZATION_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TRANSFER_WITH_AUTHORIZATION_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "authorizer",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
    ],
    name: "authorizationState",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
    ],
    name: "blacklist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "blacklister",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "burn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "authorizer",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "cancelAuthorization",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "minterAllowedAmount",
        type: "uint256",
      },
    ],
    name: "configureMinter",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "Network",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "decrement",
        type: "uint256",
      },
    ],
    name: "decreaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "increment",
        type: "uint256",
      },
    ],
    name: "increaseAllowance",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "tokenName",
        type: "string",
      },
      {
        internalType: "string",
        name: "tokenSymbol",
        type: "string",
      },
      {
        internalType: "string",
        name: "tokenNetwork",
        type: "string",
      },
      {
        internalType: "uint8",
        name: "tokenDecimals",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "newMasterMinter",
        type: "address",
      },
      {
        internalType: "address",
        name: "newPauser",
        type: "address",
      },
      {
        internalType: "address",
        name: "newBlacklister",
        type: "address",
      },
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newName",
        type: "string",
      },
    ],
    name: "initializeV2",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "lostAndFound",
        type: "address",
      },
    ],
    name: "initializeV2_1",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
    ],
    name: "isBlacklisted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isMinter",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "masterMinter",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "minter",
        type: "address",
      },
    ],
    name: "minterAllowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pauser",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "validAfter",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "validBefore",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "receiveWithAuthorization",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "minter",
        type: "address",
      },
    ],
    name: "removeMinter",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20",
        name: "tokenContract",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "rescueERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rescuer",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "validAfter",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "validBefore",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "transferWithAuthorization",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
    ],
    name: "unBlacklist",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newBlacklister",
        type: "address",
      },
    ],
    name: "updateBlacklister",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newMasterMinter",
        type: "address",
      },
    ],
    name: "updateMasterMinter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newPauser",
        type: "address",
      },
    ],
    name: "updatePauser",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newRescuer",
        type: "address",
      },
    ],
    name: "updateRescuer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const EIP712ABI = [
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
      {
        constant: true,
        inputs: [],
        name: "eip712Domain",
        outputs: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const permitSingleAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint160",
        name: "amount",
        type: "uint160",
      },
      {
        internalType: "uint48",
        name: "expiration",
        type: "uint48",
      },
      {
        internalType: "uint48",
        name: "nonce",
        type: "uint48",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint160",
                name: "amount",
                type: "uint160",
              },
              {
                internalType: "uint48",
                name: "expiration",
                type: "uint48",
              },
              {
                internalType: "uint48",
                name: "nonce",
                type: "uint48",
              },
            ],
            internalType: "struct IAllowanceTransfer.PermitDetails",
            name: "details",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "sigDeadline",
            type: "uint256",
          },
        ],
        internalType: "struct IAllowanceTransfer.PermitSingle",
        name: "permitSingle",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint160",
        name: "amount",
        type: "uint160",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const permitBatchAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint160",
        name: "amount",
        type: "uint160",
      },
      {
        internalType: "uint48",
        name: "expiration",
        type: "uint48",
      },
      {
        internalType: "uint48",
        name: "nonce",
        type: "uint48",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint160",
                name: "amount",
                type: "uint160",
              },
              {
                internalType: "uint48",
                name: "expiration",
                type: "uint48",
              },
              {
                internalType: "uint48",
                name: "nonce",
                type: "uint48",
              },
            ],
            internalType: "struct IAllowanceTransfer.PermitDetails[]",
            name: "details",
            type: "tuple[]",
          },
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "sigDeadline",
            type: "uint256",
          },
        ],
        internalType: "struct IAllowanceTransfer.PermitBatch",
        name: "permitBatch",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint160",
            name: "amount",
            type: "uint160",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
        ],
        internalType: "struct IAllowanceTransfer.AllowanceTransferDetails[]",
        name: "transferDetails",
        type: "tuple[]",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const bridgableTokens = async () => {
  const url = "https://v2.api.squidrouter.com/v2/sdk-info";
  try {
    let tokenResponse, chainResponse;
    const axiosInstance = axios.create({
      headers: {
        "X-Integrator-Id": REACT_APP_INTEGRATOR,
      },
    });
    const resp = await axiosInstance.get(url);

    const data = resp.data;
    const tokens = data.tokens;
    const chains = data.chains;

    chainResponse = chains.map((chain) => ({
      name: chain.networkName,
      chainId: chain.chainId,
      image: chain.chainIconURI,
      identifier: chain.networkIdentifier,
    }));

    tokenResponse = tokens.map((token) => ({
      symbol: token.symbol.toUpperCase(),
      name: token.name,
      image: token.logoURI,
      currentPrice: token.usdPrice,
      chain: token.chainId,
    }));

    return { tokenResponse, chainResponse };
  } catch (error) {
    console.log("Failed to fetch bridgableTokens:", error);
  }
};

export const fetchRezTokens = async () => {
  const url =
    "https://pro-api.coingecko.com/api/v3/simple/price?ids=renzo-restaked-eth%2Cwrapped-bitcoin%2Cstaked-ether%2Cethereum&vs_currencies=usd";

  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "x-cg-pro-api-key": "CG-Kkgzp67663GNAbnaDiDH3MG2",
      },
    });
    const data = await res.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const fetchTokens = async (chain) => {
  const url = `https://api.coingecko.com/api/v3/coins/markets?category=${chain}&vs_Network=usd`;
  try {
    let response;
    const resp = await axiosInstance.get(url);

    console.log(resp);

    const data = resp.data;

    response = data.map((token) => ({
      symbol: token.symbol.toUpperCase(),
      name: token.name,
      image: token.logo,
      currentPrice: token.current_price,
    }));

    return response;
  } catch (error) {
    console.log("Failed to fetch Ethereum Price:", error);
  }
};

export const supportedWallets = [
  {
    name: "MetaMask",
    image:
      "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711372422/helpers/mm_xkqhu2.svg",
    url: "https://metamask.io/",
  },
  {
    name: "WalletConnect",
    image:
      "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711372422/helpers/wc_dqwki8.svg",
    url: "https://walletconnect.org/",
  },
  {
    name: "Binance Web3 Wallet",
    image:
      "https://res.cloudinary.com/dcxwxzigo/image/upload/v1715260206/helpers/image_syckvi.jpg",
    url: "",
  },
  {
    name: "OKX Wallet",
    image:
      "https://res.cloudinary.com/dcxwxzigo/image/upload/v1715260312/helpers/image_n8sayw.png",
    url: "",
  },
  {
    name: "Coinbase Wallet",
    image:
      "https://res.cloudinary.com/dcxwxzigo/image/upload/v1715260349/helpers/coinbase.69d24939_ejsulh.svg",
    url: "",
  },
];

export const BENQI_ADDRESS = "0x8729438eb15e2c8b576fcc6aecda6a148776c0f5";

export const MaxAllowance96Bit = "79228162514264337593543950335";
export const max96BitBigNumber = BigNumber.from(MaxAllowance96Bit);

export function devLog(...args) {
  if (process.env.NODE_ENV === "development") {
    console.log(...args);
  }
}
