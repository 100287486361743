const ftData = [
    {
        id: 1,
        title: "Developers",
        text1: "GitHub",
        text2: "Audits",
        text3: "Docs",
        text4: "Bug Bounty",
        text1Link: "https://github.com/Renzo-Protocol/contracts-public/blob/master/Audit/Renzo_Protocol_EVM_Contracts_Smart_Contract_Security_Assessment.pdf",
        text2Link: "https://github.com/Renzo-Protocol/contracts-public/blob/master/Audit/Renzo_Protocol_EVM_Contracts_Smart_Contract_Security_Assessment.pdf",
        text3Link: "https://docs.renzoprotocol.com/",
        text4Link: "https://immunefi.com/api/preview?secret=2tWYPTv0HQp2QVme03BxvyWs7G9XaIcSLY5cUpaHWoc_&slug=renzoprotocol&path=/bounty/renzoprotocol&content_type=bounty",
    },
    {
        id: 2,
        title: "Community",
        text1: "Twitter (X)",
        text2: "Telegram",
        text3: "Mirror",
        text4: "Discord",
        text1Link: "https://twitter.com/RenzoProtocol",
        text2Link: "https://t.me/RenzoProtocolChat",
        text3Link: "https://mirror.xyz/renzoprotocol.eth",
        text4Link: "https://discord.gg/FMwGPDXXtf",
    },
];
export default ftData