import { ftData, InvestorsData, inf, c, dev, com } from "./Maps";
import Marquee from "react-fast-marquee";
import Background from "./Background";
import { useState } from "react";
import { Link } from "react-router-dom";

const Layout = () => {
  const [page, setPage] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  let right = (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="arrow-right"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
    </svg>
  );
  let down = (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="down"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
    </svg>
  );

  return (
    <section className="  min-h-[100vh] w-[100%] overflow-x-hidden smooth">
      <Background setPage={setPage} />

      <section className="flex flex-col items-center justify-start w-[100%] pt-[50px]">
        <header
          className={` ${
            page === 0 && "lg:hover:scale-[1.1]"
          } bg-[#8f96bf1a] backdrop-blur-md w-[80%] rounded-[2rem] py-[.75rem] px-[2rem]
      flex flex-col items-center justify-between lg:relative absolute w-[100%] z-50 smooth`}
        >
          <section className="flex flex-row items-center justify-between w-[100%]">
            <img src="./images/logo.svg" className="w-[100px]" />

            <section className="lg:flex flex-row items-center hidden xl:w-[70%] xl:justify-center">
              <div
                className="flex flex-row items-center text-[#a3a4bf] text-[14px] cursor-pointer"
                onMouseEnter={() => {
                  setPage(1);
                }}
              >
                Getting Started <h1 className="pl-[7px]">{down}</h1>
              </div>
              <div
                className="flex flex-row items-center text-[#a3a4bf] text-[14px] px-[20px] cursor-pointer"
                onMouseEnter={() => {
                  setPage(2);
                }}
              >
                Developers <h1 className="pl-[7px]">{down}</h1>
              </div>
              <div
                className="flex flex-row items-center text-[#a3a4bf] text-[14px] cursor-pointer"
                onMouseEnter={() => {
                  setPage(3);
                }}
              >
                Community <h1 className="pl-[7px]">{down}</h1>
              </div>

              <div className="flex flex-row items-center text-[#a3a4bf] text-[14px] cursor-pointer pl-[20px] disabled:opacity-75">
                Claim $REZ <h1 className="pl-[7px]">{right}</h1>
              </div>
            </section>
            <Link
              to="/app/stakerez"
              className="hidden xl:flex uppercase cursor-pointer bg-[#ace731] hover:scale-[1.1] z-40 text-[15px] font-[700] py-[.5rem] px-[2.5rem] rounded-full"
            >
              stake $REZ
            </Link>

            <i
              class="bx bx-menu lg:hidden flex text-[40px] text-[#a3a4bf] cursor-pointer"
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            ></i>
          </section>

          {openMenu && (
            <section className="flex flex-col items-start w-[100%] justify-start mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <h1 className="text-white font-[600] text-[16px] flex flex-row items-center pb-[20px]">
                Claim $REZ <h1 className="pl-[10px]">{right}</h1>
              </h1>
              <h1 className="text-white text-[20px] pb-[20px]">
                Getting Started
              </h1>

              <div className="flex flex-col items-start justify-start flex-wrap bg-[#51525f] w-[100%] rounded-[.5rem] p-[30px] bg-opacity-50">
                <button
                  onClick={() => {
                    const element = document.getElementById("w1");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[14px] font-[400] pt-[10px] text-white w-[100%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1]"
                >
                  What is EigenLayer{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("w2");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[14px] font-[400] pt-[10px] text-white w-[100%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1]"
                >
                  What is Renzo{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("partners");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[14px] font-[400] pt-[10px] text-white w-[100%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1]"
                >
                  Partners{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
              </div>
              <h1 className="text-white text-[20px] py-[20px]">Developers</h1>

              <div className="flex flex-col items-start justify-start flex-wrap bg-[#51525f] w-[100%] rounded-[.5rem] p-[30px] bg-opacity-50">
                {dev.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[100%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>

              <h1 className="text-white text-[20px] py-[20px]">Community</h1>

              <div className="flex flex-col items-start justify-start flex-wrap bg-[#51525f] w-[100%] rounded-[.5rem] p-[30px] bg-opacity-50">
                {com.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[100%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>
            </section>
          )}

          {page === 1 && (
            <section className="hidden lg:flex flex-row items-center justify-between w-[100%] mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <div className="bg-[#ace731] w-[40%] rounded-[1.5rem] p-[30px]">
                <h1 className="font-[700] text-[20px] text-black">
                  Getting Started
                </h1>

                <h1 className="text-black text-[16px] pt-[15px]">
                  Get introduced to EigenLayer or Renzo
                </h1>
              </div>

              <div className="flex flex-row items-start justify-start flex-wrap">
                <button
                  onClick={() => {
                    const element = document.getElementById("w1");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[16px] font-[400] pt-[10px] text-white w-[50%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1.1]"
                >
                  What is EigenLayer{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("w2");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[16px] font-[400] pt-[10px] text-white w-[50%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1.1]"
                >
                  What is Renzo{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("partners");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[16px] font-[400] pt-[10px] text-white w-[50%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1.1]"
                >
                  Partners{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
              </div>
            </section>
          )}

          {page === 2 && (
            <section className="hidden lg:flex flex-row items-center justify-between w-[100%] mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <div className="bg-[#ace731] w-[40%] rounded-[1.5rem] p-[30px]">
                <h1 className="font-[700] text-[20px] text-black">
                  Developers
                </h1>

                <h1 className="text-black text-[16px] pt-[15px]">
                  Everything you need to learn about Renzo and how to use it
                </h1>
              </div>

              <div className="flex flex-row items-start justify-start flex-wrap w-[40%]">
                {dev.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[50%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1.1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>
            </section>
          )}

          {page === 3 && (
            <section className="hidden lg:flex flex-row items-center justify-between w-[100%] mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <div className="bg-[#ace731] w-[40%] rounded-[1.5rem] p-[30px]">
                <h1 className="font-[700] text-[20px] text-black">Community</h1>

                <h1 className="text-black text-[16px] pt-[15px]">
                  Connect, collaborate, and grow with the Renzo community
                </h1>
              </div>

              <div className="flex flex-row items-start justify-start flex-wrap w-[40%]">
                {com.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[50%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1.1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>
            </section>
          )}
        </header>

        <section
          className="pt-[80px] flex flex-col items-center justify-center text-center"
          onMouseEnter={() => {
            setPage(0);
          }}
        >
          <h1 className="text-white text-[72px] font-[700]">
            <span className="text-[#ace731]">ez</span>ETH Restaking
          </h1>
          <h1 className="text-[#a3a4bf] text-[24px] font-[400] pb-[50px]">
            The EigenLayer Liquid Restaking Hub
          </h1>

          <Link
            to="/app/stakerez"
            className="xl:hidden flex uppercase cursor-pointer bg-[#ace731] hover:scale-[1.1] z-40 text-[20px] font-[700] py-[.5rem] px-[3.5rem] rounded-full"
          >
            stake $REZ
          </Link>

          <img
            src="https://www.renzoprotocol.com/logos/eth-dark.svg"
            className="lg:mt-[-200px] lg:mb-[-200px] z-10"
          />
        </section>

        <section
          className="flex flex-row items-center w-[100%]"
          onMouseEnter={() => {
            setPage(0);
          }}
        >
          <Marquee speed={30}>
            {inf.map((us, id) => (
              <div
                key={id}
                className="w-[150px] border-solid border-r-[1px] border-[#15192c] flex flex-col items-center justify-center text-white"
              >
                <h1 className="text-[12px] text-[#ffffff80]">{us.title}</h1>
                <h2 className="flex text-[20px] font-[700]">{us.body}</h2>
              </div>
            ))}
          </Marquee>
        </section>

        <section
          className="flex flex-row items-center flex-wrap w-[100%] justify-center pt-[100px] lg:pt-[200px]"
          onMouseEnter={() => {
            setPage(0);
          }}
        >
          {c.map((us, id) => (
            <div
              key={id}
              className="w-[80%] hover:scale-[1.1] smooth lg:w-[30%] lg:mx-[25px] flex flex-col items-center justify-center"
            >
              {us.body}
            </div>
          ))}
        </section>

        <section className="flex flex-col items-center justify-center py-[100px]">
          <section
            className="flex flex-row items-center w-[70%] justify-between text-white"
            id="w1"
          >
            <div className="z-40 w-[100%] lg:w-[50%]">
              <h1 className="text-[20px] lg:text-[36px] font-[700] pb-[50px]">
                What is EigenLayer
              </h1>
              <div className="flex flex-col items-start w-[100%] text-white">
                <h1 className="text-[17px] lg:text-[24px] font-[400]">
                  A new DeFi primitive, restaking, that allows Ethereum users to
                  stake their ETH and secure Actively Validated Services (AVSs)
                  that generate rewards in addition to staking rewards.
                </h1>
                <a
                  className="underline text-[17px] lg:text-[24px] font-[400] pt-[10px]"
                  href="https://docs.eigenlayer.xyz/overview/readme"
                >
                  EigenLayer Doc
                </a>
              </div>
            </div>

            <img src="./images/w1.webp" className="w-[240px] lg:flex hidden" />
          </section>

          <section
            className="flex flex-row items-center w-[70%] justify-between text-white pt-[150px]"
            id="w2"
          >
            <div className="z-40 w-[100%] lg:w-[50%]">
              <h1 className="text-[20px] lg:text-[36px] font-[700] pb-[50px]">
                What is Renzo
              </h1>
              <div className="flex flex-col items-start w-[100%] text-white">
                <h1 className="text-[17px] lg:text-[24px] font-[400]">
                  Renzo is a Liquid Restaking Token (LRT) and Strategy Manager
                  for EigenLayer. It is the interface to the EigenLayer
                  ecosystem securing Actively Validated Services (AVSs) and
                  offering a higher yield than ETH staking.
                </h1>

                <h1 className="text-[17px] lg:text-[24px] font-[400] py-[20px]">
                  The protocol abstracts all complexity from the end-user and
                  enables easy collaboration between users and EigenLayer node
                  operators.
                </h1>
                <a
                  className="underline text-[17px] lg:text-[24px] font-[400] pt-[10px]"
                  href="https://docs.eigenlayer.xyz/overview/readme"
                >
                  Renzo Docs
                </a>
              </div>
            </div>

            <img src="./images/w2.svg" className="w-[240px] lg:flex hidden" />
          </section>
        </section>

        <section
          id="partners"
          className="flex flex-col items-center justify-center w-[80%] pb-[100px] pt-[150px] z-40"
        >
          <h1 className="text-[30px] font-[700] text-[#e6e7f273] pb-[100px]">
            Investors
          </h1>

          <section className="flex flex-row items-start flex-wrap justify-center z-40">
            {InvestorsData.map((us, id) => (
              <a
                key={id}
                className="w-[100%] lg:w-[27%] m-[20px] flex flex-col items-center justify-center 
                      bg-[#040717]  border-solid border-[1px] rounded-[1rem] border-white h-[144px] bx2"
                href={us.link}
              >
                <img src={us.logo} className="w-[160px]" />
              </a>
            ))}
          </section>
        </section>
      </section>

      <footer className="flex bg-[#00000040] flex-col items-start justify-between z-40">
        <section
          className="flex bg-[#02030d] bg-opacity-60 px-[20px] py-[40px] lg:p-[70px] flex-col lg:flex-row items-start w-[100%] 
        justify-between pb-[100px] z-40 bg-[#02030d"
        >
          <div className="w-[50%] z-40">
            <img src="./images/logo.svg" className="w-[112px]" />
          </div>

          <section className="w-[100%] lg:w-[50%] flex flex-col lg:flex-row items-start justify-between z-40 ">
            <div className="flex flex-col items-start pt-[40px] lg:pt-[0px]">
              <h1 className="text-white font-[600] text-[16px] ">
                Getting Started
              </h1>

              <button
                onClick={() => {
                  const element = document.getElementById("w1");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
                className="text-[16px] font-[400] pt-[10px] text-white"
              >
                What is EigenLayer
              </button>
              <button
                onClick={() => {
                  const element = document.getElementById("w2");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
                className="text-[16px] font-[400] pt-[10px] text-white"
              >
                What is Renzo
              </button>
              <button
                onClick={() => {
                  const element = document.getElementById("partners");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
                className="text-[16px] font-[400] pt-[10px] text-white"
              >
                Partners
              </button>
            </div>

            {ftData?.map((us, id) => (
              <div
                key={id}
                className="flex flex-col items-start lg:pt-[0px] pt-[40px]"
              >
                <h1 className="text-white font-[600] text-[16px]">
                  {us.title}
                </h1>

                <a
                  href={us.text1Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text1}
                </a>
                <a
                  href={us.text2Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text2}
                </a>
                <a
                  href={us.text3Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text3}
                </a>
                <a
                  href={us.text4Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text4}
                </a>
              </div>
            ))}
          </section>
        </section>

        <div className="bg-[#02030d] px-[20px] lg:p-[70px] z-40 w-[100%] bg-opacity-60 pb-[70px]">
          <section className="border-solid border-t-[1px]  border-[#36374d] w-[100%] pt-[10px] z-40">
            <h1 className="text-[12px] font-[400] text-[#36374d] pl-[20px]">
              © Renzo 2024 All Rights Reserved
            </h1>
          </section>
        </div>
      </footer>
    </section>
  );
};

export default Layout;
