import { e, r } from "../../Maps";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EigenLayer = ({}) => {
  const { isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);

  return (
    <section
      className="bg-transparent dark:bg-[#050712] w-[100%] h-[100vh] lg:h-[80vh] overflow-y-scroll
      lg:p-[50px] lg:pb-[0px] pb-[150px] pt-[80px] flex flex-col items-start justify-start"
      style={{
        borderRadius: "0px 0px 1.25rem 0px",
      }}
    >
      <h1 className="dark:text-white font-[700] pl-[10px] pb-[10px]">
        Actively Validated Services
      </h1>

      <section className="w-[100%] lg:grid-cols-3 lg:grid-rows-3 lg:grid flex flex-col items-center justify-center">
        {e.map((us, id) => (
          <div
            key={id}
            className="w-[95%] m-[10px] flex flex-col justify-between bg-white dark:bg-[#0d1122] p-[1.5rem]
                    border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem] h-[320px]"
          >
            <section className="flex flex-col items-start w-[100%]">
              <a
                href={us.link}
                className="flex flex-row items-center border-solid border-b-[1px] pb-[10px] dark:border-white border-[#7a7c99] w-[100%]"
              >
                <img src={us.image} className="w-[40px]" />
                <h1 className="text-black dark:text-white pl-[10px] text-[20px] font-[600]">
                  {us.title}
                </h1>
              </a>

              <h1 className="text-[16px] font-[400] text-[#7a7c99] pt-[10px]">
                {us.body}
              </h1>
            </section>

            <section className="flex flex-row items-end justify-end w-[100%]">
              {us.point ? (
                <div className="dark:text-white text-black flex flex-row items-center">
                  <h1 className="text-[30px] ">{us.points}</h1>
                  <h1 className="text-[16px] pl-[10px] pt-[12px]">Points</h1>
                </div>
              ) : (
                <div
                  className="bg-[#f1f1f1] dark:bg-[#36374d] text-[.875rem] text-[#7a7c99] py-[.25rem] w-[60%]
                            flex flex-row items-center justify-center rounded-[9999px] capitalize"
                >
                  rewards coming soon
                </div>
              )}
            </section>
          </div>
        ))}
      </section>

      <h1 className="dark:text-white font-[700] pl-[10px] pt-[30px] pb-[10px]">
        Restake Operators
      </h1>

      <section className="w-[100%] lg:grid-cols-3 lg:grid-rows-3 lg:grid flex flex-col items-center justify-center">
        {r.map((us, id) => (
          <div
            key={id}
            className="w-[95%] m-[10px] flex flex-col justify-between bg-white dark:bg-[#0d1122] p-[1.5rem]
                    border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem] h-[200px]"
          >
            <section className="flex flex-col items-start w-[100%]">
              <a
                href={us.link}
                className="flex flex-row items-center border-solid border-b-[1px] pb-[10px] dark:border-white border-[#7a7c99] w-[100%]"
              >
                <img src={us.image} className="w-[40px]" />
                <h1 className="text-black dark:text-white pl-[10px] text-[20px] font-[600]">
                  {us.title}
                </h1>
              </a>
            </section>

            <section className="flex flex-row items-end justify-end w-[100%]">
              {us.addy !== "" ? (
                <div className="dark:text-white text-black flex flex-row items-end justify-between w-[100%]">
                  <h1 className="dark:text-white text-black text-[16px]">
                    {us.addy}
                  </h1>
                  <div className="flex flex-col items-end justify-end">
                    <h1 className="text-[30px] ">{us.value}</h1>
                    <h1 className="text-[12px]">ETH Delegated</h1>
                  </div>
                </div>
              ) : (
                <div
                  className="bg-[#f1f1f1] dark:bg-[#36374d] text-[.875rem] text-[#7a7c99] py-[.25rem] w-[60%]
                            flex flex-row items-center justify-center rounded-[9999px] capitalize"
                >
                  rewards coming soon
                </div>
              )}
            </section>
          </div>
        ))}
      </section>
    </section>
  );
};

export default EigenLayer;
