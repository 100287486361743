import { useEffect } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useNavigate } from "react-router-dom";

const Portfolio = ({ theme, setShowConnect }) => {
  const { isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);
  return (
    <section
      className="bg-transparent dark:bg-[#050712] w-[100%] min-h-[100vh] lg:min-h-[80vh] 
      lg:p-[50px] lg:px-[100px] p-[15px] pb-[150px] pt-[100px] flex flex-col items-center justify-center"
      style={{
        borderRadius: "0px 0px 1.25rem 0px",
      }}
    >
      <img
        src={
          theme === "dark" ? "./images/pt-dark.svg" : "./images/pt-white.svg"
        }
        className="w-[180px] lg:w-[280px]"
      />

      <h1 className="dark:text-white text-[2.25rem] font-[700] w-[100%] lg:w-[60%] text-center greek">
        Looks like you haven't connected your wallet yet
      </h1>
      <h1 className="text-[1.25rem] font-[400] text-[gray] text-center w-[100%] lg:w-[60%] pb-[30px]">
        Portfolio is not available at this time because your wallet is not
        connected
      </h1>

      <button
        onClick={() => {
          setShowConnect(true);
        }}
        className="flex flex-row items-center px-[1.5rem] h-[50px] rounded-[.75rem] bg-[#ace731] text-black"
      >
        <h1 className="pr-[5px] font-[700] text-[1.125rem]">Connect wallet</h1>
        <i class="bx bxs-wallet text-black"></i>
      </button>
    </section>
  );
};

export default Portfolio;
