

const e = [
    {
        id: 1,
        title: "EigenDA",
        body: "EigenDA is a data availability store made by EigenLabs and built on top of EigenLayer.",
        image: "./images/e1.webp",
        points: "",
        point: false,
        link: "https://docs.eigenlayer.xyz/eigenda/overview/"
    },
    {
        id: 2,
        title: "eoracle",
        body: "The modular and programmable oracle network secured by Ethereum, built with Eigenlayer.",
        image: "./images/e2.svg",
        points: "4.31M",
        point: true,
        link: "https://www.eoracle.io/"
    },
    {
        id: 3,
        title: "AltLayer",
        body: "AltLayer MACH is a fast finality AVS for OP Mainnet and Arbitrum One. As an AVS, users can delegate ETH or LSTs to any of the registered operators.",
        image: "./images/e3.webp",
        points: "",
        point: false,
        link: "https://altlayer.io/"
    },
    {
        id: 4,
        title: "Brevis coChain AVS",
        body: "Brevis is a Smart ZK Coprocessor For Blockchain run on EigenLayer.",
        image: "./images/e4.webp",
        points: "",
        point: false,
        link: "https://brevis.network/"
    },
    {
        id: 5,
        title: "Lagrange",
        body: "Lagrange State Committees are a ZK light client protocol for optimistic rollups that combine EigenLayer's restaked security with Lagrange's ZK Coprocessor.",
        image: "./images/e5.webp",
        points: "",
        point: false,
        link: "https://www.lagrange.dev/"
    },
    {
        id: 6,
        title: "Witness Chain",
        body: "The EigenLayer AVS for DePIN coordination. It is the first physical state consensus protocol which is unifying a siloed DePIN economy.",
        image: "./images/e6.webp",
        points: "",
        point: false,
        link: "https://www.witnesschain.com/"
    },
    {
        id: 7,
        title: "Omni Network",
        body: "Omni is an Ethereum-native interoperability protocol that establishes low latency communications across Ethereum’s rollup ecosystem.",
        image: "./images/e7.webp",
        points: "",
        point: false,
        link: "https://omni.network/"
    },
    {
        id: 8,
        title: "Xterio Mach",
        body: "Xterio is a Web3 game developer and publisher founded by veterans of the game industry. ",
        image: "./images/e8.webp",
        points: "",
        point: false,
        link: "https://xter.io/"
    },

];

export default e