import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGatekeeper } from "../../../context/Gatekeeper";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import useWeb3Actions from "../../../hooks/useWeb3ModalActions";
import {
  balObj,
  fetchRezTokens,
  updateTokenBalance,
} from "../../../helper/web3Modalutils";
import { useNavigate } from "react-router-dom";

const Swap = ({
  theme,
  coin,
  setShowOptions,
  setShowConnect,
  targetCoin,
  isBase,
  setIsBase,
}) => {
  const location = useLocation();
  useEffect(() => {}, [location]);

  const { isConnected, chainId } = useWeb3ModalAccount();
  const [tokenStatus, setTokenStatus] = useState(false);
  const [tokenPrices, setTokenPrices] = useState(null);
  const [inputFrom, setInputFrom] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);

  const {
    isLoading,
    setIsLoading,
    tokenList,
    setTokenList,
    balance,
    setBalance,
  } = useGatekeeper();
  const { action } = useWeb3Actions({
    isLoading,
    setIsLoading,
    tokenList,
    setTokenList,
    balance,
    setBalance,
  });

  const [amount, setAmount] = useState(0);
  const [equivalentTokenBAmount, setEquivalentTokenBAmount] = useState(0);
  const [equivalentTokenAAmount, setEquivalentTokenAAmount] = useState(0);
  const [usdEquivalentAmount, setUsdEquivalentAmount] = useState(0);
  const [usdEquivalentTokenB, setUsdEquivalentTokenB] = useState(0);
  const [updatedCoinWithPrice, setUpdatedCoinWithPrice] = useState({ ...coin });
  const [updatedTargetCoinWithPrice, setUpdatedTargetCoinWith] = useState({
    ...targetCoin,
  });

  useEffect(() => {
    const getPrices = async () => {
      if (!tokenStatus) {
        try {
          let prices = await fetchRezTokens();
          console.log(prices, "ubyvtfcjkgvubli");
          if (prices) {
            const nameToIdMap = {
              Ethereum: "staked-ether",
              "Renzo Restaked ETH": "renzo-restaked-eth",
              "Wrapped Bitcoin": "wrapped-bitcoin",
            };

            const getPriceForCoin = (coinName) => {
              const coinId = nameToIdMap[coinName];
              return prices && prices[coinId] ? prices[coinId].usd : null;
            };

            const updatedCoinWithPrice = {
              ...coin,
              price: getPriceForCoin(coin?.name),
            };

            const updatedTargetCoinWithPrice = {
              ...targetCoin,
              price: getPriceForCoin(targetCoin?.name),
            };

            setUpdatedCoinWithPrice({ ...updatedCoinWithPrice });
            setUpdatedTargetCoinWith({ ...updatedTargetCoinWithPrice });
            setTokenStatus(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    getPrices();
  }, [tokenStatus]);

  useEffect(() => {
    const updateBalance = async () => {
      if (isConnected) {
        try {
          console.log(balance.tokenList, "dhbsdhsdhsdhb");
          const list = [coin, targetCoin];
          console.log(list);
          const chb = balObj[chainId];
          const tokenUpdate = updateTokenBalance(list, balance.tokenList, chb);
          console.log(tokenUpdate, "hiuyucyf");
        } catch (error) {
          console.log(error);
        }
      }
    };

    updateBalance();
  }, [isConnected, balance]);

  const onlyNumbers = (e) => {
    const currentValue = e.target.value;
    const sanitizedValue = currentValue
      .replace(/[^0-9.]/g, "") // Remove non-numeric and non-decimal characters
      .replace(/(\..*?)\..*/g, "$1") // Remove multiple decimal points
      .replace(/^0+(?=\d)/, ""); // Remove leading zeros
    e.target.value = sanitizedValue;

    // Determine if the input is coming from the base or target input
    setInputFrom(e.target.name);
  };

  const handleAmountChange = (e) => {
    const input = e.target.value;
    const inputSource = e.target.name;

    if (inputSource === "target") {
    }

    if (input === "") {
      setUsdEquivalentAmount(0);
      setEquivalentTokenBAmount(0);
      setUsdEquivalentTokenB(0);
      setAmount(0);
      setInputFrom(""); // Reset inputFrom when the input is empty
      return;
    }

    if (isNaN(input)) {
      setUsdEquivalentAmount(0);
      setEquivalentTokenBAmount(0);
      setUsdEquivalentTokenB(0);
      setAmount(0);
    } else {
      setAmount(input);
    }
  };

  useEffect(() => {
    if (tokenStatus) {
      if (amount === "") {
        setUsdEquivalentAmount(0);
        setEquivalentTokenBAmount(0);
        setUsdEquivalentTokenB(0);
        return; // Exit early if the amount is empty
      }

      if (
        updatedCoinWithPrice &&
        updatedTargetCoinWithPrice &&
        !isNaN(amount)
      ) {
        console.log(updatedCoinWithPrice, updatedTargetCoinWithPrice);

        const toTokenPrice = updatedTargetCoinWithPrice.price;
        const fromTokenPrice = updatedCoinWithPrice.price;

        console.log(toTokenPrice, fromTokenPrice, amount, inputFrom);
        let calculatedUsdEquivalentTokenA,
          calculatedEquivalentTokenB,
          calculatedUsdEquivalentTokenB,
          calculatedEquivalentTokenA;

        if (inputFrom === "base") {
          calculatedUsdEquivalentTokenA = (amount * fromTokenPrice).toFixed(8);
          calculatedEquivalentTokenB = (
            calculatedUsdEquivalentTokenA *
            (1 / toTokenPrice)
          ).toFixed(8);
          calculatedUsdEquivalentTokenB = (
            calculatedEquivalentTokenB * toTokenPrice
          ).toFixed(8);
        } else if (inputFrom === "target") {
          calculatedUsdEquivalentTokenB = (amount * toTokenPrice).toFixed(8);
          calculatedEquivalentTokenA = (
            calculatedUsdEquivalentTokenB *
            (1 / fromTokenPrice)
          ).toFixed(8);
          calculatedUsdEquivalentTokenA = (
            calculatedEquivalentTokenA * toTokenPrice
          ).toFixed(8);
        }

        if (inputFrom === ("base" || "target")) {
          console.log("here");
          setUsdEquivalentAmount(calculatedUsdEquivalentTokenA);
          setEquivalentTokenBAmount(calculatedEquivalentTokenB);
          setEquivalentTokenAAmount(calculatedEquivalentTokenA); //
          setUsdEquivalentTokenB(calculatedUsdEquivalentTokenB);
        }
      }
    }
  }, [
    amount,
    updatedCoinWithPrice,
    updatedTargetCoinWithPrice,
    tokenStatus,
    inputFrom,
  ]);

  console.log(tokenStatus, updatedCoinWithPrice);
  return (
    <section
      className="flex flex-col items-center justify-center w-[100%] sw
"
    >
      <section
        className={`flex flex-col items-center justify-center w-[100%] dark:bg-[#050712] bg-white
        rounded-[1rem] p-[20px] ${theme === "dark" && "bsh"} `}
      >
        {location.pathname !== "/app/stakerez" && (
          <div className="flex flex-row items-center space-x-4 w-[100%] pb-[10px]">
            <h1 className="dark:text-white text-[16px]">Restake</h1>
            <h1 className="dark:text-white text-[16px]">Swap</h1>
          </div>
        )}

        <div
          className={`flex flex-row items-center ${
            location.pathname === "/app/stakerez"
              ? "justify-between"
              : "justify-end"
          } w-[100%] pb-[10px]`}
        >
          {location.pathname === "/app/stakerez" && (
            <h1 className="dark:text-white text-[16px]">Stake</h1>
          )}
          <h1 className="text-[#6b7280] text-[12px]">Balance: 0.00</h1> //
          fixxx!!!
        </div>

        <div className="flex flex-row items-start justify-between border-solid dark:border-[1px] border-[#374151] w-[100%] rounded-[13px]">
          <section
            className="flex flex-row items-center justify-center px-[10px] py-[30px] h-[100px]
            w-[45%] sm:w-[25%] bg-[rgb(241,241,241)] dark:bg-transparent rounded-l-[13px] cursor-pointer"
            onClick={() => {
              setShowOptions(true);
              setIsBase(true);
            }}
          >
            <div className="flex flex-col items-end">
              <img src={coin.icon} className="w-[27px] rounded-[50%]" />
              <img
                src={coin.netIcon}
                className="w-[14px] rounded-[50%] relative mt-[-10px]"
              />
            </div>
            <h1 className="dark:text-white text-[1.125rem] px-[10px]">
              {coin.ticker}
            </h1>
            {location.pathname !== "/app/stakerez" && (
              <i class="bx bxs-chevron-down text-[20px] dark:text-[#374151]"></i>
            )}
          </section>

          <section
            className="px-[10px] rounded-r-[13px] bg-[#f1f1f1] dark:bg-transparent  h-[100px] w-[55%] sm:w-[80%] flex flex-col items-center
         justify-between border-solid border-l-[1px] border-[#dfe1e3] dark:border-[#374151]"
          >
            <div className="py-[15px] border-solid border-b-[1px] border-[#dfe1e3] dark:border-[#374151] w-[100%] flex flex-row items-center justify-between">
              <input
                placeholder="0.00"
                name="base"
                className="w-[70%] pr-[10px] sm:w-[90%] lg:w-[85%] bg-transparent text-[20px]   text-black dark:text-white"
                type="text"
                value={amount}
                onChange={handleAmountChange}
                onInput={(e) => onlyNumbers(e)}
              />
              <button
                className="bg-[#ace731] dark:bg-transparent dark:text-white uppercase border-solid border-[1px] dark:border-white px-[.875rem] h-[25px] 
                flex flex-row items-center justify-center rounded-[.5rem] text-[12px] font-[700]"
              >
                max
              </button>
            </div>
            <div className="pb-[20px] w-[100%]">
              <h1 className="text-[#9ca3af80] dark:text-white text-[12px]">
                ${usdEquivalentAmount}
              </h1>
            </div>
          </section>
        </div>

        {location.pathname !== "/app/stakerez" && (
          <div className="flex flex-row items-center justify-between w-[100%] py-[10px]">
            <h1 className="dark:text-white text-[16px]">Receive</h1>
            <h1 className="text-[#6b7280] text-[12px]">Balance: 0.00</h1>
          </div>
        )}
        {location.pathname !== "/app/stakerez" && (
          <div className="flex flex-row items-start justify-between border-solid dark:border-[1px] border-[#374151] w-[100%] rounded-[13px]">
            <section
              className="flex flex-row items-center justify-center px-[10px] py-[30px] h-[100px]
            w-[45%] sm:w-[25%] bg-[#f1f1f1] dark:bg-transparent rounded-l-[13px]"
              onClick={() => {
                setShowOptions(true);
                setIsBase(false);
              }}
            >
              {location.pathname === "/app" ? (
                <>
                  <div className="flex flex-col items-end pr-[10px]">
                    <img
                      src="./images/ezETH.webp"
                      className="w-[27px] rounded-[50%]"
                    />
                    <img
                      src={coin.netIcon}
                      className="w-[14px] rounded-[50%] relative mt-[-10px]"
                    />
                  </div>
                  <h1 className="dark:text-white text-[1.125rem]">ezETH</h1>
                </>
              ) : (
                <>
                  <div className="flex flex-col items-end pr-[10px]">
                    <img
                      src={targetCoin?.icon}
                      className="w-[27px] rounded-[50%]"
                    />
                    <img
                      src={targetCoin?.netIcon}
                      className="w-[14px] rounded-[50%] relative mt-[-10px]"
                    />
                  </div>
                  <h1 className="dark:text-white text-[1.125rem]">
                    {targetCoin?.ticker}
                  </h1>
                  <i class="bx bxs-chevron-down text-[20px] dark:text-[#374151]"></i>
                </>
              )}
            </section>

            <section
              className="px-[10px] rounded-r-[13px] bg-[#f1f1f1] dark:bg-transparent  h-[100px] w-[55%] sm:w-[80%] flex flex-col items-center
         justify-between border-solid border-l-[1px] border-[#dfe1e3] dark:border-[#374151]"
            >
              <div className="py-[15px] border-solid border-b-[1px] border-[#dfe1e3] dark:border-[#374151] w-[100%] flex flex-row items-center justify-between">
                <input
                  placeholder="0.00"
                  className="w-[100%] bg-transparent text-[20px]   text-black dark:text-white"
                  type="text"
                  name="target"
                  value={equivalentTokenBAmount}
                  onInput={(e) => onlyNumbers(e)}
                  onChange={handleAmountChange}
                  disabled={location.pathname === "/app" ? true : false}
                />
              </div>
              <div className="pb-[20px] w-[100%]">
                <h1 className="text-[#9ca3af80] dark:text-white text-[12px]">
                  ${usdEquivalentTokenB}
                </h1>
              </div>
            </section>
          </div>
        )}
        <button
          onClick={() => {
            !isConnected ? setShowConnect(true) : action();
          }}
          className="flex flex-row items-center mt-[50px] justify-center px-[1.5rem] h-[50px] rounded-[.75rem] bg-[#ace731] text-black w-[100%] "
          disabled={isLoading}
          style={{ backgroundColor: isLoading && "grey" }}
        >
          <h1 className="pr-[5px] font-[700] text-[1.125rem]">
            {isLoading
              ? "Loading..."
              : !isConnected
              ? "Connect wallet"
              : amount === 0
              ? "Enter Amount"
              : "Swap"}
          </h1>
        </button>

        {coin.ticker.toLowerCase() !== "eth" &&
          location.pathname !== "/app/stakerez" && (
            <section className="flex flex-col items-start w-[100%]">
              <section className="pt-[40px] pb-[15px] flex flex-row items-cente justify-between w-[100%]">
                <h1 className="text-black dark:text-white text-[16px]">
                  Restaking Limit
                </h1>

                <h1 className="text-[#6b7280] text-[16px]">
                  14.1K/50K {coin.ticker}
                </h1>
              </section>

              <section className="bg-[#f1f1f1] h-[10px] w-[100%] rounded-[9999px]">
                <div
                  className={`bg-[#afe838] w-[30%] h-[100%] rounded-[9999px]`}
                ></div>
              </section>
            </section>
          )}
      </section>

      {location.pathname !== "/app/stakerez" && (
        <Accordion allowToggle className="w-[100%] pt-[20px]">
          <AccordionItem
            className={`flex flex-col w-[100%] dark:bg-[#050712] bg-white
        rounded-[1rem] p-[20px] ${theme === "dark" && "bsh"} `}
          >
            <h2 className="dark:text-white text-black flex flex-row items-center justify-between w-[100%]">
              <AccordionButton className="flex flex-row items-center justify-between w-[100%]">
                <h1 className="text-[#6b7280]">
                  ezETH ={" "}
                  {`$${
                    tokenStatus
                      ? updatedCoinWithPrice?.price?.toLocaleString()
                      : "3,000"
                  }`}
                </h1>
                <AccordionIcon className="text-[20px] text-[#6b7280]  " />
              </AccordionButton>
            </h2>
            <AccordionPanel
              className="border-solid border-t-[1px] border-[#dfe1e3] dark:border-[#374151]
    mt-[20px] flex flex-col items-start w-[100%]"
            >
              <div className="flex flex-row items-center justify-between w-[100%] pt-[20px]">
                <h1 className="text-[15px] text-[#6b7280]  flex flex-row items-center">
                  APR <i class="bx bx-info-circle pl-[10px]"></i>
                </h1>
                <h1 className="text-[15px] text-[#6b7280]">1.28%</h1>
              </div>
              <div className="flex flex-row items-center justify-between w-[100%] pt-[10px]">
                <h1 className="text-[15px] text-[#6b7280]  flex flex-row items-center">
                  Exchange Rate <i class="bx bx-info-circle pl-[10px]"></i>
                </h1>
                <h1 className="text-[15px] text-[#6b7280]  ">
                  1 {coin.ticker} = 0.004424 ezETH
                </h1>
              </div>
              <div className="flex flex-row items-center justify-between w-[100%] pt-[10px]">
                <h1 className="text-[15px] text-[#6b7280]  flex flex-row items-center">
                  Transaction Cost <i class="bx bx-info-circle pl-[10px]"></i>
                </h1>
                <h1 className="text-[15px] text-[#6b7280]  ">~ $10.95</h1>
              </div>
              <div className="flex flex-row items-center justify-between w-[100%] pt-[10px]">
                <h1 className="text-[15px] text-[#6b7280]  flex flex-row items-center">
                  Reward Fee <i class="bx bx-info-circle pl-[10px]"></i>
                </h1>
                <h1 className="text-[15px] text-[#6b7280]  ">10%</h1>
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </section>
  );
};

export default Swap;
