import { Link } from "react-router-dom";

const c = [
    {
        id: 1,
        body: <div className="text-white rounded-t-[1.5rem] flex flex-col items-center max-w-[400px] h-[500px]">
        <img src="./images/c1.svg" />
        <div className="bg-[#121423] p-[20px] h-[200px] rounded-b-[1.5rem]">
            <h1 className="text-[20px]">Deposit ETH Anywhere</h1>
            <h1 className="text-[#a3a4bf] text-[16px] py-[20px]">ezETH holders receive 100% of EigenLayer points.</h1>
            <Link to="/app" className="text-white font-[700] border-solid border-[1px] border-white px-[.875rem] py-[.5rem] rounded-[.5rem]">
                Restake
            </Link>
        </div>
        </div>
    },
    {
        id: 2,
        body: <div className="text-white rounded-t-[1.5rem] flex flex-col items-center max-w-[400px] h-[500px]">
        <img src="./images/c2.svg" />
        <div className="bg-[#121423] p-[20px] h-[200px] rounded-b-[1.5rem]">
            <h1 className="text-[20px]">Deposit wBETH on Mainnet</h1>
            <h1 className="text-[#a3a4bf] text-[16px] py-[20px]">Depositors receive 1 ezETH per 1 wBETH deposit and 100% of EigenLayer points.</h1>
        </div>
        </div>
    },
    {
        id: 3,
        body: <div className="text-white rounded-t-[1.5rem] flex flex-col items-center max-w-[400px] h-[500px]">
        <img src="./images/c3.svg" />
        <div className="bg-[#121423] p-[20px] h-[200px] rounded-b-[1.5rem]">
            <h1 className="text-[20px]">Deposit stETH on Mainnet</h1>
            <h1 className="text-[#a3a4bf] text-[16px] py-[20px]">Depositors receive 1 ezETH per 1 stETH deposit and 100% of EigenLayer points.</h1>
        </div>
        </div>
    },
];

export default c