import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { truncateAddressFx } from "../../helper/web3Modalutils";
import { formatNumber } from "../../helper/displayData";
import { useEffect, useState } from "react";

const Top = ({ theme, setTheme, setShowConnect }) => {
  const { address, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const handleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      document.documentElement.classList.remove("dark");
    } else {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    }
  };

  const de = [
    {
      id: 1,
      title: "Total Value Staked",
      body: 3181592222.412581,
    },
    {
      id: 2,
      title: "APR",
      body: "3.25%",
    },
    {
      id: 3,
      title: "EigenLayer Points",
      body: 1218954242,
    },
    {
      id: 4,
      title: "Renzo ezPoints",
      body: 2531626804.8660135,
    },
  ];

  return (
    <section
      className="hidden lg:flex flex-row items-center p-[50px] bg-white dark:bg-[#090b19] justify-between"
      style={{
        borderRadius: "0px 1.25rem 0px 0px",
      }}
    >
      <section className="flex flex-row items-center justify-between w-[70%]">
        {de.map((us, id) => {
          const numberformat = formatNumber(us.body);

          const tvlLabel = `$${numberformat.formattedNumber}${numberformat.suffix}`;
          return (
            <div
              key={id}
              className={`${
                us.id !== 4 && "border-solid border-r-[1px] border-[#111322]"
              } flex flex-col 
                    items-start justify-center w-[25%] mr-[20px]`}
            >
              <h1 className="text-[#565873] dark:text-[#ffffff80] text-[12px] flex flex-row items-center">
                {us.title}
                <i class="bx bx-info-circle pl-[5px]"></i>
              </h1>

              <h1 className="text-black dark:text-white text-[1.25rem] font-[500] flex flex-row items-center">
                {us.title === "EigenLayer Points" && (
                  <h1 className="flex flex-row items-center">
                    <img src="./images/e1.svg" className="w-[20px]" />
                    {tvlLabel}
                  </h1>
                )}
                {us.title !== ("EigenLayer Points" || "APR") && tvlLabel}
              </h1>
            </div>
          );
        })}
      </section>

      <section className="flex flex-row items-center justify-end w-[20%]">
        <button
          className="flex text-[#090b19] font-[600] bg-[#cfd0e5] px-[1.5rem] h-[48px] rounded-[.75rem] mr-[15px] cursor-pointer items-center justify-center"
          onClick={() => {
            isConnected ? open() : setShowConnect(true);
          }}
        >
          {isConnected ? truncateAddressFx(address) : "Connect"}

          {isConnected && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={14}
              height={8}
              viewBox="0 0 14 8"
              fill="none"
              className="size-3 shrink-0 transition-transform duration-300 data-[state=open]:rotate-180"
            >
              <path
                d="M12.9258 1.16797L6.92578 7.16797L0.925781 1.16797"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>

        <div
          onClick={() => {
            handleTheme();
          }}
          className="border-solid dark:border-[1px] border-[#a3a4bf] p-[10px] flex flex-col items-center
         justify-center rounded-[.75rem] cursor-pointer bg-[#cfd0e5] dark:bg-transparent h-[48px] w-[48px]"
        >
          {theme === "dark" ? (
            <i class="bx bxs-moon text-[#a3a4bf] text-[20px]"></i>
          ) : (
            <i class="bx bxs-sun text-[20px]"></i>
          )}
        </div>
      </section>
    </section>
  );
};

export default Top;
