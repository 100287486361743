

const r = [
    {
        id: 1,
        title: "Luganodes",
        image: "./images/r1.webp",
        addy: "0x5cd6...501a",
        value: "269.24K",
        link: "https://www.luganodes.com/"
    },
    {
        id: 2,
        title: "P2P.org",
        image: "./images/r2.webp",
        addy: "0x865c...8f16",
        value: "269.33K",
        link: "https://p2p.org/"
    },
    {
        id: 3,
        title: "HashKey Cloud",
        image: "./images/r3.webp",
        addy: "",
        value: "",
        link: "https://www.hashkey.cloud/"
    },
    {
        id: 4,
        title: "Pier Two",
        image: "./images/r4.webp",
        addy: "",
        value: "",
        link: "https://piertwo.com/"
    },
    {
        id: 5,
        title: "Figment",
        image: "./images/r5.webp",
        addy: "",
        value: "",
        link: "https://figment.io/"
    },
]

export default r