

const what = [
    {
        id: 1,
        title: "What is EigenLayer",
        body: <div className="flex flex-col items-start w-[100%]">
            <h1>A new DeFi primitive, restaking, that allows Ethereum users to stake their ETH and secure Actively Validated Services (AVSs) that generate rewards in addition to staking rewards.</h1>
            <a className="underline" href="https://docs.eigenlayer.xyz/overview/readme">EigenLayer Doc</a>
        </div>,
        image: "./images/w1.webp",
        set: "w1"
    },
    {
        id: 2,
        title: "What is Renzo",
        body: <div className="flex flex-col items-start w-[100%]">
            <h1>Renzo is a Liquid Restaking Token (LRT) and Strategy Manager for EigenLayer. It is the interface to the EigenLayer ecosystem securing Actively Validated Services (AVSs) and offering a higher yield than ETH staking. </h1>
            {/* <h1>The protocol abstracts all complexity from the end-user and enables easy collaboration between users and EigenLayer node operators.</h1> */}
            <a className="underline">Renzo Docs</a>
        </div>,
        image: "./images/w2.webp",
        set: "w2"
    },
]

export default what