import { Outlet } from "react-router-dom";
import Side from "./Side";
import Top from "./Top";
import Footer from "./Footer";
import AppHeader from "./AppHeader";
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import BottomConnect from "./BottomConnect";
import MobileSide from "./MobileSide";
import { supportedWallets } from "../../helper/web3Modalutils";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";

const Applayout = ({
  theme,
  setTheme,
  showSide,
  setShowSide,
  showConnect,
  setShowConnect,
  showOptions,
}) => {
  const { onClose } = useDisclosure();
  const { open } = useWeb3Modal();
  const { isConnected } = useWeb3ModalAccount();
  const [check, setCheck] = useState(false);
  useEffect(() => {}, [check]);

  return (
    <section
      className="bg-[#f1f1f1] dark:bg-[#111322] lg:p-[15px] flex flex-col lg:flex-row items-start justify-between
            lg:overflow-x-hidden"
    >
      <section
        className={`lg:hidden fixed top-0 w-[100%] ${
          showSide ? "z-0" : "z-20"
        }`}
      >
        <AppHeader
          theme={theme}
          setTheme={setTheme}
          showConnect={showConnect}
          setShowConnect={setShowConnect}
          showSide={showSide}
          setShowSide={setShowSide}
          showOptions={showOptions}
        />
      </section>

      <section className="w-[18%] hidden lg:flex">
        <Side theme={theme} />
      </section>
      <section className="w-[100%] lg:pl-[2px]">
        <Top
          theme={theme}
          setTheme={setTheme}
          showConnect={showConnect}
          setShowConnect={setShowConnect}
        />
        <section className="lg:pt-[2px] min-h-[90vh]">
          <Outlet />
        </section>
      </section>

      <Modal
        isOpen={showConnect && !isConnected}
        onClose={onClose}
        className="hidden lg:flex flex-col items-center justify-center px-[20px]"
      >
        <ModalContent className="hidden lg:flex flex-col items-center justify-center w-[100%] min-h-[100vh] px-[20px]">
          <div
            className="backdrop-brightness-50 bg-black/30 w-[100%] min-h-[100vh] bg-black absolute"
            onClick={() => {
              setShowConnect(false);
            }}
          />

          <section
            className="hidden lg:flex text-white bg-white dark:bg-[#05081a] border-solid dark:border-[1px] dark:border-[#232439] lg:w-[500px] w-[100%] p-[10px] 
          rounded-[.5rem] flex flex-col items-center  pb-[20px] z-50"
          >
            <div className="pb-[60px] dark:pb-[30px] w-[100%] flex flex-row items-center justify-end">
              <ModalCloseButton
                className="text-[10px] text-black dark:text-white"
                onClick={() => {
                  setShowConnect(false);
                }}
              />
            </div>
            <img
              src={theme === "dark" ? "./images/con2.svg" : "./images/con1.svg"}
              className="w-[48px]"
            />
            <h1 className="text-[16px] font-[400] pt-[15px] pb-[0px] text-black dark:text-white capitalize">
              Connect your wallet
            </h1>

            <div className="flex flex-row items-center">
              <div>
                <button
                  onClick={() => {
                    setCheck(!check);
                  }}
                  className={`w-[16px] h-[16px]
                ${
                  check
                    ? "dark:text-[#05081a] dark:bg-white bg-black"
                    : "bg-tranparent"
                } border-solid border-[1px] dark:border-white 
                border-black
                flex flex-col items-center justify-center text-[10px] rounded-[.125rem]`}
                >
                  {check && <i class="bx bx-check"></i>}
                </button>
              </div>

              <h1 className="text-[12px] font-[700] dark:text-white text-black pl-[10px]">
                Accept Renzo's{" "}
                <a className="text-[#ace731] font-[700] hover:underline">
                  Terms of Service
                </a>
                ,{" "}
                <a className="text-[#ace731] font-[700] hover:underline">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a className="text-[#ace731] font-[700] hover:underline">
                  Disclaimer
                </a>
              </h1>
            </div>

            <section className="flex flex-col items-start w-[100%] pt-[40px] px-[20px]">
              {supportedWallets.map((us, id) => (
                <button
                  disabled={check ? false : true}
                  key={id}
                  className={`flex flex-row items-center justify-between w-[100%] rounded-[.75rem] text-black dark:text-white
                        border-solid border-[1px] hover:border-[#6b728080] dark:border-[#1f2937] bg-[#f1f1f1] dark:bg-[#1f2937] mb-[10px] py-[.75rem]
                        px-[2.5rem] smooth ${
                          check
                            ? "cursor-pointer"
                            : "cursor-not-allowed opacity-50"
                        } `}
                  onClick={() => open()}
                >
                  <h1>{us.name}</h1>
                  <img src={us.image} className="w-[28px]" />
                </button>
              ))}
            </section>
          </section>
        </ModalContent>
      </Modal>

      <BottomConnect
        theme={theme}
        setTheme={setTheme}
        showConnect={showConnect}
        setShowConnect={setShowConnect}
      />

      {!showConnect && <Footer theme={theme} />}

      <section className="fixed z-50 left-0">
        <MobileSide
          theme={theme}
          showSide={showSide}
          setShowSide={setShowSide}
        />
      </section>
    </section>
  );
};

export default Applayout;
