import { displayChains } from "./web3Modalutils";

export const gatePoolMetrics = [
  {
    poolId: 1,
    chainId: 1,
    "1d": {
      swapCount: "106",
      swapVolume: "301603.541241",
    },
    "7d": {
      swapCount: "807",
      swapVolume: "5782853.057728",
    },
  },
  {
    poolId: 2,
    chainId: 1,
    "1d": {
      swapCount: "166",
      swapVolume: "484239.831682",
    },
    "7d": {
      swapCount: "901",
      swapVolume: "15667363.482362",
    },
  },
  {
    poolId: 13,
    chainId: 1,
    "1d": {
      swapCount: "755",
      swapVolume: "589.9815963250414",
    },
    "7d": {
      swapCount: "4122",
      swapVolume: "3496.127014943958",
    },
  },
  {
    poolId: 3,
    chainId: 1,
    "1d": {
      swapCount: "2",
      swapVolume: "10878.994899",
    },
    "7d": {
      swapCount: "17",
      swapVolume: "86241.278173",
    },
  },
  {
    poolId: 7,
    chainId: 1,
    "1d": {
      swapCount: "1",
      swapVolume: "1003.332554",
    },
    "7d": {
      swapCount: "5",
      swapVolume: "40545.347904",
    },
  },
  {
    poolId: 17,
    chainId: 1,
    "1d": {
      swapCount: "10",
      swapVolume: "550.747800382418",
    },
    "7d": {
      swapCount: "30",
      swapVolume: "1566.9369778174587",
    },
  },
  {
    poolId: 20,
    chainId: 1,
    "1d": {
      swapCount: "3",
      swapVolume: "162.67717120320415",
    },
    "7d": {
      swapCount: "11",
      swapVolume: "3647.4056160950117",
    },
  },
  {
    poolId: 2,
    chainId: 56,
    "1d": {
      swapCount: "3460",
      swapVolume: "2041539.566087",
    },
    "7d": {
      swapCount: "25127",
      swapVolume: "16469973.345698997",
    },
  },
  {
    poolId: 5,
    chainId: 56,
    "1d": {
      swapCount: "240",
      swapVolume: "61289.896751",
    },
    "7d": {
      swapCount: "1757",
      swapVolume: "428699.50628999993",
    },
  },

  {
    poolId: 19,
    chainId: 56,
    "1d": {
      swapCount: "86",
      swapVolume: "23540.293686",
    },
    "7d": {
      swapCount: "633",
      swapVolume: "659737.046723",
    },
  },
  {
    poolId: 20,
    chainId: 56,
    "1d": {
      swapCount: "23",
      swapVolume: "1730.2790403241058",
    },
    "7d": {
      swapCount: "195",
      swapVolume: "18588.234709692373",
    },
  },
  {
    poolId: 1,
    chainId: 43114,
    "1d": {
      swapCount: "2128",
      swapVolume: "849429.90589",
    },
    "7d": {
      swapCount: "14650",
      swapVolume: "7148183.231789",
    },
  },
  {
    poolId: 2,
    chainId: 43114,
    "1d": {
      swapCount: "986",
      swapVolume: "689281.555842",
    },
    "7d": {
      swapCount: "6669",
      swapVolume: "5254636.358524",
    },
  },

  {
    poolId: 19,
    chainId: 43114,
    "1d": {
      swapCount: "65",
      swapVolume: "77565.739678",
    },
    "7d": {
      swapCount: "361",
      swapVolume: "461937.625535",
    },
  },
  {
    poolId: 20,
    chainId: 43114,
    "1d": {
      swapCount: "7",
      swapVolume: "352.83825278250123",
    },
    "7d": {
      swapCount: "41",
      swapVolume: "4915.783229966319",
    },
  },
  {
    poolId: 1,
    chainId: 137,
    "1d": {
      swapCount: "2571",
      swapVolume: "968397.613297",
    },
    "7d": {
      swapCount: "21037",
      swapVolume: "6446581.667672",
    },
  },
  {
    poolId: 2,
    chainId: 137,
    "1d": {
      swapCount: "1651",
      swapVolume: "963548.757907",
    },
    "7d": {
      swapCount: "12191",
      swapVolume: "7756392.713251",
    },
  },
  {
    poolId: 3,
    chainId: 137,
    "1d": {
      swapCount: "42",
      swapVolume: "7202.119019",
    },
    "7d": {
      swapCount: "234",
      swapVolume: "153057.747912",
    },
  },
  {
    poolId: 20,
    chainId: 137,
    "1d": {
      swapCount: "9",
      swapVolume: "521.4422960136974",
    },
    "7d": {
      swapCount: "55",
      swapVolume: "10242.32808265565",
    },
  },
  {
    poolId: 1,
    chainId: 42161,
    "1d": {
      swapCount: "3648",
      swapVolume: "2233456.728168",
    },
    "7d": {
      swapCount: "20947",
      swapVolume: "13331299.933726",
    },
  },
  {
    poolId: 2,
    chainId: 42161,
    "1d": {
      swapCount: "1225",
      swapVolume: "761095.522507",
    },
    "7d": {
      swapCount: "7614",
      swapVolume: "7826713.930685",
    },
  },
  {
    poolId: 13,
    chainId: 42161,
    "1d": {
      swapCount: "5108",
      swapVolume: "1782.8159051751697",
    },
    "7d": {
      swapCount: "28758",
      swapVolume: "11269.239755627692",
    },
  },
  {
    poolId: 7,
    chainId: 42161,
    "1d": {
      swapCount: "4",
      swapVolume: "202.105838",
    },
    "7d": {
      swapCount: "72",
      swapVolume: "41935.038998",
    },
  },
  {
    poolId: 15,
    chainId: 42161,
    "1d": {
      swapCount: "3",
      swapVolume: "113.042992",
    },
    "7d": {
      swapCount: "10",
      swapVolume: "1219.45474",
    },
  },
  {
    poolId: 20,
    chainId: 42161,
    "1d": {
      swapCount: "44",
      swapVolume: "6020.220065722548",
    },
    "7d": {
      swapCount: "297",
      swapVolume: "120538.72438987636",
    },
  },
  {
    poolId: 1,
    chainId: 10,
    "1d": {
      swapCount: "2585",
      swapVolume: "3098263.184197",
    },
    "7d": {
      swapCount: "14955",
      swapVolume: "14057156.975957",
    },
  },
  {
    poolId: 13,
    chainId: 10,
    "1d": {
      swapCount: "4048",
      swapVolume: "1267.8292727647886",
    },
    "7d": {
      swapCount: "25808",
      swapVolume: "8516.223822218188",
    },
  },
  {
    poolId: 3,
    chainId: 10,
    "1d": {
      swapCount: "19",
      swapVolume: "5749.753252",
    },
    "7d": {
      swapCount: "201",
      swapVolume: "155848.834823",
    },
  },
  {
    poolId: 7,
    chainId: 10,
    "1d": {
      swapCount: "3",
      swapVolume: "200.75741",
    },
    "7d": {
      swapCount: "46",
      swapVolume: "54208.477518",
    },
  },
  {
    poolId: 14,
    chainId: 10,
    "1d": {
      swapCount: "1",
      swapVolume: "597.202341",
    },
    "7d": {
      swapCount: "1",
      swapVolume: "597.202341",
    },
  },
  {
    poolId: 15,
    chainId: 10,
    "1d": {
      swapCount: "3",
      swapVolume: "17.174725",
    },
    "7d": {
      swapCount: "16",
      swapVolume: "1060.559431",
    },
  },
  {
    poolId: 20,
    chainId: 10,
    "1d": {
      swapCount: "7",
      swapVolume: "2622.1053582331956",
    },
    "7d": {
      swapCount: "89",
      swapVolume: "32857.87064192006",
    },
  },
  {
    poolId: 20,
    chainId: 250,
    "1d": {
      swapCount: "1",
      swapVolume: "1.3423104153587622",
    },
    "7d": {
      swapCount: "1",
      swapVolume: "1.3423104153587622",
    },
  },
  {
    poolId: 21,
    chainId: 250,
    "1d": {
      swapCount: "779",
      swapVolume: "406332.553163",
    },
    "7d": {
      swapCount: "4064",
      swapVolume: "2199024.400809",
    },
  },
  {
    poolId: 17,
    chainId: 1088,
    "1d": {
      swapCount: "1",
      swapVolume: "0.1715818333379566",
    },
    "7d": {
      swapCount: "29",
      swapVolume: "2886.351143785727",
    },
  },
  {
    poolId: 19,
    chainId: 1088,
    "1d": {
      swapCount: "294",
      swapVolume: "41122.211185",
    },
    "7d": {
      swapCount: "1535",
      swapVolume: "938075.9188699999",
    },
  },
  {
    poolId: 13,
    chainId: 8453,
    "1d": {
      swapCount: "6255",
      swapVolume: "1615.3965638907837",
    },
    "7d": {
      swapCount: "32233",
      swapVolume: "8612.721424036035",
    },
  },
  {
    poolId: 1,
    chainId: 8453,
    "1d": {
      swapCount: "2480",
      swapVolume: "1934909.084497",
    },
    "7d": {
      swapCount: "20085",
      swapVolume: "12150672.253049001",
    },
  },
  {
    poolId: 13,
    chainId: 183,
    "1d": {
      swapCount: "2214",
      swapVolume: "648.7405321511886",
    },
    "7d": {
      swapCount: "13742",
      swapVolume: "3722.290997162434",
    },
  },
  {
    poolId: 2,
    chainId: 2222,
    "1d": {
      swapCount: "734",
      swapVolume: "171881.329692",
    },
    "7d": {
      swapCount: "3865",
      swapVolume: "2428050.577191",
    },
  },
];

export const gatePoolData = [
  {
    pid: 0,
    chainId: 1,
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    token: "USDC",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    apr: 0.08202904537799167,
    aprData: null,
    "1d": {
      swapCount: "106",
      swapVolume: "301603.541241",
    },
    "7d": {
      swapCount: "807",
      swapVolume: "5782853.057728",
    },
    liquidity: "34188214.17",
  },
  {
    pid: 1,
    chainId: 1,
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.056583393804431746,
    aprData: null,
    "1d": {
      swapCount: "166",
      swapVolume: "484239.831682",
    },
    "7d": {
      swapCount: "901",
      swapVolume: "15667363.482362",
    },
    liquidity: "29114844.72",
  },
  {
    pid: 2,
    chainId: 1,
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/eth.svg",
    token: "ETH",
    apr: 0.04502392834680035,
    aprData: null,
    "1d": {
      swapCount: "19",
      swapVolume: "5749.753252",
    },
    "7d": {
      swapCount: "201",
      swapVolume: "155848.834823",
    },
    liquidity: "26483521",
  },
  {
    pid: 3,
    chainId: 1,
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/dai.svg",
    token: "DAI",
    apr: 0.06392750061533425,
    aprData: null,
    "1d": {
      swapCount: "755",
      swapVolume: "589.9815963250414",
    },
    "7d": {
      swapCount: "4122",
      swapVolume: "3496.127014943958",
    },
    liquidity: "324848.37",
  },
  {
    pid: 4,
    chainId: 1,
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/frax.svg",
    token: "FRAX",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "2",
      swapVolume: "10878.994899",
    },
    "7d": {
      swapCount: "17",
      swapVolume: "86241.278173",
    },
    liquidity: "324800.37",
    supported: "By Metis",
  },
  {
    pid: 5,
    chainId: 1,
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdd.svg",
    token: "sUSD",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "1",
      swapVolume: "1003.332554",
    },
    "7d": {
      swapCount: "5",
      swapVolume: "40545.347904",
    },
    liquidity: "100224.92",
  },
  {
    pid: 6,
    chainId: 1,
    address: "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/lusd.svg",
    token: "LUSD",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "3",
      swapVolume: "162.67717120320415",
    },
    "7d": {
      swapCount: "11",
      swapVolume: "3647.4056160950117",
    },
    liquidity: "102875.92",
  },
  {
    pid: 0,
    chainId: 56,
    address: "0x3052A0F6ab15b4AE1df39962d5DdEFacA86DaB47",
    crypto: "BSC",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.06942988627312911,
    aprData: null,
    "1d": {
      swapCount: "3460",
      swapVolume: "2041539.566087",
    },
    "7d": {
      swapCount: "25127",
      swapVolume: "16469973.345698997",
    },
    liquidity: "21162661.33",
  },
  {
    pid: 1,
    chainId: 56,
    address: "0x3052A0F6ab15b4AE1df39962d5DdEFacA86DaB47",
    crypto: "BSC",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/busd.svg",
    token: "BUSD",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "240",
      swapVolume: "61289.896751",
    },
    "7d": {
      swapCount: "1757",
      swapVolume: "428699.50628999993",
    },
    liquidity: "586207.78",
  },
  {
    pid: 0,
    chainId: 43114,
    crypto: "Avalanche",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/avalanche.svg",
    address: "0x8731d54E9D02c286767d56ac03e8037C07e01e98",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    token: "USDC",
    apr: 0.0697202144705779,
    aprData: null,
    "1d": {
      swapCount: "2128",
      swapVolume: "849429.90589",
    },
    "7d": {
      swapCount: "14650",
      swapVolume: "7148183.231789",
    },
    liquidity: "12096398.11",
  },
  {
    pid: 1,
    chainId: 43114,
    crypto: "Avalanche",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/avalanche.svg",
    address: "0x8731d54E9D02c286767d56ac03e8037C07e01e98",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.07004363270859437,
    aprData: null,
    "1d": {
      swapCount: "986",
      swapVolume: "689281.555842",
    },
    "7d": {
      swapCount: "6669",
      swapVolume: "5254636.358524",
    },
    liquidity: "9745035.14",
    supported: "By Metis",
  },
  {
    pid: 2,
    chainId: 43114,
    crypto: "Avalanche",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/avalanche.svg",
    address: "0x8731d54E9D02c286767d56ac03e8037C07e01e98",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/frax.svg",
    token: "FRAX",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "65",
      swapVolume: "77565.739678",
    },
    "7d": {
      swapCount: "361",
      swapVolume: "461937.625535",
    },
    liquidity: "41513.23",
  },
  {
    pid: 0,
    chainId: 137,
    crypto: "Polygon",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/polygon.svg",
    address: "0x8731d54E9D02c286767d56ac03e8037C07e01e98",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    token: "USDC",
    apr: 0.08304060607198016,
    aprData: null,
    "1d": {
      swapCount: "2571",
      swapVolume: "968397.613297",
    },
    "7d": {
      swapCount: "21037",
      swapVolume: "6446581.667672",
    },
    liquidity: "11967624.20",
  },
  {
    pid: 1,
    chainId: 137,
    crypto: "Polygon",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/polygon.svg",
    address: "0x8731d54E9D02c286767d56ac03e8037C07e01e98",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",

    token: "USDT",
    apr: 0.07442423343215096,
    aprData: null,
    "1d": {
      swapCount: "1651",
      swapVolume: "963548.757907",
    },
    "7d": {
      swapCount: "12191",
      swapVolume: "7756392.713251",
    },
    liquidity: "12062156.91",
  },
  {
    pid: 2,
    chainId: 137,
    crypto: "Polygon",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/polygon.svg",
    address: "0x8731d54E9D02c286767d56ac03e8037C07e01e98",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/dai.svg",

    token: "DAI",
    apr: 0.046033661418359025,
    aprData: null,
    "1d": {
      swapCount: "42",
      swapVolume: "7202.119019",
    },
    "7d": {
      swapCount: "234",
      swapVolume: "153057.747912",
    },
    liquidity: "274869.05",
  },
  {
    pid: 0,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",

    token: "USDC",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "3648",
      swapVolume: "2233456.728168",
    },
    "7d": {
      swapCount: "20947",
      swapVolume: "13331299.933726",
    },
    liquidity: "21922489.53",
  },
  {
    pid: 1,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "1225",
      swapVolume: "761095.522507",
    },
    "7d": {
      swapCount: "7614",
      swapVolume: "7826713.930685",
    },
    liquidity: "16373800.21",
  },
  {
    pid: 2,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/eth.svg",
    token: "ETH",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "5108",
      swapVolume: "1782.8159051751697",
    },
    "7d": {
      swapCount: "28758",
      swapVolume: "11269.239755627692",
    },
    liquidity: "17561367.96",
  },
  {
    pid: 3,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/frax.svg",
    token: "FRAX",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "4",
      swapVolume: "202.105838",
    },
    "7d": {
      swapCount: "72",
      swapVolume: "41935.038998",
    },
    liquidity: "32237.84",
  },
  {
    pid: 0,
    chainId: 10,
    crypto: "Optimism",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    address: "0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    token: "USDC",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "2585",
      swapVolume: "3098263.184197",
    },
    "7d": {
      swapCount: "14955",
      swapVolume: "14057156.975957",
    },
    liquidity: "11502030.71",
    supported: "By Optimism",
  },
  {
    pid: 1,
    chainId: 10,
    crypto: "Optimism",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    address: "0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/eth.svg",
    token: "ETH",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "4048",
      swapVolume: "1267.8292727647886",
    },
    "7d": {
      swapCount: "25808",
      swapVolume: "8516.223822218188",
    },
    liquidity: "11307241.21",
    supported: "By Optimism",
  },
  {
    pid: 0,
    chainId: 250,
    crypto: "Fantom",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/fantom.svg",
    address: "0x224D8Fd7aB6AD4c6eb4611Ce56EF35Dec2277F03",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",

    token: "USDC",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "294",
      swapVolume: "41122.211185",
    },
    "7d": {
      swapCount: "1535",
      swapVolume: "938075.9188699999",
    },
    liquidity: "11607241.21",
  },
  {
    pid: 1,
    chainId: 250,
    crypto: "Fantom",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/fantom.svg",
    address: "0x224D8Fd7aB6AD4c6eb4611Ce56EF35Dec2277F03",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    token: "USDC",
    apr: 0.06300992104816248,
    aprData: null,
    "1d": {
      swapCount: "2480",
      swapVolume: "1934909.084497",
    },
    "7d": {
      swapCount: "20085",
      swapVolume: "12150672.253049001",
    },
    liquidity: "3806985.89",
  },
  {
    pid: 0,
    chainId: 1,
    address: "0x1c3000b8f475A958b87c73a5cc5780Ab763122FC",
    crypto: "Ethereum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",

    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.0671465382595381,
    aprData: null,
    "1d": {
      swapCount: "734",
      swapVolume: "171881.329692",
    },
    "7d": {
      swapCount: "3865",
      swapVolume: "2428050.577191",
    },
    liquidity: "1467947.11",
    supported: "By Metis",
  },
  {
    pid: 0,
    chainId: 56,
    crypto: "BSC",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg",
    address: "0x18E08773daFfF53e84dDF4CEfC10c094f33671F4",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "10",
      swapVolume: "550.747800382418",
    },
    "7d": {
      swapCount: "30",
      swapVolume: "1566.9369778174587",
    },
    liquidity: "1355790.65",
  },
  {
    pid: 0,
    chainId: 56,
    crypto: "BSC",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg",
    address: "0x447f2078a1b6b2C1190B7b7aF98ef4B139d41F70",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.06811507294547212,
    aprData: null,
    "1d": {
      swapCount: "3460",
      swapVolume: "2041539.566087",
    },
    "7d": {
      swapCount: "25127",
      swapVolume: "16469973.345698997",
    },
    supported: "By Metis",
    liquidity: "1355842.33",
  },
  {
    pid: 0,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0x9774558534036Ff2E236331546691b4eB70594b1",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    token: "USDC",
    apr: 0.07578682972263738,
    aprData: null,
    "1d": {
      swapCount: "779",
      swapVolume: "406332.553163",
    },
    "7d": {
      swapCount: "4064",
      swapVolume: "2199024.400809",
    },
    liquidity: "21913960.78",
  },
  {
    pid: 1,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0x9774558534036Ff2E236331546691b4eB70594b1",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.07330625031038344,
    aprData: null,
    "1d": {
      swapCount: "5108",
      swapVolume: "1782.8159051751697",
    },
    "7d": {
      swapCount: "28758",
      swapVolume: "11269.239755627692",
    },
    liquidity: "16373700.00",
  },
  {
    pid: 2,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0x9774558534036Ff2E236331546691b4eB70594b1",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/eth.svg",
    token: "ETH",
    apr: 0.04388811217487902,
    aprData: null,
    "1d": {
      swapCount: "1225",
      swapVolume: "761095.522507",
    },
    "7d": {
      swapCount: "7614",
      swapVolume: "7826713.930685",
    },
    liquidity: "17555984.09",
  },
  {
    pid: 3,
    chainId: 42161,
    crypto: "Arbitrum",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    address: "0x9774558534036Ff2E236331546691b4eB70594b1",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/frax.svg",

    token: "FRAX",
    apr: 0.018298117080873814,
    aprData: null,
    "1d": {
      swapCount: "3648",
      swapVolume: "2233456.728168",
    },
    "7d": {
      swapCount: "20947",
      swapVolume: "13331299.933726",
    },
    liquidity: "32237.84",
  },
  {
    pid: 0,
    chainId: 10,
    crypto: "Optimism",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    address: "0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    token: "USDT",
    apr: 0.06122958189478019,
    aprData: null,
    "1d": {
      swapCount: "3648",
      swapVolume: "2233456.728168",
    },
    "7d": {
      swapCount: "20947",
      swapVolume: "13331299.933726",
    },
  },

  {
    pid: 2,
    chainId: 10,
    crypto: "Optimism",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    address: "0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/dai.svg",
    token: "DAI",
    apr: 0.047935161822309724,
    aprData: null,
    "1d": {
      swapCount: "1651",
      swapVolume: "963548.757907",
    },
    "7d": {
      swapCount: "12191",
      swapVolume: "7756392.713251",
    },
  },
  {
    pid: 3,
    chainId: 10,
    address: "0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/frax.svg",
    crypto: "Optimism",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    token: "FRAX",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "1651",
      swapVolume: "963548.757907",
    },
    "7d": {
      swapCount: "12191",
      swapVolume: "7765692.713251",
    },
    supported: "By Optimism",
  },
  {
    pid: 4,
    chainId: 10,
    crypto: "Optimism",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    address: "0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/susd.svg",
    token: "sUSD",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "240",
      swapVolume: "61289.896751",
    },
    "7d": {
      swapCount: "1757",
      swapVolume: "428699.50628999993",
    },
  },
  {
    pid: 5,
    chainId: 10,
    crypto: "Optimism",
    chainLogo:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    address: "0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/lusd.svg",
    token: "LUSD",
    apr: 0,
    aprData: null,
    "1d": {
      swapCount: "2571",
      swapVolume: "968397.613297",
    },
    "7d": {
      swapCount: "21037",
      swapVolume: "6446581.667672",
    },
  },
  {
    pid: 0,
    chainId: 1088,
    crypto: "Metis",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/metis.svg",
    address: "0x45A01E4e04F14f7A4a6702c74187c5F6222033cd",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.06779936984733742,
    aprData: null,
    "1d": {
      swapCount: "23",
      swapVolume: "1730.2790403241058",
    },
    "7d": {
      swapCount: "195",
      swapVolume: "18588.234709692373",
    },
    supported: "By Metis",
  },
  {
    pid: 0,
    chainId: 8453,
    crypto: "Base",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/base.svg",
    address: "0x06Eb48763f117c7Be887296CDcdfad2E4092739C",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/eth.svg",
    token: "ETH",
    apr: 0.05302802385444618,
    aprData: null,
    "1d": {
      swapCount: "986",
      swapVolume: "689281.555842",
    },
    "7d": {
      swapCount: "6669",
      swapVolume: "5254636.358524",
    },
  },
  {
    pid: 1,
    chainId: 8453,
    crypto: "Base",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/base.svg",
    address: "0x06Eb48763f117c7Be887296CDcdfad2E4092739C",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdc.svg",
    token: "USDC",
    apr: 0.08131383733190536,
    aprData: null,
    "1d": {
      swapCount: "65",
      swapVolume: "77565.739678",
    },
    "7d": {
      swapCount: "361",
      swapVolume: "461937.625535",
    },
  },
  {
    pid: 0,
    chainId: 2222,
    crypto: "Kava",
    chainLogo: "https://icons-ckg.pages.dev/stargate-light/networks/kava.svg",

    address: "0x35F78Adf283Fe87732AbC9747d9f6630dF33276C",
    logo: "https://icons-ckg.pages.dev/stargate-light/tokens/usdt.svg",
    token: "USDT",
    apr: 0.08213442333025789,
    aprData: null,
    "1d": {
      swapCount: "7",
      swapVolume: "352.83825278250123",
    },
    "7d": {
      swapCount: "41",
      swapVolume: "4915.783229966319",
    },
  },
];

export const combineGateData = (poolData) => {
  const combinedData = {};

  poolData.forEach((data) => {
    console.log(poolData, "pddddd");
    combinedData = {
      ...data,
      chainName: displayChain[data.chainId] || "Unknown",
      icon: displayChains[data.chainId]?.logo || "default-icon.png",
    };
  });

  const combinedArray = Object.values(combinedData);

  return combinedArray;
};

export const extractUniqueTokensAndLogos = (data) => {
  const uniqueTokens = data.reduce((acc, item) => {
    const key = `${item.token}-${item.logo}`;
    if (!acc[key]) {
      acc[key] = { token: item.token, logo: item.logo };
    }
    return acc;
  }, {});

  return Object.values(uniqueTokens);
};

export const extractUniqueChainsAndLogos = (d) => {
  const uniqueChains = d.reduce((acc, item) => {
    const key = `${item.chainName}-${item.icon}`;
    if (!acc[key]) {
      acc[key] = { name: item.chainName, icon: item.chainLogo };
    }
    return acc;
  }, {});

  return Object.values(uniqueChains);
};

export const displayChain = {
  1: "Ethereum",
  10: "Optimism",
  56: "BSC",
  137: "Polygon",
  1101: "PolygonZkEVM",
  43114: "Avalanche",
  42161: "Arbitrum",
  8453: "Base",
  324: "Zksync_era",
  250: "Fantom",
  1088: "Metis",
  2222: "Kava",
  5000: "Mantle",
};

export const gateTokens = [
  "ETH",
  "BNB",
  "AVAX",
  "MATIC",
  "FTM",
  "METIS",
  "KAVA",
  "MNT",
  "USDC",
  "USDT",
  "BUSD",
  "STG",
  "USDD",
  "FRAX",
  "LUSD",
  "SUSD",
  "DAI",
  "MAI",
  "BAL",
  "BEETS",
  "OX",
  "MAV",
  "JOE",
  "CRV",
  "crvUSD",
  "CAKE",
  "IDIA",
  "OP",
  "WOO",
  "WBTC",
  "BTC",
  "BTC.b",
  "RDNT",
  "REUNI",
  "USH",
  "unshETH",
  "PEPE",
  "MIM",
  "ARKEN",
  "DEGEN",
  "WETH",
  "BETS",
  "LIF3",
  "LSHARE",
  "WNCG",
  "MTVT",
  "ARB",
  "SFUND",
  "swETH",
  "TENET",
  "TROVE",
  "WAGMI",
  "mkUSD",
  "QI",
  "GOLD",
  "wstETH",
  "SHRAP",
  "msUSD",
  "msETH",
  "UNB",
  "OLE",
  "GUILD",
  "UNIX",
  "OMNI",
  "USDV",
];

export const transfers = [
  {
    id: "0x3ae7b865b5e05bce2f55e2d7e888c202dda5047303609aa148360ad81faa15eb0a000000",
    usdValue: 28.584062067233212,
    toChainId: 43114,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/avalanche.svg",
    dstPoolId: 2,
    srcChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
    chainId: 1,
    srcPoolId:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
    srcTxTimestamp: 1707851896,
    srcTxHash:
      "0x3ae7b865b5e05bce2f55e2d7e888c202dda5047303609aa148360ad81faa15eb",
    amount: 28.582086,
    srcAddress: "0xecc19e177d24551aa7ed6bc6fe566eca726cc8a9",
    dstAddress: null,
    srcNetworkSymbol: "USDC",
    dstNetworkSymbol: "USDT",
  },
  {
    id: "0x57a31f017d2dc5c8fe9a6ed45882cd7e7542e05d8c1b97d36997eceacd6d8f2608000000",
    usdValue: 756.1406214476807,
    toChainId: 10,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    dstPoolId: 13,
    chainId: 1,
    srcChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
    srcPoolId: 1,
    srcTxTimestamp: 1707851895,
    srcTxHash:
      "0x57a31f017d2dc5c8fe9a6ed45882cd7e7542e05d8c1b97d36997eceacd6d8f26",
    amount: 0.28809190766265763,
    srcAddress: "0xecc19e177d24551aa7ed6bc6fe566eca726cc8a9",
    dstAddress: null,
    srcNetworkSymbol: "ETH",
    dstNetworkSymbol: "ETH",
  },
  {
    id: "0xad27d8faff278ab104afecefaf4c78a3f7417c71786eda8dcc92cb3b4137c7b70b000000",
    usdValue: 0.11018461725927049,
    toChainId: 1,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
    dstPoolId: 1,
    chainId: 10,
    srcChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    srcPoolId: 1,
    srcTxTimestamp: 1707851894,
    srcTxHash:
      "0xad27d8faff278ab104afecefaf4c78a3f7417c71786eda8dcc92cb3b4137c7b7",
    amount: 0.110177,
    srcAddress: "0xecc19e177d24551aa7ed6bc6fe566eca726cc8a9",
    dstAddress: null,
    srcNetworkSymbol: "USDC",
    dstNetworkSymbol: "USDC",
  },
  {
    id: "0x95fa62226ec2fdf036d4b0047c83cb1a4b573a08e407d89f88f1b3f4610e25f20f000000",
    usdValue: 4983.844190873298,
    toChainId: 10,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    dstPoolId: 13,
    chainId: 42161,
    srcChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    srcPoolId: 13,
    srcTxTimestamp: 1707851893,
    srcTxHash:
      "0x95fa62226ec2fdf036d4b0047c83cb1a4b573a08e407d89f88f1b3f4610e25f2",
    amount: 1.89886,
    srcAddress: "0x8731d54e9d02c286767d56ac03e8037c07e01e98",
    dstAddress: null,
    srcNetworkSymbol: "ETH",
    dstNetworkSymbol: "ETH",
  },
  {
    id: "0x9c945630007a229f8924478f50e62a61793a547137d4997ccf95d912579235d825010000",
    usdValue: 0.00086860568588034,
    toChainId: 43114,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/avalanche.svg",
    dstPoolId: null,
    chainId: 250,
    srcChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/fantom.svg",
    srcPoolId: null,
    srcTxTimestamp: 1707851891,
    srcTxHash:
      "0x9c945630007a229f8924478f50e62a61793a547137d4997ccf95d912579235d8",
    amount: 0.0015233709,
    srcAddress: "0x6b02545932a1a0a06483602db264ff7ff86c9439",
    dstAddress: "0x6b02545932a1a0a06483602db264ff7ff86c9439",
    srcNetworkSymbol: "STG",
    dstNetworkSymbol: "STG",
  },
  {
    id: "0x66d95a2a6f650120f3d5ff92254fa24d83582ee569cef21b693fbf8ef979015084000000",
    usdValue: 573.7391575204728,
    toChainId: 42161,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-outline/networks/arbitrum.svg",
    dstPoolId: 13,
    srcChainId: "https://icons-ckg.pages.dev/stargate-light/networks/base.svg",
    chainId: 8453,
    srcPoolId: 13,
    srcTxTimestamp: 1707851889,
    srcTxHash:
      "0x66d95a2a6f650120f3d5ff92254fa24d83582ee569cef21b693fbf8ef9790150",
    amount: 0.2185963876327412,
    srcAddress: "0xb49c4e680174e331cb0a7ff3ab58afc9738d5f8b",
    dstAddress: null,
    srcNetworkSymbol: "ETH",
    dstNetworkSymbol: "ETH",
  },
  {
    id: "0x92ba281a9d28c33cf2f94e15b7f7683feb6337b73e7cd36311a4e852c0151c9a09000000",
    usdValue: 9650.670734319161,
    toChainId: 10,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    dstPoolId: 13,
    chainId: 8453,
    srcChainId: "https://icons-ckg.pages.dev/stargate-light/networks/base.svg",
    srcPoolId: 13,
    srcTxTimestamp: 1707851889,
    srcTxHash:
      "0x92ba281a9d28c33cf2f94e15b7f7683feb6337b73e7cd36311a4e852c0151c9a",
    amount: 3.676935299086511,
    srcAddress: "0x50b6ebc2103bfec165949cc946d739d5650d7ae4",
    dstAddress: null,
    srcNetworkSymbol: "ETH",
    dstNetworkSymbol: "ETH",
  },
  {
    id: "0x3a14326d0e386f3e30ed461f22343c873604df00c4faf5314b3b7ceb696419620c000000",
    usdValue: 6747.690372758369,
    toChainId: 10,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/optimism.svg",
    dstPoolId: 13,
    chainId: 1,
    srcChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
    srcPoolId: 13,
    srcTxTimestamp: 1707851885,
    srcTxHash:
      "0x3a14326d0e386f3e30ed461f22343c873604df00c4faf5314b3b7ceb69641962",
    amount: 6748,
    srcAddress: "0xbf22f0f184bccbea268df387a49ff5238dd23e40",
    dstAddress: null,
    srcNetworkSymbol: "USDC",
    dstNetworkSymbol: "USDC",
  },
  {
    id: "0xc0023d48b2431439e921cf49e136855820e95ee1cbadec738d5bafa04d87e49902000000",
    usdValue: 720.9837060790005,
    toChainId: 42161,
    dstChainId:
      "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
    dstPoolId: 13,
    chainId: 8453,
    srcChainId: "https://icons-ckg.pages.dev/stargate-light/networks/base.svg",
    srcPoolId: 13,
    srcTxTimestamp: 1707851885,
    srcTxHash:
      "0xc0023d48b2431439e921cf49e136855820e95ee1cbadec738d5bafa04d87e499",
    amount: 0.2746970145319247,
    srcAddress: "0xbf22f0f184bccbea268df387a49ff5238dd23e40",
    dstAddress: null,
    srcNetworkSymbol: "ETH",
    dstNetworkSymbol: "ETH",
  },
  {
    id: "0xe7e1ab93985121100ec395bdc3fba638e629197a980c3d4338f0033888757509fe000000",
    usdValue: 803.5754717179914,
    dstChainId: "https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg",
    dstPoolId: 13,
    chainId: 1101,
    toChainId: 56,
    srcChainId:
      "https://icons-ckg.pages.dev/stargate-outline/networks/zkevm.svg",
    srcPoolId: 13,
    srcTxTimestamp: 1707851885,
    srcTxHash:
      "0xe7e1ab93985121100ec395bdc3fba638e629197a980c3d4338f0033888757509",
    amount: 0.30616473183906895,
    srcAddress: "0xecc19e177d24551aa7ed6bc6fe566eca726cc8a9",
    dstAddress: null,
    srcNetworkSymbol: "CAKE",
    dstNetworkSymbol: "CAKE",
  },
];

export const walletRpcs = [
  {
    name: "Arbitrum One",
    logo: "",
  },
  {
    name: "Aurora",
    logo: "",
  },
  {
    name: "Avalanche",
    logo: "",
  },
  {
    name: "Base",
    logo: "",
  },
  {
    name: "BNB Chain",
    logo: "",
  },
  {
    name: "Arbitrum One",
    logo: "",
  },
  {
    name: "Arbitrum One",
    logo: "",
  },
  {
    name: "Arbitrum One",
    logo: "",
  },
  {
    name: "Arbitrum One",
    logo: "",
  },
  {
    name: "Arbitrum One",
    logo: "",
  },
  {
    name: "Arbitrum One",
    logo: "",
  },
];

export const formatNumber = (num) => {
  if (typeof num === "string" && num.includes("%")) {
    console.log("first");

    const numberPart = num.replace(/%/g, "");
    console.log(parseFloat(numberPart));
    // Remove the '%' sign and convert to a number
    // const numberPart = parseFloat(num.replace(/%/g, ""));
    if (isNaN(numberPart)) {
      return { formattedNumber: num, suffix: "" }; // Return original input if conversion fails
    }
    return { formattedNumber: numberPart, suffix: "%" }; // Append '%' to formatted number
  }

  console.log(num);

  if (num >= 1000000000) {
    console.log("kk", (num / 1000000000).toFixed(2));
    return {
      formattedNumber: (num / 1000000000).toFixed(2),
      suffix: "B",
    };
  } else if (num >= 1000000) {
    console.log("kkds");

    return {
      formattedNumber: (num / 1000000).toFixed(2),
      suffix: "M",
    };
  } else if (num >= 1000) {
    console.log("kkaas");

    return {
      formattedNumber: (num / 1000).toFixed(2),
      suffix: "K",
    };
  } // else {
  //   console.log(num);

  //   return {
  //     formattedNumber: num?.toFixed(2),
  //     suffix: "",
  //   };
  // }
};

export const renzoTokens = [
  "renzo-restaked-eth",
  "wrapped-bitcoin",
  "staked-ether",
];
