import Swap from "./Swap";
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BottomOption from "../BottomOptions";
import { coins, tokens } from "../../Maps";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useNavigate } from "react-router-dom";
const Withdraw = ({
  theme,
  setTheme,
  showConnect,
  setShowConnect,
  showOptions,
  setShowOptions,
}) => {
  const { isOpen, onClose } = useDisclosure();
  const [isBase, setIsBase] = useState(true);
  const { isConnected } = useWeb3ModalAccount();

  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);

  const [coin, setCoin] = useState({
    name: coins[3].name,
    ticker: coins[3].ticker,
    icon: coins[3].icon,
    netIcon: coins[3].netIcon,
    chain: tokens[0].name,
  });

  const [chain, setChain] = useState({
    name: tokens[0].name,
    chainid: tokens[0].chainid,
    icon: tokens[0].icon,
  });

  const [targetCoin, setTargetCoin] = useState({
    name: coins[0].name,
    ticker: coins[0].ticker,
    icon: coins[0].icon,
    netIcon: coins[0].netIcon,
    chain: tokens[0].name,
  });

  return (
    <section
      className="bg-transparent dark:bg-[#050712] w-[100%] h-[100vh] lg:h-[80vh] overflow-y-scroll
      lg:p-[50px] lg:pt-[0px] lg:px-[0px] pb-[150px] pt-[80px] flex flex-col items-center justify-start"
      style={{
        borderRadius: "0px 0px 1.25rem 0px",
      }}
    >
      <h1
        className="text-[#111827] text-[14px] lg:text-[16px] font-[700] bg-[#fbcc25] dark:bg-[#ffa260] w-[100%] text-center
 py-[10px]"
      >
        Note: Receive more ezETH by swapping ETH for ezETH on Mainnet Balancer{" "}
      </h1>

      <section className="lg:w-[60%] w-[100%] pt-[80px] px-[20px]">
        <Swap
          theme={theme}
          coin={coin}
          isOpen={isOpen}
          targetCoin={targetCoin}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          showConnect={showConnect}
          setShowConnect={setShowConnect}
          isBase={isBase}
          setIsBase={setIsBase}
        />
      </section>

      <Modal
        isOpen={showOptions}
        onClose={onClose}
        className="hidden lg:flex flex-col items-center justify-center px-[20px]"
      >
        <ModalContent className="hidden lg:flex flex-col items-center justify-center w-[100%] min-h-[100vh] px-[20px] z-50">
          <div className="backdrop-brightness-50 bg-black/30 w-[100%] min-h-[100vh] bg-black absolute" />

          <section
            className="hidden lg:flex text-white bg-white dark:bg-[#05081a] border-solid dark:border-[1px] dark:border-[#232439] lg:w-[400px] w-[100%] p-[10px] 
          rounded-[.5rem] flex flex-col items-center  pb-[100px] z-50"
          >
            <div className="w-[100%] flex flex-row items-center justify-end">
              <ModalCloseButton
                className="text-[10px] text-black dark:text-white"
                onClick={() => {
                  setShowOptions(false);
                }}
              />
            </div>
            <h1 className="text-[18px] font-[700] pb-[40px] text-black dark:text-white">
              Select Token
            </h1>
            <section className="flex flex-row items-center flex-wrap justify-start px-[30px] w-[100%] z-50">
              {tokens.map((us, id) => (
                <div
                  key={id}
                  className={`w-[54px] h-[54px] flex flex-col items-center justify-center cursor-pointer
                 bg-[#cfd0e5] border-[#bec0d6] dark:bg-[#191a26] border-solid border-[1px] dark:border-[#272938] rounded-[.5rem] mr-[5px] mb-[5px]
                 
                 ${
                   chain.name === us.name
                     ? "bg-[#a0a1b800] dark:bg-[#0f101a]"
                     : ""
                 }
                 
                 `}
                  onClick={() => {
                    setChain({ ...us });
                  }}
                >
                  <img src={us.icon} className="w-[40px] rounded-[50%]" />
                </div>
              ))}
            </section>
            <h1 className="dark:text-white text-black px-[45px] text-[12px] mt-[-5px] flex flex-row items-center justify-start w-[100%]">
              • Soon
            </h1>
            <section className="flex flex-col items-start w-[100%] px-[20px] pt-[10px]">
              {coins.map((us, id) => (
                <section
                  key={id}
                  className={`flex flex-row items-center py-[10px] cursor-pointer z-50 ${
                    isBase && targetCoin.name === us.name ? "hidden" : ""
                  }
                    ${!isBase && coin.name === us.name ? "hidden" : ""}
                    dark:hover:bg-[#0e1120] z-50 w-[100%] px-[10px] rounded-[.5rem] hover:bg-[#e6e7f2]`}
                  onClick={() => {
                    isBase
                      ? setCoin({
                          name: us.name,
                          ticker: us.ticker,
                          icon: us.icon,
                          netIcon: chain.icon,
                          chain: chain.name,
                        })
                      : setTargetCoin({
                          name: us.name,
                          ticker: us.ticker,
                          icon: us.icon,
                          netIcon: chain.icon,
                          chain: chain.name,
                        });
                    setShowOptions(false);
                  }}
                >
                  <div className="flex flex-col items-end pr-[10px]">
                    <img src={us.icon} className="w-[40px] rounded-[50%]" />
                    <img
                      src={chain.icon}
                      className="w-[14px] rounded-[50%] relative mt-[-10px]"
                    />
                  </div>

                  <div className="flex flex-col items-start w-[90%]">
                    <h1 className="dark:text-white text-black text-[14px] font-[700]">
                      {us.ticker}
                    </h1>
                    <h1 className="dark:text-white text-black text-[14px] font-[400]">
                      {us.name}
                    </h1>
                  </div>
                </section>
              ))}
            </section>
          </section>
        </ModalContent>
      </Modal>

      <BottomOption
        theme={theme}
        setTheme={setTheme}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        setCoin={setCoin}
      />
    </section>
  );
};

export default Withdraw;
