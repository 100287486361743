const coins = [
  {
    id: 1,
    name: "Renzo Restaked ETH",
    ticker: "ezETH",
    icon: "https://assets.coingecko.com/coins/images/34753/large/Ezeth_logo_circle.png?1713496404",
    netIcon: "./images/zz.svg",
  },
  {
    id: 2,
    name: "Binance Staked Ether",
    ticker: "wBETH",
    icon: "./images/z2.webp",
    netIcon: "./images/zz.svg",
  },
  {
    id: 3,
    name: "Lido Staked Ether",
    ticker: "stETH",
    icon: "https://assets.coingecko.com/coins/images/13442/large/steth_logo.png?1696513206",
    netIcon: "./images/zz.svg",
  },
  {
    id: 4,
    name: "Ethereum",
    ticker: "ETH",
    icon: "https://coin-images.coingecko.com/coins/images/279/large/ethereum.png?1696501628",
    netIcon: "./images/zz.svg",
  },
];

export default coins;
