

const com = [
    {
        id: 1,
        name: "Twitter (X)",
        icon: <img src="./images/x.svg" className="w-[20px] lg:w-[25px]" />,
        link: "https://twitter.com/RenzoProtocol"
    },
    {
        id: 2,
        name: "Telegram",
        icon: <i class='bx bxl-telegram text-[#ACE730] text-[20px] lg:text-[25px]' ></i>,
        link: "https://t.me/RenzoProtocolChat"
    },
    {
        id: 3,
        name: "Mirror",
        icon: <img src="./images/mirror.svg" className="w-[20px]" />,
        link: "https://mirror.xyz/renzoprotocol.eth"
    },
    {
        id: 4,
        name: "Discord",
        icon: <i class='bx bxl-discord-alt text-[#ACE730] text-[20px] lg:text-[25px]'></i>,
        link: "https://discord.gg/FMwGPDXXtf"
    },
];

export default com