import {
  ModalCloseButton,
  useDisclosure,
  Drawer,
  DrawerContent,
} from "@chakra-ui/react";
import { coins, tokens } from "../Maps";
import { useState } from "react";
const BottomOption = ({ showOptions, setShowOptions, setCoin }) => {
  const { onClose } = useDisclosure();

  const [chain, setChain] = useState({
    name: "Ethereum",
    chainid: "1",
    icon: "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
  });

  const [targetCoin, setTargetCoin] = useState({
    ticker: "ezETH",
    icon: "https://assets.coingecko.com/coins/images/34753/large/Ezeth_logo_circle.png?1713496404",
    netIcon: "./images/zz.svg",
  });

  return (
    <Drawer
      placement="bottom"
      isOpen={showOptions}
      onClose={onClose}
      className="fixed bottom-0 flex-col items-center justify-center px-[20px]"
    >
      <DrawerContent className="z-50">
        <div className="sm:hidden backdrop-brightness-50 bg-black/30 w-[100%] min-h-[100vh] bg-black absolute" />

        <section
          className="sm:hidden flex text-white bg-white dark:bg-[#05081a] border-solid dark:border-[1px] dark:border-[#232439] fixed bottom-0 w-[100%] p-[10px] 
          rounded-[.5rem] flex flex-col items-center  pb-[100px]"
        >
          <div className="w-[100%] flex flex-row items-center justify-end">
            <ModalCloseButton
              className="text-[10px] text-black dark:text-white"
              onClick={() => {
                setShowOptions(false);
              }}
            />
          </div>
          <h1 className="text-[18px] font-[700] pb-[40px] text-black dark:text-white">
            Select Token
          </h1>
          <section className="flex flex-row items-center flex-wrap justify-start px-[30px] w-[100%]">
            {tokens.map((us, id) => (
              <div
                key={id}
                className={`w-[54px] h-[54px] flex flex-col items-center justify-center cursor-pointer
                   bg-[#cfd0e5] border-[#bec0d6] dark:bg-[#191a26] border-solid border-[1px] dark:border-[#272938] rounded-[.5rem] mr-[5px] mb-[5px]    ${
                     chain.name === us.name
                       ? "bg-[#a0a1b800] dark:bg-[#0f101a]"
                       : ""
                   }`}
                onClick={() => {
                  setChain({ ...us });
                }}
              >
                <img src={us.icon} className="w-[40px] rounded-[50%]" />
              </div>
            ))}
          </section>
          <h1 className="dark:text-white text-black px-[45px] text-[12px] mt-[-5px] flex flex-row items-center justify-start w-[100%]">
            • Soon
          </h1>
          <section className="flex flex-col items-start w-[100%] px-[20px] pt-[10px]">
            {coins.map((us, id) => (
              <section
                key={id}
                className="flex flex-row items-center py-[10px] cursor-pointer
                  dark:hover:bg-[#0e1120] z-40 w-[100%] px-[10px] rounded-[.5rem] hover:bg-[#e6e7f2]"
                onClick={() => {
                  setCoin({
                    ticker: us.ticker,
                    icon: us.icon,
                    netIcon: us.netIcon,
                  });
                  setShowOptions(false);
                }}
              >
                <div className="flex flex-col items-end pr-[10px]">
                  <img src={us.icon} className="w-[40px] rounded-[50%]" />
                  <img
                    src={us.netIcon}
                    className="w-[14px] rounded-[50%] relative mt-[-10px]"
                  />
                </div>

                <div className="flex flex-col items-start w-[90%]">
                  <h1 className="dark:text-white text-black text-[14px] font-[700]">
                    {us.ticker}
                  </h1>
                  <h1 className="dark:text-white text-black text-[14px] font-[400]">
                    {us.name}
                  </h1>
                </div>
              </section>
            ))}
          </section>
        </section>
      </DrawerContent>
    </Drawer>
  );
};

export default BottomOption;
