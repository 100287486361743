import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { truncateAddressFx } from "../../helper/web3Modalutils";

const AppHeader = ({
  theme,
  setTheme,
  setShowConnect,
  showSide,
  setShowSide,
  showOptions,
  showConnect,
}) => {
  const { address, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();

  const handleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      document.documentElement.classList.remove("dark");
    } else {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    }
  };

  return (
    <section
      className={`bg-white dark:bg-[#090b19] border-solid border-b-[1px] dark:border-[#181e38] 
        p-[10px] lg:hidden flex flex-row items-center justify-between w-[100%] fixed ${
          showOptions || showConnect ? "opacity-50" : ""
        }`}
    >
      <i
        class="bx bx-menu dark:text-[#a3a4bf] text-black text-[30px]"
        onClick={() => {
          setShowSide(!showSide);
        }}
      ></i>

      <section className="flex flex-row items-center justify-end w-[50%]">
        <button
          className="text-[#090b19] font-[600] bg-[#cfd0e5] px-[1.5rem] h-[48px] rounded-[.75rem] mr-[15px] cursor-pointer"
          onClick={() => {
            isConnected ? open() : setShowConnect(true);
          }}
        >
          {isConnected ? truncateAddressFx(address) : "Connect"}

        </button>
        <div
          onClick={() => {
            handleTheme();
          }}
          className="border-solid dark:border-[1px] border-[#a3a4bf] p-[10px] flex flex-col items-center
 justify-center rounded-[.75rem] cursor-pointer bg-[#cfd0e5] dark:bg-transparent h-[48px] w-[48px]"
        >
          {theme === "dark" ? (
            <i class="bx bxs-moon text-[#a3a4bf] text-[20px]"></i>
          ) : (
            <i class="bx bxs-sun text-[20px]"></i>
          )}
        </div>
      </section>
    </section>
  );
};

export default AppHeader;
