const Footer = ({}) => {

  const de = [
    {
      id: 1,
      title: "Total Value Staked",
      body: "$3.42B",
    },
    {
      id: 2,
      title: "APR",
      body: "1.40%",
    },
    {
      id: 3,
      title: "EigenLayer Points",
      body: (
        <h1 className="flex flex-row items-center">
          <img src="./images/e1.svg" className="w-[20px]" />
          919.25M
        </h1>
      ),
    },
    {
      id: 4,
      title: "Renzo ezPoints",
      body: "1.51B",
    },
  ];

  return (
    <section
      className="lg:hidden fixed bottom-0 w-[100%] flex flex-row items-center border-solid
         border-t-[1px] border-[#c9c9c9] dark:border-black bg-white dark:bg-[#090b19] justify-between p-[10px]"
    >
      <section className="flex flex-row items-center justify-between w-[100%] flex-wrap">
        {de.map((us, id) => (
          <div
            key={id}
            className={` flex flex-col text-left pt-[5px]
                    items-start justify-start w-[50%]`}
          >
            <h1 className="text-[#565873] dark:text-[#ffffff80] text-[12px] flex flex-row items-center">
              {us.title}
              <i class='bx bx-info-circle pl-[5px]' ></i>
            </h1>

            <h1 className="text-black dark:text-white text-[1.25rem] font-[500] flex flex-row items-center">
              {us.body}
            </h1>
          </div>
        ))}
      </section>
    </section>
  );
};

export default Footer;
