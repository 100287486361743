

const allchains = [
    {
        id: 1,
        icon: "./images/eth.svg",
        name: "Ethereum"
    },
    {
        id: 2,
        icon: "./images/arb.svg",
        name: "Arbitrum"
    },
    {
        id: 3,
        icon: "./images/bnb.svg",
        name: "BNB Chain"
    },
    {
        id: 4,
        icon: "./images/mode.webp",
        name: "Mode Mainnet"
    },
    {
        id: 5,
        icon: "./images/blast.webp",
        name: "Blast"
    },
    {
        id: 6,
        icon: "./images/linea.svg",
        name: "Linea"
    },
    {
        id: 7,
        icon: "./images/base.svg",
        name: "Base"
    },
];

export default allchains