import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Layout, Applayout } from "./Components";
import { useState, useEffect } from "react";
import {
  Referral,
  Portfolio,
  Restake,
  EigenLayer,
  Defi,
  Analytics,
  Withdraw,
  StakeRez,
} from "./Components/App/Pages";
import { GatekeeperProvider } from "./context/Gatekeeper";
import {
  arbitrum,
  fantom,
  mainnet,
  optimism,
  base,
  bsc,
} from "./helper/web3Modalutils";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import Verify from "./Components/App/Pages/Verify";

const { REACT_APP_PROJECT_ID } = process.env;

const metadata = {
  name: "Renzo Protocol",
  description: "The EigenLayer Liquid Restaking Hub",
  url: "",
  icons: [""],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 1 }),
  chains: [mainnet, arbitrum, base, bsc, fantom, optimism],
  projectId: REACT_APP_PROJECT_ID,
  excludeWalletIds: [
    "a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393",
  ],
});

function App() {
  const [theme, setTheme] = useState("dark");
  const [showSide, setShowSide] = useState(false);
  const [showConnect, setShowConnect] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  useEffect(() => {}, [theme, showSide]);

  return (
    <GatekeeperProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}></Route>
          <Route path="/verify" element={<Verify />}></Route>
          <Route
            path="/app"
            element={
              <Applayout
                theme={theme}
                setTheme={setTheme}
                showSide={showSide}
                setShowSide={setShowSide}
                showConnect={showConnect}
                setShowConnect={setShowConnect}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
              />
            }
          >
            <Route
              path="/app/referral"
              element={
                <Referral
                  showConnect={showConnect}
                  setShowConnect={setShowConnect}
                />
              }
            />
            <Route
              path="/app/portfolio"
              element={
                <Portfolio
                  theme={theme}
                  showConnect={showConnect}
                  setShowConnect={setShowConnect}
                />
              }
            />
            <Route
              path="/app"
              element={
                <Restake
                  theme={theme}
                  showConnect={showConnect}
                  setShowConnect={setShowConnect}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                />
              }
            />
            <Route
              path="/app/stakerez"
              element={
                <StakeRez
                  theme={theme}
                  showConnect={showConnect}
                  setShowConnect={setShowConnect}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                  showSide={showSide}
                />
              }
            />
            <Route
              path="/app/withdraw"
              element={
                <Withdraw
                  theme={theme}
                  showConnect={showConnect}
                  setShowConnect={setShowConnect}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                />
              }
            />
            <Route
              path="/app/eigenlayer"
              element={<EigenLayer theme={theme} />}
            />
            <Route path="/app/defi" element={<Defi theme={theme} />} />
            <Route
              path="/app/analytics"
              element={
                <Analytics
                  theme={theme}
                  showSide={showSide}
                  setShowSide={setShowSide}
                />
              }
            />
          </Route>
        </Routes>
      </HashRouter>
    </GatekeeperProvider>
  );
}

export default App;
