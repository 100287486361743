import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Referral = ({ setShowConnect }) => {
  const { isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (isConnected) {
        navigate("/verify");
      }
    };

    request();
  }, [isConnected]);
  const more = [
    {
      id: 1,
      title: "Copy referral Link",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 14.75H9.99999C9.58999 14.75 9.24999 14.41 9.24999 14C9.24999 13.59 9.58999 13.25 9.99999 13.25H12.5C15.12 13.25 17.25 11.12 17.25 8.5C17.25 5.88 15.12 3.75 12.5 3.75H7.49999C4.87999 3.75 2.74999 5.88 2.74999 8.5C2.74999 9.6 3.13999 10.67 3.83999 11.52C4.09999 11.84 4.05999 12.31 3.73999 12.58C3.41999 12.84 2.94999 12.8 2.67999 12.48C1.74999 11.36 1.23999 9.95 1.23999 8.5C1.23999 5.05 4.03999 2.25 7.48999 2.25H12.49C15.94 2.25 18.74 5.05 18.74 8.5C18.74 11.95 15.95 14.75 12.5 14.75Z"
            fill="gray"
          ></path>
          <path
            d="M16.5 21.75H11.5C8.05 21.75 5.25 18.95 5.25 15.5C5.25 12.05 8.05 9.25 11.5 9.25H14C14.41 9.25 14.75 9.59 14.75 10C14.75 10.41 14.41 10.75 14 10.75H11.5C8.88 10.75 6.75 12.88 6.75 15.5C6.75 18.12 8.88 20.25 11.5 20.25H16.5C19.12 20.25 21.25 18.12 21.25 15.5C21.25 14.4 20.86 13.33 20.16 12.48C19.9 12.16 19.94 11.69 20.26 11.42C20.58 11.15 21.05 11.2 21.32 11.52C22.25 12.64 22.76 14.05 22.76 15.5C22.75 18.95 19.95 21.75 16.5 21.75Z"
            fill="gray"
          ></path>
        </svg>
      ),
    },
    {
      id: 2,
      title: "Share",
      icon: (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.153041 0L6.77154 8.82491L0.111328 16H1.61039L7.44153 9.71796L12.1528 16H17.2538L10.2627 6.67879L16.4621 0H14.963L9.59304 5.78545L5.25406 0H0.153041ZM2.35748 1.10103H4.70087L15.049 14.899H12.7057L2.35748 1.10103Z"
            fill="gray"
          ></path>
        </svg>
      ),
    },
  ];
  return (
    <section
      className="bg-transparent dark:bg-[#050712] w-[100%] min-h-[100vh] lg:min-h-[80vh] lg:p-[50px] lg:px-[100px] p-[15px] pb-[150px] pt-[80px]"
      style={{
        borderRadius: "0px 0px 1.25rem 0px",
      }}
    >
      <section
        className="bg-white dark:bg-[#0d1122] border-solid dark:border-[1px] rounded-[.75rem] 
        px-[2rem] py-[1rem] border-[#1e202a] text-center flex flex-col items-center justify-center dark:text-white text-black"
      >
        <h1 className="text-[1.5rem] pb-[1rem]">
          You earn 10% of the points your friends make
        </h1>

        <h1 className="text-[.875rem]">
          Referral deposits are supported on Ethereum mainnet and Layer 2s. To
          activate the referral, users need to use the referral link and deposit
          ETH on mainnet to start accruing referral points. Once a referral is
          active, the referring user will accrue 10% of all points, across all
          chains.
        </h1>
      </section>

      <section className="flex flex-col lg:flex-row items-start justify-between w-[100%] pt-[20px]">
        <div
          className="bg-white dark:bg-[#0d1122] border-solid dark:border-[1px] rounded-[.75rem] w-[100%] lg:w-[49%] h-[158px]
        px-[2rem] py-[1rem] border-[#1e202a] text-center flex flex-col items-center justify-center dark:text-white text-black"
        >
          <h1 className="text-[16px] pb-[30px]">
            Connect your wallet to check more
          </h1>
          <button
            onClick={() => {
              setShowConnect(true);
            }}
            className="flex flex-row items-center px-[1.5rem] h-[50px] rounded-[.75rem] bg-[#ace731] text-black"
          >
            <button className="pr-[5px]">Connect your wallet</button>
            <img src="./images/arrow.svg" className="" />
          </button>
        </div>

        <div
          className="bg-white dark:bg-[#0d1122] border-solid dark:border-[1px] rounded-[.75rem] w-[100%] mt-[10px] lg:mt-[0px] lg:w-[49%] h-[158px] 
        px-[2rem] py-[1rem] border-[#1e202a] text-center flex flex-col items-center justify-between dark:text-white text-black"
        >
          {more.map((us, id) => (
            <div
              key={id}
              className="flex flex-row items-center w-[100%] bg-white dark:bg-[#090c1a] border-solid border-[1px] border-[gray] 
                justify-center h-[42px] rounded-[12px] dark:border-[#3f4156]"
            >
              {us.icon}
              <h1 className="text-[16px] pl-[10px] text-[gray]">{us.title}</h1>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Referral;
