

const InvestorsData = [
    {
        id: 1,
        logo: "./images/i1.svg",
        link: "https://twitter.com/BinanceLabs"
    },
    {
        id: 2,
        logo: "./images/i2.svg",
        link: "https://twitter.com/Maven11Capital"
    },
    {
        id: 3,
        logo: "./images/i3.webp",
        link: "https://twitter.com/FigmentCapital"
    },
    {
        id: 4,
        logo: "./images/i4.svg",
        link: "https://twitter.com/SevenXVentures"
    },
    {
        id: 5,
        logo: "./images/i5.webp",
        link: "https://twitter.com/IOSGVC"
    },
    {
        id: 6,
        logo: "./images/i6.svg",
        link: "https://twitter.com/bodhi_ventures"
    },
    {
        id: 7,
        logo: "./images/i7.svg",
        link: "https://twitter.com/okx"
    },
    {
        id: 8,
        logo: "./images/i8.svg",
        link: "https://twitter.com/Papervc"
    },
    {
        id: 9,
        logo: "./images/i9.svg",
        link: "https://twitter.com/0xMantle"
    },
    {
        id: 10,
        logo: "./images/i10.svg",
        link: "https://twitter.com/edessacap"
    },
    {
        id: 11,
        logo: "./images/i11.webp",
        link: "https://twitter.com/protagonistxyz"
    },
    {
        id: 12,
        logo: "./images/i12.svg",
        link: "https://twitter.com/BitscaleCapital"
    },
    {
        id: 13,
        logo: "./images/i13.webp",
        link: "https://www.karatage.io/"
    },
    {
        id: 14,
        logo: "./images/i14.svg",
        link: "https://twitter.com/robotventures"
    },
    {
        id: 15,
        logo: "./images/i15.webp",
        link: "https://twitter.com/Re7Capital"
    },
];

export default InvestorsData