const tokens = [
  {
    id: 1,
    name: "ETH",
    chainid: "1",
    icon: "https://icons-ckg.pages.dev/stargate-light/networks/ethereum.svg",
  },
  {
    id: 2,
    name: "Arbitrum",
    chainid: "42161",
    icon: "https://icons-ckg.pages.dev/stargate-light/networks/arbitrum.svg",
  },
  {
    id: 3,
    name: "BNB",
    chainid: "56",
    icon: "https://icons-ckg.pages.dev/stargate-light/networks/bsc.svg",
  },
  {
    id: 4,
    name: "Base",
    chainid: "8453",
    icon: "https://icons-ckg.pages.dev/stargate-light/networks/base.svg",
  },
  {
    id: 5,
    name: "Mode Mainnet",
    chainid: "34443",
    icon: "https://res.cloudinary.com/dcxwxzigo/image/upload/v1711384131/helpers/image_m2kjun.webp",
  },
  {
    id: 6,
    name: "Linea",
    chainid: "59144",
    icon: "https://icons-ckg.pages.dev/stargate-light/networks/linea.svg",
  },
  {
    id: 7,
    icon: "./images/x7.svg",
  },
];

export default tokens;
