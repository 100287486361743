

const dev = [
    {
        id: 1,
        name: "Github",
        icon: <i class='bx bxl-github text-[#ACE730] text-[20px] lg:text-[25px]' ></i>,
        link: "https://github.com/Renzo-Protocol"
    },
    {
        id: 2,
        name: "Audits",
        icon: <img src="./images/stamp.svg" className="w-[20px] lg:w-[25px]" />,
        link: "https://docs.renzoprotocol.com/"
    },
    {
        id: 3,
        name: "Docs",
        icon: <img src="./images/docs.svg" className="w-[15px] lg:w-[20px]" />,
        link: "https://github.com/Renzo-Protocol/contracts-public/blob/master/Audit/Renzo_Protocol_EVM_Contracts_Smart_Contract_Security_Assessment.pdf"
    },
    {
        id: 4,
        name: "Bug Bounty",
        icon: <i class='bx bx-bug text-[#ACE730] text-[20px] lg:text-[25px]'></i>,
        link: "https://immunefi.com/api/preview?secret=2tWYPTv0HQp2QVme03BxvyWs7G9XaIcSLY5cUpaHWoc_&slug=renzoprotocol&path=/bounty/renzoprotocol&content_type=bounty"
    },
];

export default dev